import { NodeValue, Tree } from 'tree';
import api from '@/egrul/api';

export default {
    namespaced: true,

    state: {
    /**
     * @var {Tree}
     */
        tree: {},

        searchString: '',

        /**
         * @var {Tree}
         */
        searchTree: {},

        visibleTree: false,

        showLinkOkvedNotification: false,

        nodeByParsedOkvedCodeIsFound: false,

        includeAdditionalOkved: {
            name: 'include_additional_okved',
            title: 'Искать только в основных видах деятельности',
            chosen: false,
        },
    },

    getters: {
        getTree(state) {
            return state.tree;
        },

        getSearchTree(state) {
            return state.searchTree;
        },

        isSearchMode(state) {
            return state.searchString.length >= 3;
        },

        emptySearchResult(state) {
            return state.searchTree.isEmpty();
        },

        getSelectedOkveds(state) {
            if (!state.tree.root) {
                return [];
            }

            return state.tree.getChosenNodesFromLevel(3).map((okved) => okved.value.obj.id);
        },

        getIncludeAdditionalOkved(state) {
            return state.includeAdditionalOkved.chosen;
        },

        selectedMainOkveds(state) {
            if (!state.tree.root) {
                return [];
            }

            return state.tree.getChosenNodesFromLevel(2);
        },

        okvedIsEmpty(state, getters) {
            return getters.getSelectedOkveds.length === 0;
        },

        allOkvedsSelected(state) {
            if (!state.tree.root) {
                return false;
            }
            return state.tree.root.value.isChosen && !state.tree.root.value.isPartiallyChosen;
        },

        nodeByOkvedCodeIsFound(state) {
            return state.nodeByParsedOkvedCodeIsFound;
        },
    },

    mutations: {
        resetSearch(state) {
            state.searchString = '';
        },

        setSearchString(state, searchString) {
            state.searchString = searchString;
        },

        expandOkvedNode: (state, nodeDesc) => {
            state.tree.expandNode(nodeDesc.level, nodeDesc.id);
        },

        runSearch(state) {
            state.searchTree = state.tree.filterTreeByNodeName(state.searchString, 0, false);
        },

        setTree(state, { groups, okveds }) {
            // корень
            const tree = new Tree(3);
            const nodeValue = new NodeValue(
                { id: 1, name: 'Выбрать все ОКВЭД' },
                1,
                true,
                false,
                false,
                true,
            );
            tree.addNodeByParentId(nodeValue, 0);

            // группы ОКВЭД
            let keys = Object.keys(groups);
            keys.forEach((key) => {
                groups[key].id = groups[key].code;
                groups[key].name = `Группа ${groups[key].code}. ${groups[key].name}`;
                const nodeValue = new NodeValue(
                    groups[key],
                    2,
                    false,
                    false,
                    false,
                    false,
                );
                tree.addNodeByParentId(nodeValue, 1);
            });

            // ОКВЭД
            keys = Object.keys(okveds);
            keys.forEach((key) => {
                okveds[key].id = okveds[key].code;
                okveds[key].name = `${okveds[key].code} ${okveds[key].name}`;
                const nodeValue = new NodeValue(okveds[key], 3);
                tree.addNodeByParentId(nodeValue, okveds[key].group_code);
            });

            state.tree = tree;
        },

        expandOkvedSearchNode: (state, nodeDesc) => {
            state.searchTree.expandNode(nodeDesc.level, nodeDesc.id);
        },

        syncSearchTree: (state) => {
            state.searchTree.copyStateOfChoice(state.searchTree.root, state.tree);
        },

        chooseOkvedNode: (state, nodeDesc) => {
            state.tree.chooseNode(nodeDesc.level, nodeDesc.id, false);
        },

        chooseOrCancelTree: (state, choice) => {
            state.tree.selectOrDropAllNodes(choice);
        },

        chooseOrCancelSearchTree(state, choice) {
            state.searchTree.selectOrDropAllNodes(choice);
        },

        setVisibleTree(state, value) {
            state.visibleTree = value;
        },

        setShowLinkOkvedNotification(state, value) {
            state.showLinkOkvedNotification = value;
        },

        parseUrlForOkvedCode(state, commit) {
            const url = new URL(window.location);
            if (url.searchParams.get('okvedCode') !== null) {
                const okvedCode = url.searchParams.get('okvedCode');

                for (let i = 2; i < 4; i++) {
                    const okvedCodeNode = state.tree.searchNodeById(i, okvedCode, state.tree.root);

                    if (okvedCodeNode !== null) {
                        commit('chooseOkvedNode', { level: i, id: okvedCodeNode.value.obj.id });

                        if (i == 2) {
                            okvedCodeNode.value.isExpanded = true;
                        } else {
                            okvedCodeNode.parent.value.isExpanded = true;
                        }

                        state.visibleTree = true;
                        state.nodeByParsedOkvedCodeIsFound = true;

                        break;
                    }
                }
            }
        },

        setNodeByParsedOkvedCodeIsFound(state, value) {
            state.nodeByParsedOkvedCodeIsFound = value;
        },

        toggleIncludeAdditionalOkved(state) {
            state.includeAdditionalOkved.chosen = !state.includeAdditionalOkved.chosen;
        },
    },

    actions: {
        async setOkvedTree({ commit }) {
            const res = await api.getOkveds();
            const { groups, okveds } = res;
            commit('setTree', { groups, okveds });
            commit('parseUrlForOkvedCode', commit);
        },

        updateSearch: ({ commit, getters }, searchString) => {
            commit('setSearchString', searchString);
            if (getters.isSearchMode) {
                commit('runSearch');
            }
        },

        chooseOkvedNode({ commit }, nodeDesc) {
            commit('chooseOkvedNode', nodeDesc);
            commit('runSearch');
        },

        chooseOkvedsSearchNode: ({ commit }, nodeDesc) => {
            commit('chooseOkvedNode', nodeDesc);
            commit('syncSearchTree');
        },

        chooseOkveds({ commit }, okveds) {
            commit('chooseOrCancelTree', false);

            okveds.forEach((okved) => {
                commit('chooseOkvedNode', { level: 3, id: okved });
            });
        },

        toggleIncludeAdditionalOkved({ commit }) {
            commit('toggleIncludeAdditionalOkved');
        },
    },
};
