<template>
  <div class="actualization__client_file_representation__container">
    <div class="actualization__client_file_representation_content__wrapper">
      <div class="actualization__client_file_representation_row">
        <div
          v-for="(column, columnIndex) in columns"
          :key="'actualization__client_file_representation_' + columnIndex + '_' + column.title + '_column'"
          class="actualization__client_file_representation_column"
          @click="chooseColumn(columnIndex)"
        >
          <!--          Заголовок столбца    -->
          <div
            :key="'actualization__client_file_representation_header_row_' + columnIndex + '_column'"
            :class="{'actualization__active_cell': columnIndex === chosenColumn}"
            class="actualization__client_file_representation_cell header"
          />
          <!--          Содержимое столбца    -->
          <div
            v-for="(row, rowIndex) in rows"
            :key="'actualization__client_file_representation_' + rowIndex + '_row_' + columnIndex + '_column'"
            :class="{'actualization__active_cell': columnIndex === chosenColumn}"
            class="actualization__client_file_representation_cell"
          >
            <span class="actualization__client_file_representation_cell_span">
              {{ row[columnIndex] }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RepresentUploadedFile',
  props: {
    rows: Array,
    columns: Array,
    chosenColumn: Number,
  },

  emits: ['chooseColumn'],

  methods: {
    chooseColumn(columnIndex) {
      this.$emit('chooseColumn', columnIndex);
    },
  },
};
</script>

<style scoped lang="less">
    .actualization__client_file_representation__container {
        display: flex;
        box-sizing: border-box;
        border: 1px solid black;
        background: url('./xlsx_table_image.png') top left no-repeat;
        height: 139px;
        padding: 0 0 0 38px;
    }

    .actualization__client_file_representation_content__wrapper {
        width: 100%;
        height: 100%;
    }

    .actualization__client_file_representation_row {
        display: flex;
        flex-direction: row;
    }

    .actualization__client_file_representation_column {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 82px;
      cursor: pointer;

      &:hover {
        background-color: rgba(34, 177, 42, 0.2);
      }

      &:last-child {
        width: 41px;
      }
    }

    .actualization__client_file_representation_cell {
      display: flex;
      box-sizing: border-box;
      width: inherit;
      height: 17.3px;
      padding: 4px 0 0 4px;
    }

    .actualization__client_file_representation_cell_span {
      width: inherit;
      overflow: hidden;
      font-size: 11px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .actualization__active_cell {
      background-color: rgba(34, 177, 42, 0.4);
    }

    @media (max-width: 950px) {
      .actualization__client_file_representation__container{
        background-size: cover;
      }
    }
</style>
