
export const notChosenHandlerMixin = {
    methods: {
        notChosenHandler(msg) {
            return () => {
                echo(msg);
            };
        }
    }
}
