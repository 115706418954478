import Fuse from 'fuse.js';
import MultiSelectorUtil from '../../common_components/MultiSelector/MultiSelectorUtil';
import {
    parseIncome,
    parseProfit,
    sanitizeEarnings,
    sanitizeEmployees,
    sanitizeMinProfit,
} from './utils/inputHandling';
import EgrulConfiguratorUtil from './utils/EgrulConfiguratorUtil';

const mutations = {
    togglePhone(state, isPartiallySelectedChange = false) {
        if (isPartiallySelectedChange) {
            state.phoneFilter.partiallySelected = !state.phoneFilter.partiallySelected;

            state.selectedOptionalFilters = EgrulConfiguratorUtil.pushOrSpliceParam(
                state.phoneFilter.partiallySelected,
                'phone',
                state.selectedOptionalFilters,
            );
        } else {
            state.phoneFilter.selected = !state.phoneFilter.selected;

            state.selectedBasicFilters = EgrulConfiguratorUtil.pushOrSpliceParam(
                state.phoneFilter.selected,
                'phone',
                state.selectedBasicFilters,
            );
        }
    },
    togglePhoneSubFilters(state, subFilterName) {
        const currentSubFilterIndex = state.phoneFilter.subFilters.findIndex(subFilters => subFilters.name === subFilterName);

        state.phoneFilter.subFilters[currentSubFilterIndex].selected = !state.phoneFilter.subFilters[currentSubFilterIndex].selected;

        const disabledFilters = state.phoneFilter.subFilters.filter(filter => !filter.selected);

        if (state.phoneFilter.subFilters.length === disabledFilters.length) {
            state.phoneFilter.selected = false;

            state.selectedBasicFilters = EgrulConfiguratorUtil.pushOrSpliceParam(
                state.phoneFilter.selected,
                'phone',
                state.selectedBasicFilters,
            );

            state.phoneFilter.subFilters.forEach((subFilter) => {
                subFilter.selected = true;
            });
        }
    },
    toggleEmailVerifiedSubFilter(state) {
        state.emailFilter.subFilters[0].selected = !state.emailFilter.subFilters[0].selected;
    },
    toggleAddress(state, isPartiallySelectedChange = false) {
        if (isPartiallySelectedChange) {
            state.addressFilter.partiallySelected = !state.addressFilter.partiallySelected;

            state.selectedOptionalFilters = EgrulConfiguratorUtil.pushOrSpliceParam(
                state.addressFilter.partiallySelected,
                'address',
                state.selectedOptionalFilters,
            );
            state.selectedOptionalFilters = EgrulConfiguratorUtil.pushOrSpliceParam(
                state.addressFilter.partiallySelected,
                'post_index',
                state.selectedOptionalFilters,
            );
        } else {
            state.addressFilter.selected = !state.addressFilter.selected;

            state.selectedBasicFilters = EgrulConfiguratorUtil.pushOrSpliceParam(
                state.addressFilter.selected,
                'address',
                state.selectedBasicFilters,
            );
            state.selectedBasicFilters = EgrulConfiguratorUtil.pushOrSpliceParam(
                state.addressFilter.selected,
                'post_index',
                state.selectedBasicFilters,
            );
        }
    },
    toggleEmail(state, isPartiallySelectedChange = false) {
        if (isPartiallySelectedChange) {
            state.emailFilter.partiallySelected = !state.emailFilter.partiallySelected;

            state.selectedOptionalFilters = EgrulConfiguratorUtil.pushOrSpliceParam(
                state.emailFilter.partiallySelected,
                'email',
                state.selectedOptionalFilters,
            );
        } else {
            state.emailFilter.selected = !state.emailFilter.selected;

            state.selectedBasicFilters = EgrulConfiguratorUtil.pushOrSpliceParam(
                state.emailFilter.selected,
                'email',
                state.selectedBasicFilters,
            );
        }
    },
    toggleSite(state, isPartiallySelectedChange = false) {
        if (isPartiallySelectedChange) {
            state.siteFilter.partiallySelected = !state.siteFilter.partiallySelected;

            state.selectedOptionalFilters = EgrulConfiguratorUtil.pushOrSpliceParam(
                state.siteFilter.partiallySelected,
                'site',
                state.selectedOptionalFilters,
            );
        } else {
            state.siteFilter.selected = !state.siteFilter.selected;

            state.selectedBasicFilters = EgrulConfiguratorUtil.pushOrSpliceParam(
                state.siteFilter.selected,
                'site',
                state.selectedBasicFilters,
            );
        }
    },
    toggleDecisionMaker(state, isPartiallySelectedChange = false) {
        if (isPartiallySelectedChange) {
            state.decisionMakerFilter.partiallySelected = !state.decisionMakerFilter.partiallySelected;

            state.selectedOptionalFilters = EgrulConfiguratorUtil.pushOrSpliceParam(
                state.decisionMakerFilter.partiallySelected,
                'okved_contact_person',
                state.selectedOptionalFilters,
            );
        } else {
            state.decisionMakerFilter.selected = !state.decisionMakerFilter.selected;

            state.selectedBasicFilters = EgrulConfiguratorUtil.pushOrSpliceParam(
                state.decisionMakerFilter.selected,
                'okved_contact_person',
                state.selectedBasicFilters,
            );
        }
    },
    toggleShowCompanySize(state, isPartiallySelectedChange = false) {
        if (isPartiallySelectedChange) {
            state.showCompanySizeFilter.partiallySelected = !state.showCompanySizeFilter.partiallySelected;

            state.selectedOptionalFilters = EgrulConfiguratorUtil.pushOrSpliceParam(
                state.showCompanySizeFilter.partiallySelected,
                'show_company_size',
                state.selectedOptionalFilters,
            );
        } else {
            state.showCompanySizeFilter.selected = !state.showCompanySizeFilter.selected;

            state.selectedBasicFilters = EgrulConfiguratorUtil.pushOrSpliceParam(
                state.showCompanySizeFilter.selected,
                'show_company_size',
                state.selectedBasicFilters,
            );
        }
    },
    toggleRegistrationDate(state, isPartiallySelectedChange = false) {
        if (isPartiallySelectedChange) {
            state.registrationDateFilter.partiallySelected = !state.registrationDateFilter.partiallySelected;

            state.selectedOptionalFilters = EgrulConfiguratorUtil.pushOrSpliceParam(
                state.registrationDateFilter.partiallySelected,
                'registration_date',
                state.selectedOptionalFilters,
            );
        } else {
            state.registrationDateFilter.selected = !state.registrationDateFilter.selected;

            state.selectedBasicFilters = EgrulConfiguratorUtil.pushOrSpliceParam(
                state.registrationDateFilter.selected,
                'registration_date',
                state.selectedBasicFilters,
            );
        }
    },
    toggleHasMessenger(state) {
        state.has_messenger = !state.has_messenger;
    },
    toggleOgrn(state, isPartiallySelectedChange = false) {
        if (isPartiallySelectedChange) {
            state.ogrnSelectedFilter.partiallySelected = !state.ogrnSelectedFilter.partiallySelected;

            state.selectedOptionalFilters = EgrulConfiguratorUtil.pushOrSpliceParam(
                state.ogrnSelectedFilter.partiallySelected,
                'ogrn',
                state.selectedOptionalFilters,
            );
        } else {
            state.ogrnSelectedFilter.selected = !state.ogrnSelectedFilter.selected;

            state.selectedBasicFilters = EgrulConfiguratorUtil.pushOrSpliceParam(
                state.ogrnSelectedFilter.selected,
                'ogrn',
                state.selectedBasicFilters,
            );
        }
    },
    toggleAllCompanyTypeSelected(state) {
        for (const id in state.companyTypes) {
            state.companyTypes[id].included = false;
            state.companyTypes[id].excluded = false;
        }
    },
    toggleAllMspTypeSelected(state) {
        for (const id in state.mspTypes) {
            state.mspTypes[id].included = false;
            state.mspTypes[id].excluded = false;
        }
    },
    toggleIncludeMspType(state, id) {
        state.mspTypes[id].included = !state.mspTypes[id].included;

        if (state.mspTypes[id].excluded) {
            state.mspTypes[id].excluded = !state.mspTypes[id].excluded;
        }
    },
    toggleExcludeMspType(state, id) {
        state.mspTypes[id].excluded = !state.mspTypes[id].excluded;

        if (state.mspTypes[id].included) {
            state.mspTypes[id].included = !state.mspTypes[id].included;
        }
    },
    toggleIncAndProfitForLastYears(state) {
        state.show_inc_and_profit_for_last_years = !state.show_inc_and_profit_for_last_years;
    },
    toggleHasSitePhone(state) {
        state.has_site_phone = !state.has_site_phone;
    },
    setCityDescription(state, cityDescription) {
        state.cityDescription = cityDescription;
    },
    setMspTypes(state, mspTypes) {
        const mspTypesState = {};
        mspTypes.forEach((mspType) => {
            mspTypesState[mspType.id] = {
                id: mspType.id,
                title: mspType.title,
                subtitle: mspType.subtitle,
                included: false,
                excluded: false,
            };
        });
        state.mspTypes = mspTypesState;
    },
    setFuse(state, companyTypes) {
        const data = companyTypes.map(item => ({
            ...item,
            groupId: item.id_group,
        })).filter(item => item.id_group !== null);

        state.fuse = new Fuse(data, state.fuseOptions);
    },
    setCompanyTypes(state, companyTypes) {
        const popupUrls = {
            1: '/api/popup/licenses_instruction',
            20: '/api/popup/wb_description',
        };
        const descriptions = {
            21: 'Упрощенная система налогообложения',
            26: 'Единый сельскохозяйственный налог',
            28: 'Операторов персональных данных',
            31: 'Общая система налогообложения',
            35: 'Налог на профессиональный доход',
        };
        const companyTypesState = {};

        companyTypes.forEach((companyType, index) => {
            companyTypesState[companyType.id] = {
                id: companyType.id,
                groupId: companyType.id_group,
                sortIndex: index,
                title: companyType.title,
                title_accusative: companyType.title_accusative,
                included: false,
                excluded: false,
                popupUrl: popupUrls[companyType.id] || null,
                visible: false,
                description: descriptions[companyType.id] || null,
                isActive: false,
            };
        });
        state.companyTypes = companyTypesState;
    },
    setCompanyTypeGroups(state, companyTypeGroup) {
        state.companyTypeGroups = companyTypeGroup.map(group => ({
            ...group,
            active: false,
            show: true,
        }));
    },
    setShowCompanyTypeGroup(state, { groupId, show }) {
        const companyTypeGroup = state.companyTypeGroups.find(group => group.id === groupId);
        companyTypeGroup.show = show;
    },
    setCompanyStatuses(state, companyStatuses) {
        state.companyStatuses = companyStatuses.map(group => ({
            ...group,
            included: false,
        }));
    },
    toggleVisibleCompanyTypesByGroupId(state, groupId) {
        for (const id in state.companyTypes) {
            if (state.companyTypes[id].groupId === groupId) {
                state.companyTypes[id].visible = !state.companyTypes[id].visible;
            }
        }
    },
    setVisibleCompanyTypesByGroupId(state, groupId) {
        for (const id in state.companyTypes) {
            if (state.companyTypes[id].groupId === groupId) {
                state.companyTypes[id].visible = true;
            }
        }
    },
    hideAllCompanyTypes(state) {
        for (const id in state.companyTypes) {
            state.companyTypes[id].visible = false;
        }
    },
    visibleAllCompanyTypes(state) {
        Object.entries(state.companyTypes).forEach(([key]) => {
            state.companyTypes[key].visible = true;
        });
    },
    toggleActiveGroup(state, id) {
        state.companyTypeGroups = state.companyTypeGroups.map(group => ({
            ...group,
            active: group.id === id ? !group.active : group.active,
        }));
    },
    setActiveGroup(state, groupId) {
        state.companyTypeGroups = state.companyTypeGroups.map(group => ({
            ...group,
            active: group.id === groupId ? true : group.active,
        }));
    },
    resetActiveGroups(state) {
        state.companyTypeGroups = state.companyTypeGroups.map(group => ({
            ...group,
            active: false,
        }));
    },
    activateAllGroups(state) {
        state.companyTypeGroups = state.companyTypeGroups.map(group => ({
            ...group,
            active: true,
        }));
    },
    setPhoneTypes(state, phoneTypes) {
        const phoneSubFilters = [];
        phoneTypes.forEach((phoneType) => {
            phoneSubFilters.push({
                name: `${phoneType.id}`,
                title: phoneType.title,
                selected: false,
            });
        });
        state.phoneFilter.subFilters = phoneSubFilters;
    },
    setYears(state, years) {
        state.years = years;
    },
    setMonths(state, months) {
        state.months = months;
    },
    yearChange(state, year) {
        const changedYear = MultiSelectorUtil.findByKey(state.years, year);
        changedYear.selected = !changedYear.selected;
    },
    monthChange(state, month) {
        const monthChanged = MultiSelectorUtil.findByKey(state.months, month);
        monthChanged.selected = !monthChanged.selected;
    },
    allYearSelect(state) {
        state.years = MultiSelectorUtil.unselectAll(state.years);
    },
    allMonthSelect(state) {
        state.months = MultiSelectorUtil.unselectAll(state.months);
    },
    toggleExpandAdditionalFilters(state) {
        state.expandAdditionalFilters = !state.expandAdditionalFilters;
        state.additionalFiltersChosen = !state.additionalFiltersChosen;
    },
    toggleEmployees(state) {
        state.employees.chosen = !state.employees.chosen;
    },
    setEmployeesMin(state, value) {
        state.employees.minValue = sanitizeEmployees(value);
    },
    setEmployeesMax(state, value) {
        state.employees.maxValue = sanitizeEmployees(value);
    },
    toggleIncome(state) {
        state.income.chosen = !state.income.chosen;
    },
    setIncomeMin(state, value) {
        state.income.minValue = parseIncome(sanitizeEarnings(value));
    },
    setIncomeMax(state, value) {
        state.income.maxValue = parseIncome(sanitizeEarnings(value));
    },
    setProfitMin(state, value) {
        state.profit.minValue = parseProfit(sanitizeMinProfit(value));
    },
    setProfitMax(state, value) {
        state.profit.maxValue = parseProfit(sanitizeMinProfit(value));
    },
    toggleProfit(state) {
        state.profit.chosen = !state.profit.chosen;
    },
    // Фильтры численности и выручки отключаются при выборе МСП
    toggleDisableAdditionalFilters(state) {
        const selectedMspTypes = Object.keys(state.mspTypes)
            .filter(mspType => state.mspTypes[mspType].included || state.mspTypes[mspType].excluded);

        state.employeesAndIncomeDisabled = !!selectedMspTypes.length;

        if (selectedMspTypes.length) {
            state.employees.chosen = false;
            state.income.chosen = false;
            state.profit.chosen = false;
        }
    },
    toggleIncludeCompanyType(state, id) {
        state.companyTypes[id].included = !state.companyTypes[id].included;

        if (state.companyTypes[id].excluded) {
            state.companyTypes[id].excluded = !state.companyTypes[id].excluded;
        }
    },
    toggleExcludeCompanyType(state, id) {
        state.companyTypes[id].excluded = !state.companyTypes[id].excluded;

        if (state.companyTypes[id].included) {
            state.companyTypes[id].included = !state.companyTypes[id].included;
        }
    },
    toggleIncludeCompanyStatus(state, id) {
        const companyStatus = state.companyStatuses.find(status => status.id === id);

        companyStatus.included = !companyStatus.included;
    },
    setVisibleTree(state, value) {
        state.visibleTree = value;
    },
    setHighLightGeoButton(state, value) {
        state.highlightGeoButton = value;
    },
    setHighLightOkvedButton(state, value) {
        state.highlightOkvedButton = value;
    },
    setHighLightOkopfButton(state, value) {
        state.highlightOkopfButton = value;
    },
    setNeedRecalculation(state, value) {
        state.needRecalculation = value;
    },
    setCalculating(state, value) {
        state.isCalculating = value;
    },
    highlightActiveType(state, id) {
        state.companyTypes[id].isActive = true;

        setTimeout(() => {
            state.companyTypes[id].isActive = false;
        }, 3000);
    },

    setSearchCompanyType(state, value) {
        state.searchCompanyType = value;
    },

    toggleShowCompanyTypeSelector(state) {
        state.showCompanySizeSelector = false;
        state.showCompanyStatusSelector = false;

        state.showCompanyTypeSelector = !state.showCompanyTypeSelector;
    },

    toggleShowCompanySizeSelector(state) {
        state.showCompanyTypeSelector = false;
        state.showCompanyStatusSelector = false;

        state.showCompanySizeSelector = !state.showCompanySizeSelector;
    },

    toggleShowCompanyStatusSelector(state) {
        state.showCompanyTypeSelector = false;
        state.showCompanySizeSelector = false;

        state.showCompanyStatusSelector = !state.showCompanyStatusSelector;
    },

    setShowCompanyType(state, value) {
        state.showCompanyTypeSelector = value;

        state.showCompanySizeSelector = false;
        state.showCompanyStatusSelector = false;
    },
};

export default mutations;
