<template>
  <div>
    <p class="type__description">
      Выберите тип данных по которому будут исключаться компании.
    </p>
    <ul class="type__list">
      <li
        v-for="(value, key) in getDataTypeList"
        :key="key"
        :class="getClassesForType(key)"
        @click="setChosenDataType(key)"
      >
        {{ value }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { dataTypeList } from '@/egrul/store/utils/constants';

export default {
  name: 'ChosenDataTypeStep',

  computed: {
    ...mapState('exclusionFile', [
      'chosenType',
    ]),

    getDataTypeList() {
      return dataTypeList;
    },
  },

  methods: {
    ...mapMutations('exclusionFile', [
      'setChosenDataType',
    ]),

    getClassesForType(key) {
      return {
        'chosen-type': this.chosenType === key,
        type: true,
      };
    },
  },
};
</script>

<style scoped>
  .type__list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    margin-top: 20px;
  }

  .type__description {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    cursor: text;
  }

  .type {
    border: 2px solid #fb8c00;
    color: #000;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    padding: 10px;
    border-radius: 8px;
    transition: .2s;
  }

  .type:hover {
    background: var(--color-button-prime-hover);
    color: #fff;
    border-color: transparent;
  }

  .chosen-type {
    background: var(--color-button-prime-hover);
    color: #fff;
    border-color: transparent;
  }
</style>
