<template>
  <div class="additionalFilter">
    <CheckboxRange
      title="выручка"
      unit="млн/год"
      :checkbox-width="110"
      :input-width="60"
      :disabled="disabled"
      :chosen="income.chosen"
      :min-value="incomeMin"
      :max-value="income.maxValue"
      :not-chosen-handler="notChosenHandler('Пожалуйста, активируйте фильтр выручки')"
      @toggle="toggleIncome"
      @changeMinValue="setIncomeMin"
      @changeMaxValue="setIncomeMax"
    />
  </div>
</template>

<script>
import CheckboxRange from 'checkbox-range';
import { notChosenHandlerMixin } from '@/egrul/mixin/notChosenHandlerMixin';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'IncomeFilter',

  components: {
    CheckboxRange,
  },

  mixins: [notChosenHandlerMixin],

  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapState([
      'income',
    ]),

    incomeMin() {
      return this.income.chosen ? this.income.minValue : 0;
    },
  },

  methods: {
    ...mapActions([
      'setIncomeMin',
      'setIncomeMax',
      'toggleIncome',
    ]),
  },
};
</script>

<style scoped>
</style>
