import intersection from 'lodash.intersection';
import uniq from 'lodash.uniq';

export default {
    selectionIsUnionOfPresets(selectedValues, presets) {
        const selectedPresets = this.getSelectedPresets(selectedValues, presets);

        let valuesInUnion = [];
        selectedPresets.forEach((preset) => {
            valuesInUnion.push(...preset.values);
        });
        valuesInUnion = uniq(valuesInUnion);

        return (valuesInUnion.length === selectedValues.length);
    },

    getSelectedPresets(selectedValues, presets) {
        const selectedPresets = [];
        presets.forEach((preset) => {
            if (intersection(selectedValues, preset.values).length === preset.values.length) {
                selectedPresets.push(preset);
            }
        });

        return selectedPresets;
    },
};
