import MultiSelectorUtil from "../../common_components/MultiSelector/MultiSelectorUtil";

const getters = {
    selectedBasicFilters(state) {
        const basicFiltersOrderLength = state.basicFiltersOrder.length;
        const selectedBasicFiltersLength = state.selectedBasicFilters.length;
        const orderedArray = [];

        for (let i = 0; i < basicFiltersOrderLength; i++) {
            if (state.selectedBasicFilters.indexOf(state.basicFiltersOrder[i]) !== -1) {
                orderedArray.push(state.basicFiltersOrder[i]);

                if (orderedArray.length === selectedBasicFiltersLength) {
                    break;
                }
            }
        }

        return orderedArray;
    },
    mspType(state) {
        const selectedTypes = {};

        const mspTypes = state.mspTypes;

        for (let id in mspTypes) {
            if (mspTypes[id].included || mspTypes[id].excluded) {
                selectedTypes[mspTypes[id].id] = {
                    included: mspTypes[id].included,
                    excluded: mspTypes[id].excluded
                };
            }
        }

        return selectedTypes;
    },
    selectedMspType(state) {
        const selectedTypes = [];

        const mspTypes = state.mspTypes;

        for (let id in mspTypes) {
            if (mspTypes[id].included || mspTypes[id].excluded) {
                selectedTypes.push(mspTypes[id].id);
            }
        }

        return selectedTypes;
    },
    selectedCompanyStatuses(state) {
        return state.companyStatuses.filter(companyStatus => {
            return companyStatus.included;
        });
    },
    selectedIdCompanyStatuses(state, getters) {
        const selectedCompanyStatuses = getters.selectedCompanyStatuses;

        return selectedCompanyStatuses.reduce((acc, companyStatus) => {
            if (companyStatus.included) {
                acc.push(companyStatus.id);
            }

            return acc;
        }, []);
    },
    selectedPhoneTypes(state) {
        return MultiSelectorUtil.getSelectedKeys(state.phoneFilter.subFilters, 'name');
    },
    isVerifiedEmail(state) {
        return state.selectedBasicFilters.indexOf('email') !== -1 && state.emailFilter.subFilters[0].selected;
    },
    selectedYear(state) {
        return MultiSelectorUtil.getSelectedKeys(state.years, 'key');
    },
    isSelectedYears(state, getters) {
        return getters.selectedYear.length > 0;
    },
    selectedMonth(state) {
        return MultiSelectorUtil.getSelectedKeys(state.months, 'key');
    },
    isSelectedMonths(state, getters) {
        return getters.selectedMonth.length > 0;
    },
    companyType(state) {
        const selectedTypes = {};

        const companyTypes = state.companyTypes;

        for (let id in companyTypes) {
            if (companyTypes[id].included || companyTypes[id].excluded) {
                selectedTypes[companyTypes[id].id] = {
                    included: companyTypes[id].included,
                    excluded: companyTypes[id].excluded
                };
            }
        }

        return selectedTypes;
    },
    selectedCompanyType(state) {
        const selectedTypes = [];

        const companyTypes = state.companyTypes;

        for (let id in companyTypes) {
            if (companyTypes[id].included || companyTypes[id].excluded) {
                selectedTypes.push(companyTypes[id].id);
            }
        }

        return selectedTypes;
    },
    employees(state) {
        return state.employees;
    },
    selectedOptionalFilters(state) {
        return state.selectedOptionalFilters;
    },

    expandAdditionalFilters(state) {
        return state.expandAdditionalFilters;
    },
    additionalFiltersChosen(state, getters) {
        return state.employees.chosen ||
            state.income.chosen ||
            state.profit.chosen ||
            getters.isSelectedMonths ||
            getters.isSelectedYears;
    },

    /**
     * @param state
     * @returns {object} - диапазон выручки в тыс. руб.
     * используется для расчета по БД, где выручка в тыс. руб.
     */
    incomeForCalculationRequest(state) {
        return {
            ...state.income,
            minValue: state.income.minValue * 1000,
            maxValue: state.income.maxValue * 1000,
        };
    },

    profitForCalculationRequest(state) {
        return {
            ...state.profit,
            minValue: state.profit.minValue * 1000,
            maxValue: state.profit.maxValue * 1000,
        };
    },

    getActiveGroupId(state) {
        return state.companyTypeGroups.find((group) => group.active) ?
            state.companyTypeGroups.find((group) => group.active).id : null;
    },

    getFilters(state) {
        return [
            state.nameFilter,
            state.ogrnSelectedFilter,
            state.phoneFilter,
            state.addressFilter,
            state.emailFilter,
            state.siteFilter,
            state.decisionMakerFilter,
            state.showCompanySizeFilter,
            state.registrationDateFilter,
        ];
    },

    getFiltersForCalculationRequest(state, getters) {
        return {
            basic: getters.selectedBasicFilters,
            msp: getters.mspType,
            company_status: getters.selectedIdCompanyStatuses,
            reg_year: getters.selectedYear,
            reg_month: getters.selectedMonth,
            okopfs: getters['okopfTree/getOkopfsForCalculationRequest'],
            company_type: getters.companyType,
            employees: getters.employees,
            income: getters.incomeForCalculationRequest,
            profit: getters.profitForCalculationRequest,
            phone: getters.selectedPhoneTypes,
            email_verified: getters.isVerifiedEmail,
            optional_filters: getters.selectedOptionalFilters,
            exclusion_data: getters["exclusionFile/getDataForConfig"],
            has_site_phone: getters.getHasSitePhone,
            has_messenger: getters.getHasMessenger,
            options: getters.getAdditionalOptions,
            include_additional_okved: getters['okvedTree/getIncludeAdditionalOkved'],
        };
    },

    getAdditionalOptions(state) {
        return {
            show_inc_and_profit_for_last_years: state.show_inc_and_profit_for_last_years,
        };
    },

    getHasSitePhone(state) {
        return state.phoneFilter.selected && state.has_site_phone;
    },

    getHasMessenger(state) {
        return state.hasMessengerFilter.selected;
    },

    getDataForCalculationRequest(state, getters) {
        return {
            cities: getters['tree/selectedGeoUnits'],
            filters: getters.getFiltersForCalculationRequest,
            okveds: getters['okvedTree/getSelectedOkveds'],
            count: getters['summary/totalCount'],
        };
    },

    getGroupIds(state) {
        return state.companyTypeGroups.map(group => group.id);
    },
};

export default getters;
