// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./xlsx_table_image.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".actualization__client_file_representation__container[data-v-22654205] {\n  display: flex;\n  box-sizing: border-box;\n  border: 1px solid black;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") top left no-repeat;\n  height: 139px;\n  padding: 0 0 0 38px;\n}\n.actualization__client_file_representation_content__wrapper[data-v-22654205] {\n  width: 100%;\n  height: 100%;\n}\n.actualization__client_file_representation_row[data-v-22654205] {\n  display: flex;\n  flex-direction: row;\n}\n.actualization__client_file_representation_column[data-v-22654205] {\n  display: flex;\n  flex-direction: column;\n  box-sizing: border-box;\n  width: 82px;\n  cursor: pointer;\n}\n.actualization__client_file_representation_column[data-v-22654205]:hover {\n  background-color: rgba(34, 177, 42, 0.2);\n}\n.actualization__client_file_representation_column[data-v-22654205]:last-child {\n  width: 41px;\n}\n.actualization__client_file_representation_cell[data-v-22654205] {\n  display: flex;\n  box-sizing: border-box;\n  width: inherit;\n  height: 17.3px;\n  padding: 4px 0 0 4px;\n}\n.actualization__client_file_representation_cell_span[data-v-22654205] {\n  width: inherit;\n  overflow: hidden;\n  font-size: 11px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n.actualization__active_cell[data-v-22654205] {\n  background-color: rgba(34, 177, 42, 0.4);\n}\n@media (max-width: 950px) {\n.actualization__client_file_representation__container[data-v-22654205] {\n    background-size: cover;\n}\n}\n", ""]);
// Exports
module.exports = exports;
