<template>
  <section
    id="vue_egrul_summary"
    class="summary"
  >
    <GrayPreloader :visible="isCalculating" />
    <EgrulSummary />
  </section>
</template>

<script>
import { mapState } from 'vuex';
import GrayPreloader from '@/common_components/GrayPreloader/GrayPreloader.vue';
import EgrulSummary from '@/egrul/components/EgrulSummary/EgrulSummary.vue';

export default {
  name: 'OrderBucket',

  components: {
    EgrulSummary,
    GrayPreloader,
  },

  computed: {
    ...mapState([
      'isCalculating',
    ]),
  },
};
</script>

<style scoped>
#vue_egrul_summary {
  display: flex;
  justify-content: start;
  margin-right: 0;
  margin-top: 0;
  grid-row: 1/3;
  grid-column: 2/3;
}

.summary {
  position: relative;
  height: 100%;
}
</style>
