<template>
  <div
    class="selectorOption"
    :class="[{selectorOptionWide: isAllTypesSelector},
             {selectorOptionIncluded: optionObj.included || (isAllTypesSelector && allTypesSelected)},
             {selectorOptionExcluded: optionObj.excluded && !isAllTypesSelector},
             {'active': optionObj.isActive},
             modeClasses,
    ]"
  >
    <div class="selectorOptionLabel">
      {{ selectorLabel }}
      <span
        v-if="optionObj.popupUrl"
        :data-file="optionObj.popupUrl"
        :data-method="isLicensesOption ? 'GET' : 'POST'"
        class="_popup licenses_instruction"
      />
      <span
        v-if="optionObj.description"
        class="licenses_instruction"
      >
        <span class="selectorOptionLabel__description">
          {{ optionObj.description }}
        </span>
      </span>
    </div>

    <div
      v-if="!isAllTypesSelector"
      class="selectorInclusionOptionBlock"
    >
      <div
        :title="[ 'Добавляет в ваш заказ ' + selectorTitle]"
        class="selectorCheckboxCell selectorCell"
        @click="inclusionClick"
      >
        <Checkbox
          :mode="['exportbase', 'dark']"
          :chosen="optionObj.included"
        />
      </div>
      <div
        v-if="!onlyIncluded"
        :title="[ 'Исключает из вашего заказа ' + selectorTitle]"
        class="selectorCheckboxCell selectorCell"
        @click="exclusionClick"
      >
        <Checkbox
          :mode="['exportbase', 'dark']"
          :chosen="optionObj.excluded"
        />
      </div>
    </div>

    <div
      v-if="isAllTypesSelector"
      class="selectorInclusionOptionBlock"
    >
      <div
        :title="[ 'Добавляет в ваш заказ ' + selectorTitle]"
        class="selectorCheckboxCell selectorCell"
        @click="toggleAllTypes"
      >
        <Checkbox
          :mode="['exportbase', 'dark']"
          :chosen="allTypesSelected"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from 'checkbox';

export default {
  name: 'IncludeExcludeSelector',
  components: {
    Checkbox,
  },

  props: {
    selectorLabel: {
      type: String,
      required: true,
    },

    selectorLabelAccusative: {
      type: String,
      required: false,
      default: '',
    },

    optionObj: {
      validator(value) {
        return value.hasOwnProperty('included');
      },

      required: false,
      default: () => ({
        included: false,
        excluded: false,
      }),
    },

    isAllTypesSelector: {
      type: Boolean,
      required: false,
    },

    allTypesSelected: {
      type: Boolean,
      required: false,
    },

    isLicensesOption: {
      type: Boolean,
      required: false,
    },

    onlyIncluded: {
      type: Boolean,
      required: false,
      default: false,
    },

    mode: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: ['inclusion', 'exclusion', 'allTypes'],

  computed: {
    selectorTitle() {
      if (this.labelIsAbbreviation()) {
        return this.selectorLabel;
      } if (this.isAllTypesSelector || (this.selectorLabelAccusative === undefined || this.selectorLabelAccusative === null)) {
        return this.selectorLabel.charAt(0).toLowerCase() + this.selectorLabel.slice(1);
      }
      return this.selectorLabelAccusative.charAt(0).toLowerCase() + this.selectorLabelAccusative.slice(1);
    },

    modeClasses() {
      return {
        egrulCompanyTypeMode: this.mode === 'egrulCompanyTypeMode',
      };
    },
  },

  methods: {
    inclusionClick() {
      this.$emit('inclusion');
    },

    exclusionClick() {
      this.$emit('exclusion');
    },

    toggleAllTypes() {
      this.$emit('allTypes');
    },

    labelIsAbbreviation() {
      const secondSymbol = this.selectorLabel[1];
      return secondSymbol.toLowerCase() !== secondSymbol;
    },
  },
};
</script>

<style scoped src="./IncludeExcludeSelector.css" />
