<template>
  <div class="row-legal-form">
    <p class="row-legal-form__description">
      Правовая форма:
    </p>
    <p class="row-legal-form__value">
      {{ selectedOkopfsDescription }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'RowLegalForm',

  computed: {
    ...mapGetters('okopfTree', [
      'selectedOkopfsDescription',
    ]),
  },
};
</script>

<style>
.row-legal-form {
  font-weight: bold;
  display: grid;
  grid-template-columns: 130px 1fr;
  justify-items: start;
  align-items: flex-start;
}

.row-legal-form__value {
  font-weight: normal;
}
</style>
