<template>
  <DropdownConfigurator
    id="companyTypeConfiguratorButton"
    :content="selectedCompanyTypeDescription"
    title="Тип компании:"
    width="200px"
    :is-open="showCompanyTypeSelector"
    @clickButton="toggleCompanyTypeSelector"
  >
    <CompanyTypeSelector />
  </DropdownConfigurator>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import DropdownConfigurator from '@/egrul/components/DropListConfigurator/DropdownConfigurator.vue';
import CompanyTypeSelector from '@/egrul/components/CompanyTypeSelector/CompanyTypeSelector.vue';

export default {
  name: 'CompanyTypeConfigurator',

  components: {
    CompanyTypeSelector,
    DropdownConfigurator,
  },

  computed: {
    ...mapState([
      'companyTypes',
      'showCompanyTypeSelector',
    ]),

    selectedCompanyTypeDescription() {
      const selectedCompanyTypes = Object.keys(this.companyTypes)
        .filter((companyType) => this.companyTypes[companyType].included
              || this.companyTypes[companyType].excluded);

      if (selectedCompanyTypes.length === 0) {
        return 'Все компании';
      } if (selectedCompanyTypes.length === 1) {
        if (this.companyTypes[selectedCompanyTypes[0]].included) {
          return this.companyTypes[selectedCompanyTypes[0]].title;
        }
        return `Исключить ${this.companyTypeNameToLowerCase(
          this.companyTypes[selectedCompanyTypes[0]].title,
        )}`;
      }
      return `${selectedCompanyTypes.length} шт.`;
    },
  },

  methods: {
    ...mapMutations([
      'toggleShowCompanyTypeSelector',
    ]),

    companyTypeNameToLowerCase(value) {
      const isAbbreviation = value[0] === value[0].toUpperCase()
          && value[1] === value[1].toUpperCase();
      if (isAbbreviation) {
        return value;
      }
      return value.charAt(0).toLowerCase() + value.slice(1);
    },

    toggleCompanyTypeSelector() {
      if (this.showCompanyTypeSelector) {
        this.closeCompanyTypeSelector();
      } else {
        this.toggleShowCompanyTypeSelector();
        document.addEventListener('click', this.clickHandler);
      }
    },

    closeCompanyTypeSelector() {
      document.removeEventListener('click', this.clickHandler);
      this.toggleShowCompanyTypeSelector();
    },

    clickHandler(event) {
      const clickedButton = event.target;
      const insideSelector = clickedButton.closest('.dropdownBody');
      const insideSelectorButton = clickedButton.closest('.selectMenu');
      if (this.showCompanyTypeSelector && !insideSelector && !insideSelectorButton) {
        this.closeCompanyTypeSelector();
      }
    },
  },
};
</script>

<style scoped>
</style>
