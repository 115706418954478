import { createStore } from 'vuex';
import actions from './actions';
import mutations from './mutations';
import state from './state';
import getters from './getters';
import summary from "./modules/summary";
import tree from './modules/tree';
import exclusionFile from "./modules/exclusionFile";
import payPopup from './modules/payPopup';
import okvedTree from "@/egrul/store/modules/okvedTree";
import okopfTree from '@/egrul/store/modules/okopfTree';

const store = createStore({
    state,
    getters,
    mutations,
    actions,
    modules: {
        summary,
        tree,
        exclusionFile,
        payPopup,
        okvedTree,
        okopfTree,
    },
});

export default store;
