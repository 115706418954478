<!-- компонент реализуется уникально для каждого радела -->

<template>
  <div v-if="showNotification">
    <NotificationComponent
      class="card-notification__body"
      flipper-position="without-flipper"
    >
      <div v-if="companiesNotFound">
        <span
          v-if="(ipWithContactsSelected)"
          class="with-warn-icon"
        >
          У ИП в настоящее время не указываются контактные данные: телефон, мессенджер и Email.
          <br>
          Однако можно добавить в заказ сайты, где нередко присутствуют контактные данные компаний.
        </span>
        <span v-else>
          К сожалению, по данной конфигурации в нашей базе нет фирм.
          Попробуйте выбрать больше городов и/или ОКВЭД или включить менее жесткие фильтры.
        </span>
      </div>

      <div v-if="companyCost > 0">
        В выгрузке будут представлены только действующие
        компании с известными и подтвержденными контактными данными.
      </div>

      <div v-if="companyCost >= CASHLESS_NOTIFICATION">
        Можно оплатить от организации по
        <span
          class="card-notification__clickable-text"
          @click="openCashlessPopup"
        >
          безналичному расчету
        </span>
      </div>

      <div v-if="companyCost > MIN_COST_VIP_NOTIFICATION">
        При покупке больших объемов информации,
        вы можете получить дополнительную скидку от 5 до 60% от стоимости базы. <br> <br>
        Напишите нам на
        <a
          class="card-notification__link"
          href="../../../../../../index.php"
          target="_blank"
        >info@export-base.ru
        </a>
        или позвоните 8 (800) 775-29-12.
      </div>
    </NotificationComponent>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import NotificationComponent from '../../../../../common_components/NotificationComponent/NotificationComponent.vue';

export default {
  name: 'EgrulNotificationList',
  components: {
    NotificationComponent,
  },

  props: {
    companyCost: {
      type: Number,
      required: true,
    },

    selectedOkvedCount: {
      type: Number,
      required: true,
    },

    selectedGeoCount: {
      type: Number,
      required: true,
    },
  },

  emits: ['openOkvedTree', 'openCityTree', 'openCashlessPopup'],

  data() {
    return {
      CASHLESS_NOTIFICATION: 3000,
      MIN_COST_VIP_NOTIFICATION: 20000,
    };
  },

  computed: {
    ...mapState([
      'phoneFilter',
      'hasMessengerFilter',
    ]),

    ...mapGetters('okopfTree', [
      'ipPresetFullySelected',
      'getSelectedOkopfs',
    ]),

    companiesNotFound() {
      return (
        (this.selectedGeoCount > 0)
        && (this.selectedOkvedCount > 0)
        && (this.companyCost === 0)
      );
    },

    ipWithContactsSelected() {
      return (
        this.ipPresetFullySelected
        && this.getSelectedOkopfs.length === 1
        && (
          this.phoneFilter.selected
          || this.hasMessengerFilter.selected
        )
      );
    },

    showNotification() {
      return this.companiesNotFound || this.companyCost > 0
        || this.companyCost >= this.CASHLESS_NOTIFICATION
        || this.companyCost > this.MIN_COST_VIP_NOTIFICATION;
    },
  },

  methods: {
    openOkvedTree() {
      this.$emit('openOkvedTree');
    },

    openCityTree() {
      this.$emit('openCityTree');
    },

    openCashlessPopup() {
      this.$emit('openCashlessPopup');
    },
  },
};
</script>

<style scoped
       src="../../../../../common_components/NotificationComponent/NotificationComponent.css"
/>

<style scoped>
  .with-warn-icon {
    display: inline-block;
    padding-left: 35px;
    background-image: url("/dist/css/images/icon_warning.png");
    background-repeat: no-repeat;
    background-size: 27px;
    background-position: left center;
  }
</style>
