<template>
  <main class="mainConfigurator">
    <ConfiguratorBlock />

    <OptionBlock />
  </main>
</template>

<script>
import ConfiguratorBlock from '@/egrul/components/ConfiguratorBlock/ConfiguratorBlock.vue';
import OptionBlock from '@/egrul/components/OptionBlock/OptionBlock.vue';

export default {
  name: 'MainConfigurator',

  components: {
    OptionBlock,
    ConfiguratorBlock,
  },
};
</script>

<style scoped>
.mainConfigurator {
  display: grid;
  grid-template-columns: 2fr 3fr;
}
</style>
