<template>
  <div class="additionalFilter">
    <CheckboxRange
      title="численность"
      unit="чел."
      :checkbox-width="110"
      :input-width="60"
      :disabled="disabled"
      :chosen="employees.chosen"
      :min-value="employeesMin"
      :max-value="employees.maxValue"
      :not-chosen-handler="notChosenHandler('Пожалуйста, активируйте фильтр численности')"
      @toggle="toggleEmployees"
      @changeMinValue="setEmployeesMin"
      @changeMaxValue="setEmployeesMax"
    />
  </div>
</template>

<script>
import CheckboxRange from 'checkbox-range';
import { mapActions, mapState } from 'vuex';
import { notChosenHandlerMixin } from '@/egrul/mixin/notChosenHandlerMixin';

export default {
  name: 'EmployeeFilter',

  components: {
    CheckboxRange,
  },

  mixins: [notChosenHandlerMixin],

  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapState([
      'employees',
    ]),

    employeesMin() {
      return this.employees.chosen ? this.employees.minValue : 0;
    },
  },

  methods: {
    ...mapActions([
      'setEmployeesMin',
      'setEmployeesMax',
      'toggleEmployees',
    ]),
  },
};
</script>

<style scoped>
</style>
