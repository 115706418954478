<template>
  <div class="additionalCommentWrapper">
    <div class="additionalComment">
      Если вы не нашли нужного фильтра в интерфейсе сайта,
      свяжитесь с нами по телефону <a
        class="additionalCommentLinkPhone"
        target="_blank"
        href="callto:+78007752912"
      >8 (800) 775-29-12</a>
      или напишите нам: <a
        class="additionalCommentLinkEmail"
        target="_blank"
        href="mailto:info@export-base.ru"
      >info@export-base.ru.</a>
    </div>

    <div class="additionalComment">
      Мы поможем подобрать базу в соответствии с вашими уникальными критерими.
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdditionalComment',
};
</script>

<style scoped>
.additionalCommentWrapper {
  display: grid;
  gap: 10px;
}

.additionalComment {
  font-style: italic;
}

.additionalCommentLinkPhone {
  text-decoration: none;
}

.additionalCommentLinkEmail {
  font-weight: bold;
  color: #1b88c1;
}
</style>
