import ru from 'dayjs/locale/ru';
import localeData from 'dayjs/plugin/localeData';
import dayjs from 'dayjs';
import api from '../api';

const actions = {
    changePhoneSubFilter({ dispatch }, subFilterName) {
        dispatch('togglePhoneSubFilters', subFilterName);
        dispatch('calculateConfiguration');
    },
    changeEmailVerifiedSubFilter({ dispatch }) {
        dispatch('toggleEmailVerifiedSubFilter');
        dispatch('calculateConfiguration');
    },
    toggleAllCompanyTypeSelected({ commit, dispatch }) {
        commit('toggleAllCompanyTypeSelected');
        dispatch('calculateConfiguration');
    },
    toggleAllMspTypeSelected({ commit, dispatch }) {
        commit('toggleAllMspTypeSelected');
        commit('toggleDisableAdditionalFilters');
        dispatch('calculateConfiguration');
    },
    toggleIncludeMspType({ commit, dispatch }, typeOfMsp) {
        commit('toggleIncludeMspType', typeOfMsp);
        commit('toggleDisableAdditionalFilters');
        dispatch('calculateConfiguration');
    },
    toggleExcludeMspType({ commit, dispatch }, typeOfMsp) {
        commit('toggleExcludeMspType', typeOfMsp);
        commit('toggleDisableAdditionalFilters');
        dispatch('calculateConfiguration');
    },
    toggleIncludeCompanyType({ commit, dispatch }, typeOfCompany) {
        commit('toggleIncludeCompanyType', typeOfCompany);
        dispatch('calculateConfiguration');
    },
    toggleExcludeCompanyType({ commit, dispatch }, typeOfCompany) {
        commit('toggleExcludeCompanyType', typeOfCompany);
        dispatch('calculateConfiguration');
    },
    toggleIncludeCompanyStatus({ commit, dispatch, getters }, statusId) {
        const { selectedCompanyStatuses } = getters;

        if (selectedCompanyStatuses.length > 1) {
            commit('toggleIncludeCompanyStatus', statusId);
            dispatch('calculateConfiguration');
        } else {
            const isContainsSelectedStatus = selectedCompanyStatuses.some((item) => item.id === statusId);

            if (!isContainsSelectedStatus) {
                commit('toggleIncludeCompanyStatus', statusId);
                dispatch('calculateConfiguration');
            }
        }
    },
    togglePhone({ commit, dispatch }, isPartiallySelectedChange = false) {
        commit('togglePhone', isPartiallySelectedChange);
        dispatch('calculateConfiguration');
    },
    togglePhoneSubFilters({ commit }, subFilterName) {
        commit('togglePhoneSubFilters', subFilterName);
    },
    toggleEmailVerifiedSubFilter({ commit }) {
        commit('toggleEmailVerifiedSubFilter');
    },
    toggleAddress({ commit, dispatch }, isPartiallySelectedChange = false) {
        commit('toggleAddress', isPartiallySelectedChange);
        dispatch('calculateConfiguration');
    },
    toggleEmail({ commit, dispatch }, isPartiallySelectedChange = false) {
        commit('toggleEmail', isPartiallySelectedChange);
        dispatch('calculateConfiguration');
    },
    toggleSite({ commit, dispatch }, isPartiallySelectedChange = false) {
        commit('toggleSite', isPartiallySelectedChange);
        dispatch('calculateConfiguration');
    },
    toggleDecisionMaker({ commit, dispatch }, isPartiallySelectedChange = false) {
        commit('toggleDecisionMaker', isPartiallySelectedChange);
        dispatch('calculateConfiguration');
    },
    toggleShowCompanySize({ commit, dispatch }, isPartiallySelectedChange = false) {
        commit('toggleShowCompanySize', isPartiallySelectedChange);
        dispatch('calculateConfiguration');
    },
    toggleRegistrationDate({ commit, dispatch }, isPartiallySelectedChange = false) {
        commit('toggleRegistrationDate', isPartiallySelectedChange);
        dispatch('calculateConfiguration');
    },
    toggleHasMessenger({ commit, dispatch }) {
        commit('toggleHasMessenger');
        dispatch('calculateConfiguration');
    },
    toggleOgrn({ commit, dispatch }, isPartiallySelectedChange = false) {
        commit('toggleOgrn', isPartiallySelectedChange);
        dispatch('calculateConfiguration');
    },
    toggleIncAndProfitForLastYears({ commit, dispatch }) {
        commit('toggleIncAndProfitForLastYears');
        dispatch('calculateConfiguration');
    },
    toggleHasSitePhone({ commit, dispatch }) {
        commit('toggleHasSitePhone');
        dispatch('calculateConfiguration');
    },
    async setYears({ commit }) {
        const years = await api.getYears();
        const yearsState = years.map((year) => ({
            key: year,
            name: year,
            selected: false,
        }));
        commit('setYears', yearsState);
    },
    setMonths({ commit }) {
        dayjs.locale(ru);
        dayjs.extend(localeData);
        const months = [];
        const monthNames = dayjs.months();
        for (let i = 0; i < monthNames.length; i++) {
            months[i] = {
                key: i + 1,
                name: monthNames[i],
                selected: false,
            };
        }
        commit('setMonths', months);
    },
    yearChange({ commit, dispatch }, year) {
        commit('yearChange', year);
        dispatch('calculateConfiguration');
    },
    monthChange({ commit, dispatch }, month) {
        commit('monthChange', month);
        dispatch('calculateConfiguration');
    },
    allYearSelect({ commit, dispatch }) {
        commit('allYearSelect');
        dispatch('calculateConfiguration');
    },
    allMonthSelect({ commit, dispatch }) {
        commit('allMonthSelect');
        dispatch('calculateConfiguration');
    },
    toggleEmployees({ commit, dispatch }) {
        commit('toggleEmployees');
        dispatch('calculateConfiguration');
    },
    toggleIncome({ commit, dispatch }) {
        commit('toggleIncome');
        dispatch('calculateConfiguration');
    },
    setEmployeesMin({ commit, dispatch }, value) {
        commit('setEmployeesMin', value);
        dispatch('calculateConfiguration');
    },
    setEmployeesMax({ commit, dispatch }, value) {
        commit('setEmployeesMax', value);
        dispatch('calculateConfiguration');
    },
    setIncomeMin({ commit, dispatch }, value) {
        commit('setIncomeMin', value);
        dispatch('calculateConfiguration');
    },
    setIncomeMax({ commit, dispatch }, value) {
        commit('setIncomeMax', value);
        dispatch('calculateConfiguration');
    },
    setProfitMin({ commit, dispatch }, value) {
        commit('setProfitMin', value);
        dispatch('calculateConfiguration');
    },
    setProfitMax({ commit, dispatch }, value) {
        commit('setProfitMax', value);
        dispatch('calculateConfiguration');
    },
    toggleProfit({ commit, dispatch }) {
        commit('toggleProfit');
        dispatch('calculateConfiguration');
    },

    async setIncludeExcludeSelectorData({ commit, dispatch }) {
        const includeExcludeSelectorData = await api.getIncludeExcludeSelectorData();

        commit('setCompanyTypes', includeExcludeSelectorData.company_type);
        commit('setFuse', includeExcludeSelectorData.company_type);
        commit('setCompanyTypeGroups', includeExcludeSelectorData.company_type_group);
        commit('setMspTypes', includeExcludeSelectorData.msp_type);
        commit('setCompanyStatuses', includeExcludeSelectorData.company_statuses);

        dispatch('showCompanyTypesFromLeftSidebar', window.location.hash);
    },

    showCompanyTypesFromLeftSidebar({ commit, dispatch }, companyType) {
        switch (companyType) {
        case '#on_wb':
            commit('setShowCompanyType', true);
            dispatch('showOnWbCompanyTypes');
            break;
        case '#manufacturer':
            commit('setShowCompanyType', true);
            dispatch('showManufacturerCompanyTypes');
            break;
        default:
            break;
        }

        if (companyType == '#on_wb' || companyType == '#manufacturer') {
            setTimeout(() => {
                window.scrollTo({
                    top: window.scrollY
                        + document.getElementById('companyTypeConfiguratorButton')
                            .getBoundingClientRect().top
                        - 100,
                    behavior: 'smooth',
                }, 120);
            });
        }
    },

    async setPhoneTypes({ commit, dispatch }) {
        const phoneTypes = await api.getPhoneTypes();
        commit('setPhoneTypes', phoneTypes);
        dispatch('summary/setSelectedConfiguratorFields');
    },

    async calculateConfiguration({
        state, commit, dispatch, getters,
    }) {
        dispatch('summary/setSelectedConfiguratorFields');

        commit('setHighLightGeoButton', !getters['tree/hasSelectedCities']);
        commit('setHighLightOkvedButton', getters['okvedTree/okvedIsEmpty']);
        commit('setHighLightOkopfButton', getters['okopfTree/okopfIsEmpty']);

        if (state.isCalculating) {
            commit('setNeedRecalculation', true);
            return;
        }

        commit('setNeedRecalculation', false);
        commit('setCalculating', true);

        const res = await api.calculate(getters.getDataForCalculationRequest);

        commit('setCalculating', false);

        if (res.data.no_config) {
            dispatch('summary/resetPageInfo');
        } else {
            dispatch('summary/updatePageInfo', res.data);
        }

        if (state.needRecalculation) {
            dispatch('calculateConfiguration');
        }
    },

    toggleVisibleCompanyTypesByGroupId({ commit }, groupId) {
        commit('toggleVisibleCompanyTypesByGroupId', groupId);
        commit('toggleActiveGroup', groupId);
    },

    hideAllCompanyTypesGroups({ commit, getters }) {
        const groupIds = getters.getGroupIds;

        groupIds.forEach((groupId) => commit('setShowCompanyTypeGroup', { groupId, show: false }));
    },

    showAllCompanyTypesGroups({ commit, getters }) {
        const groupIds = getters.getGroupIds;

        groupIds.forEach((groupId) => commit('setShowCompanyTypeGroup', { groupId, show: true }));
    },

    showCompanyTypesGroupsByIds({ commit, dispatch }, groupIds) {
        dispatch('hideAllCompanyTypesGroups');
        groupIds.forEach((groupId) => commit('setShowCompanyTypeGroup', { groupId, show: true }));
    },

    resetSearch({ commit, dispatch }) {
        dispatch('showAllCompanyTypesGroups');
        commit('resetActiveGroups');
        commit('hideAllCompanyTypes');
    },

    search({ state, commit, dispatch }) {
        const searchedCompanyTypes = state.fuse.search(state.searchCompanyType).map((itemData) => itemData.item);

        const groupIds = [...new Set(searchedCompanyTypes.map((companyType) => companyType.groupId))];

        dispatch('showCompanyTypesGroupsByIds', groupIds);
        commit('activateAllGroups');
        commit('visibleAllCompanyTypes');
    },

    showOnWbCompanyTypes({ commit }) {
        // commit('resetActiveGroups');
        commit('setActiveGroup', 6);
        commit('setVisibleCompanyTypesByGroupId', 6);
        commit('highlightActiveType', 7);
        commit('highlightActiveType', 19);
        commit('highlightActiveType', 20);
        commit('highlightActiveType', 23);
    },

    showManufacturerCompanyTypes({ commit }) {
        // commit('resetActiveGroups');
        commit('setActiveGroup', 9);
        commit('setVisibleCompanyTypesByGroupId', 9);
        commit('highlightActiveType', 39);
    },

    async setInitFilters({ dispatch, commit }, initFiltersConfig) {
        if (initFiltersConfig && Object.keys(initFiltersConfig).length !== 0) {
            Object.entries(initFiltersConfig).forEach(([key, filter]) => {
                const { isPartiallySelected, selected } = filter;
                if (selected) {
                    switch (key) {
                    case 'ogrn':
                        commit('toggleOgrn');
                        if (isPartiallySelected) {
                            commit('toggleOgrn', true);
                        }
                        break;
                    case 'address':
                        commit('toggleAddress');
                        if (isPartiallySelected) {
                            commit('toggleAddress', true);
                        }
                        break;
                    case 'email':
                        commit('toggleEmail');
                        if (isPartiallySelected) {
                            commit('toggleEmail', true);
                        }
                        if (filter.subFilters && filter.subFilters.length === 1) {
                            if (filter.subFilters[0].selected) {
                                commit('toggleEmailVerifiedSubFilter');
                            }
                        }
                        break;
                    case 'site':
                        commit('toggleSite');
                        if (isPartiallySelected) {
                            commit('toggleSite', true);
                        }
                        break;
                    case 'decisionMaker':
                        commit('toggleDecisionMaker');
                        if (isPartiallySelected) {
                            commit('toggleDecisionMaker', true);
                        }
                        break;
                    case 'showCompanySize':
                        commit('toggleShowCompanySize');
                        if (isPartiallySelected) {
                            commit('toggleShowCompanySize', true);
                        }
                        break;
                    case 'registrationDate':
                        commit('toggleRegistrationDate');
                        if (isPartiallySelected) {
                            commit('toggleRegistrationDate', true);
                        }
                        break;
                    case 'hasMessenger':
                        commit('toggleHasMessenger');
                        if (isPartiallySelected) {
                            commit('toggleHasMessenger', true);
                        }
                        break;
                    case 'phone':
                        commit('togglePhone');
                        if (isPartiallySelected) {
                            commit('togglePhone', true);
                        }
                        if (filter.subFilters && filter.subFilters.length > 0) {
                            filter.subFilters.forEach((subFilter) => {
                                if (subFilter.selected === true) {
                                    dispatch('togglePhoneSubFilters', subFilter.key);
                                }
                            });
                        }
                        break;
                    default:
                        break;
                    }
                }
            });
        }
    },
    async setInitTypesData({ dispatch, commit }, initDataConfig) {
        if (initDataConfig && Object.keys(initDataConfig).length !== 0) {
            dispatch('tree/setGeoUnits', {
                region: initDataConfig.geoUnits.regions,
                city: initDataConfig.geoUnits.cities,
                country: initDataConfig.geoUnits.country,
            });
            if (initDataConfig.okveds.codes.length !== 0) {
                dispatch('okvedTree/chooseOkveds', initDataConfig.okveds.codes);
            }
            Object.entries(initDataConfig).forEach(([key, data]) => {
                switch (key) {
                case 'companyStatuses':
                    data.statuses.forEach((status) => {
                        if (status.included) {
                            commit('toggleIncludeCompanyStatus', status.id);
                        }
                    });
                    break;
                case 'companyTypes': {
                    const allFieldsNotTrue = data.types.every((obj) => (
                        !('included' in obj) || !obj.included)
                      && (!('excluded' in obj) || !obj.excluded));

                    if (data.types.length === 0 || allFieldsNotTrue) {
                        commit('toggleAllCompanyTypeSelected');
                        break;
                    }
                    data.types.forEach((companyType) => {
                        if (companyType.included) {
                            commit('toggleIncludeCompanyType', companyType.id);
                        }
                        if (companyType.excluded) {
                            commit('toggleExcludeCompanyType', companyType.id);
                        }
                    });
                    break;
                }
                case 'companySizes': {
                    const allFieldsNotTrue = data.sizes.every((obj) => (
                        !('included' in obj) || !obj.included)
                      && (!('excluded' in obj) || !obj.excluded));
                    if (data.sizes.length === 0 || allFieldsNotTrue) {
                        commit('toggleAllMspTypeSelected');
                        break;
                    }
                    data.sizes.forEach((companySize) => {
                        if (companySize.included) {
                            commit('toggleIncludeMspType', companySize.id);
                        }
                        if (companySize.excluded) {
                            commit('toggleExcludeMspType', companySize.id);
                        }
                    });
                    break;
                }
                default:
                    break;
                }
            });
        }
    },

    async setInitAdditionalFilters({ commit }, initAddFiltersConfig) {
        if (initAddFiltersConfig && Object.keys(initAddFiltersConfig).length !== 0) {
            commit('toggleExpandAdditionalFilters');
            if (initAddFiltersConfig.initMonths && initAddFiltersConfig.initMonths.length > 0) {
                initAddFiltersConfig.initMonths.forEach((month) => {
                    commit('monthChange', month);
                });
            }
            if (initAddFiltersConfig.initYears && initAddFiltersConfig.initYears.length > 0) {
                initAddFiltersConfig.initYears.forEach((year) => {
                    commit('yearChange', year);
                });
            }
            Object.entries(initAddFiltersConfig).forEach(([key, filter]) => {
                const { selected } = filter;
                if (selected) {
                    switch (key) {
                    case 'sitePhone':
                        commit('toggleHasSitePhone');
                        break;
                    case 'lastYearsIncAndProfit':
                        commit('toggleIncAndProfitForLastYears');
                        break;
                    case 'employees':
                        commit('toggleEmployees');
                        commit('setEmployeesMin', filter.employeesMin);
                        commit('setEmployeesMax', filter.employeesMax);
                        break;
                    case 'income':
                        commit('toggleIncome');
                        commit('setIncomeMin', filter.incomeMin);
                        commit('setIncomeMax', filter.incomeMax);
                        break;
                    case 'profit':
                        commit('toggleProfit');
                        commit('setProfitMin', filter.profitMin);
                        commit('setProfitMax', filter.profitMax);
                        break;
                    default:
                        break;
                    }
                }
            });
        }
    },
};

export default actions;
