<template>
  <div class="additionalFilter">
    <CheckboxRange
      title="прибыль"
      unit="млн/год"
      :checkbox-width="110"
      :input-width="60"
      :disabled="disabled"
      :chosen="profit.chosen"
      :min-value="profitMin"
      :max-value="profitMax"
      :not-chosen-handler="notChosenHandler('Пожалуйста, активируйте фильтр прибыли')"
      @toggle="toggleProfit"
      @changeMinValue="changeMinValueHandle"
      @changeMaxValue="changeMaxValueHandle"
    />
  </div>
</template>

<script>
import CheckboxRange from 'checkbox-range';
import { notChosenHandlerMixin } from '@/egrul/mixin/notChosenHandlerMixin';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ProfitFilter',

  components: {
    CheckboxRange,
  },

  mixins: [notChosenHandlerMixin],

  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      minValue: 0,
      maxValue: 500,
    };
  },

  computed: {
    ...mapState([
      'profit',
    ]),

    profitMin() {
      return this.profit.chosen ? this.profit.minValue : this.minValue;
    },

    profitMax() {
      return this.profit.chosen ? this.profit.maxValue : this.maxValue;
    },
  },

  methods: {
    ...mapActions([
      'setProfitMin',
      'setProfitMax',
      'toggleProfit',
    ]),

    changeMinValueHandle(value) {
      this.minValue = value;

      if (value === '-0' || value === '0-' || value === '-') {
        this.minValue = '-';
        return;
      }

      this.setProfitMin(value);
      this.minValue = this.profit.minValue;
    },

    changeMaxValueHandle(value) {
      this.maxValue = value;

      if (value === '-0' || value === '0-' || value === '-') {
        this.maxValue = '-';
        return;
      }

      this.setProfitMax(value);
      this.maxValue = this.profit.maxValue;
    },
  },
};
</script>

<style scoped>
</style>
