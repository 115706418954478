<template>
  <div class="upload">
    <p class="description">
      Исключение компаний осуществляется по вашему excel-файлу, после его загрузки.
    </p>
    <div class="wrapper-upload">
      <input
        id="file"
        type="file"
        class="file"
        accept=".xlsx"
        @change="uploadFile"
      >
      <label
        for="file"
        class="upload-button"
      >
        Загрузить файл
      </label>
      <p
        v-if="getClientFileName"
        class="chosen-file"
      >
        Выбранный файл: {{ getClientFileName }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ChosenFileStep',

  computed: {
    ...mapGetters('exclusionFile', [
      'getClientFileName',
    ]),
  },

  methods: {
    ...mapActions('exclusionFile', [
      'uploadClientFile',
    ]),

    uploadFile(e) {
      const file = e.target.files[0];

      if (file === undefined) {
        return;
      }

      if (this.checkFileExtension(file)) {
        return window.echo(
          `Ожидаемый формат файла: xlsx, но загружен файл формата: ${this.getExtensionFile(file)}!`,
          5000,
          'warning',
        );
      }

      const fileData = new FormData();
      fileData.append('file', file);
      this.uploadClientFile(fileData);
    },

    checkFileExtension(file) {
      const ext = this.getExtensionFile(file);
      return ext !== 'xlsx';
    },

    getExtensionFile(file) {
      return file.name.split('.').pop().toLowerCase();
    },
  },
};
</script>

<style scoped>
  .upload {
    max-width: 600px;
    margin: 0 auto;
  }

  .description {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 30px;
    cursor: text;
  }

  .file {
    display: none;
  }
  .upload-button {
    cursor: pointer;
    padding: 5px 10px;
    border: 2px solid var(--color-button-prime-hover);
    border-radius: 6px;
    font-size: 16px;
    font-weight: bold;
    transition: .3s;
    flex-shrink: 0;
  }

  .upload-button:hover {
    background: var(--color-button-prime-hover);
    color: #fff;
  }

  .wrapper-upload {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .chosen-file {
    font-size: 15px;
    cursor: text;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 5px 0;
  }
</style>
