<template>
  <div class="exclusionFilter">
    <ExclusionFileControl />

    <div
      v-if="visibleResult"
      class="exclusionResult exclusionResultSummary"
    >
      <p>Загруженный файл: {{ getClientFileName }}</p>
      <p>Исключаем по списку {{ getFormattedChosenType }}</p>
      <p>В файле найдено {{ countParsedData }} {{ getFormattedChosenType }}</p>
      <p>Компаний исключено из выборки: {{ excludedTotalCount }}</p>
    </div>

    <NotificationComponent
      v-if="!fileIsUploaded"
      flipper-position="flipper"
      class="notificationBalloon exclusionResult"
    >
      Если вы хотите исключить из заказа некоторые компании,
      загрузите файл со списком ИНН или ОГРН для их исключения
    </NotificationComponent>

    <NotificationComponent
      v-if="(notFoundData && fileIsUploaded)"
      flipper-position="flipper"
      class="notificationBalloon exclusionResult"
    >
      Исключение не используется.
      Пожалуйста, завершите настройку
    </NotificationComponent>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ExclusionFileControl from './ExclusionFileControl.vue';
import NotificationComponent from '../../../common_components/NotificationComponent/NotificationComponent.vue';

export default {
  name: 'ExclusionFilter',

  components: {
    NotificationComponent,
    ExclusionFileControl,
  },

  computed: {
    ...mapState('exclusionFile', [
      'excludeCompanies',
      'countParsedData',
      'fileIsUploaded',
    ]),

    ...mapGetters('exclusionFile', [
      'getFormattedChosenType',
      'getClientFileName',
    ]),

    ...mapGetters('summary', [
      'excludedTotalCount',
    ]),

    visibleResult() {
      return this.countParsedData > 0;
    },

    notFoundData() {
      return this.countParsedData === 0;
    },
  },
};
</script>

<style scoped>
.exclusionFilter {
  z-index: 69;
  display: grid;
  row-gap: 20px;
}

.exclusionResult {
  line-height: 17px;
  font-size: 13px;
  position: relative;
  cursor: text;
  z-index: -1;
}

.exclusionResultSummary p {
  margin-bottom: 7px;
}

.notificationBalloon {
  margin-top: 10px!important;
  margin-bottom: 0!important;
}
</style>
