// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./img/arrow.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.additionalFiltersHeader[data-v-2c08b4d3] {\n  display: flex;\n  align-items: center;\n  justify-content: start;\n  cursor: pointer;\n  width: 35%;\n}\n.additionalFiltersBody[data-v-2c08b4d3] {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  padding: 0 25px;\n  margin-top: 20px;\n}\n.optionsConfigurator[data-v-2c08b4d3] {\n  display: grid;\n  grid-template-columns: 355px auto;\n  gap: 20px;\n}\n.additionalFilters[data-v-2c08b4d3] {\n  display: grid;\n  gap: 10px;\n}\n.checkbox[data-v-2c08b4d3] {\n  margin-right: 10px;\n}\n.additionalFilersTitle[data-v-2c08b4d3] {\n  position: relative;\n  font-weight: bold;\n  border-bottom: 1px dashed;\n  font-size: 15px;\n}\n.additional_filters:hover .additionalFilersTitle[data-v-2c08b4d3] {\n  border-bottom: transparent;\n}\n.additionalFilersTitle[data-v-2c08b4d3]::after {\n  content: '';\n  position: absolute;\n  right: -28px;\n  top: 50%;\n  transform: translateY(-50%);\n  display: inline-block;\n  width: 21px;\n  height: 21px;\n  background: url('/dist/css/images/arrow_for_index.png') center center no-repeat;\n  background-size: contain;\n  pointer-events: none;\n}\n.registrationConfigurator[data-v-2c08b4d3] {\n  display: flex;\n  justify-content: space-between;\n  grid-column: 1 / 2;\n}\n.registrationConfiguratorText[data-v-2c08b4d3] {\n  font-style: italic;\n  width: 50%;\n  display: flex;\n}\n.registrationConfiguratorText[data-v-2c08b4d3]::after {\n  content: '';\n  margin-right: 10px;\n  width: 40px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat top right;\n  background-size: auto 40px;\n}\n", ""]);
// Exports
module.exports = exports;
