<template>
  <div
    class="regionConfigurator"
    @click="openTreePopup"
  >
    <span class="regionConfiguratorTitle"><b>География выборки:</b></span>
    <ButtonSelector
      :content="getDescriptionForRegions"
      :class="{'selector-nothing-selected': !hasSelectedCities & itIsTimeToShow}"
    />

    <GeoTree v-if="visibleTree" />

  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import ButtonSelector from 'button-selector';
import GeoTree from '@/egrul/components/GeoTree/GeoTree.vue';

export default {
  name: 'RegionConfigurator',

  components: {
    ButtonSelector,
    GeoTree,
  },

  props: {
    itIsTimeToShow: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapState([
      'visibleTree',
    ]),

    ...mapGetters('tree', [
      'getDescriptionForRegions',
      'hasSelectedCities',
    ]),
  },

  methods: {
    ...mapMutations([
      'setVisibleTree',
    ]),

    openTreePopup() {
      this.setVisibleTree(true);
    },
  },
};
</script>

<style scoped src="../../../assets/css/element.css" />

<style scoped>
.regionConfigurator {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 5px;
  width: max-content;
}

.regionConfiguratorTitle {
  margin-right: 10px;
  font-size: 14px;
}
</style>
