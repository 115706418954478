import { Tree, NodeValue } from 'tree';
import plural from 'plural-ru';
import api from '../../api/index';

export default {
    namespaced: true,

    state: {
        /**
             * @var {Tree}
             */
        tree: {},

        searchString: '',

        /**
             * @var {Tree}
             */
        searchTree: {},

        tooltipVisible: false,

        geoUnits: [],
    },

    getters: {
        tooltipVisible(state) {
            return state.tooltipVisible;
        },

        getGeoTree: (state) => state.tree,
        getGeoSearchTree: (state) => state.searchTree,
        isSearchMode: (state) => (state.searchString.length >= 3),
        emptySearchResult: (state) => state.searchTree.isEmpty(),

        // Возвращает true, false. Если дерево полностью выбрано
        treeFullSelected(state) {
            if (!state.tree.root) { // Перед инициализацией дерева недоступно значение root
                return false;
            }
            return state.tree.root.value.isChosen && !state.tree.root.value.isPartiallyChosen;
        },

        // Есть ли выбранные города
        hasSelectedCities(state) {
            // когда дерево не прогрузилось возвращаем true, так как по умолчанию выбрана вся Россия
            if (!state.tree.root) {
                return true;
            }
            return state.tree.root.value.isPartiallyChosen || state.tree.root.value.isChosen;
        },

        getDescriptionForRegions(state, getters) {
            if (!state.tree.root) {
                return 'Города не выбраны';
            }

            const selectedCities = state.tree.getChosenNodesFromLevel(4);

            // Получаем все регионы (уровень 3)
            const allRegions = state.tree.root.children.flatMap(
                (country) => country.children.flatMap((territory) => territory.children),
            );

            const selectedRegions = allRegions.filter(
                (region) => region.value.isPartiallyChosen || region.value.isChosen
                  || region.children.some(
                      (city) => city.value.isChosen,
                  ),
            );

            if (getters.treeFullSelected) {
                return 'Россия';
            }
            if (selectedCities.length === 1) {
                return selectedCities[0].value.obj.name;
            }
            if (selectedRegions.length === 0) {
                return 'Города не выбраны';
            }
            return `${selectedRegions.length} ${plural(selectedRegions.length, 'регион', 'региона', 'регионов')}`;
        },
        getDescriptionForCitiesList(state, getters) {
            if (!state.tree.root) {
                return 'по Москве';
            }

            const selectedCities = state.tree.getChosenNodesFromLevel(4);
            // Получаем все регионы (уровень 3)
            const allRegions = state.tree.root.children.flatMap(
                (country) => country.children.flatMap((territory) => territory.children),
            );

            const selectedRegions = allRegions.filter(
                (region) => region.value.isPartiallyChosen || region.value.isChosen || region.children.some(
                    (city) => city.value.isChosen,
                ),
            );

            if (getters.treeFullSelected) {
                return 'по России';
            } if (selectedCities.length === 1) {
                return `по ${selectedCities[0].value.obj.name}`;
            } if (selectedRegions.length === 1) {
                return `по ${selectedRegions[0].value.obj.name}`;
            } if (selectedRegions.length === 0) {
                return ', Регионы не выбраны';
            }
            return `по ${selectedRegions.length} ${plural(selectedRegions.length, 'региону', 'регионам')}`;
        },

        // выбранные пункты, отформатированные для отправки запроса на сервер
        selectedGeoUnits(state) {
            if (!state.tree.root) {
                return { country: [], region: [], city: [] };
            }
            const chosenCountries = state.tree.getChosenNodesFromLevel(1).map((country) => country.value.obj.id);
            const chosenRegions = state.tree.getChosenNodesFromLevel(3).reduce((regions, region) => {
                if (!chosenCountries.includes(region.value.obj.id_country) && !region.value.obj.hide) {
                    regions.push(region.value.obj.id);
                }
                return regions;
            }, []);
            const chosenCities = state.tree.getChosenNodesFromLevel(4).reduce((cities, city) => {
                if (
                    !chosenRegions.includes(city.value.obj.region)
                    && !chosenCountries.includes(city.value.obj.id_country)
                    && !city.value.obj.hide
                ) {
                    cities.push(city.value.obj.id);
                }
                return cities;
            }, []);

            return {
                region: chosenRegions,
                city: chosenCities,
                country: chosenCountries,
            };
        },

        getMillionaireCities(state) {
            return state.geoUnits.cities.filter((city) => city.level === 3 && city.is_millionaire);
        },

        getRegionalCenters(state) {
            const idMoscow = 585;

            return state.geoUnits.cities.filter(
                (city) => city.extra === 1 || city.id === idMoscow,
            );
        },
    },

    mutations: {
        setGeoTree: (state, payload) => {
            let { territories } = payload;

            territories = new Set(territories.map((territory) => {
                territory.id = Number(territory.id);
                return territory;
            }));

            const tree = new Tree(4);

            let regions = payload.cities.filter((region) => region.level === 2);

            regions = regions.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });

            regions = regions.sort((a, b) => {
                if (a.name === 'Московская область' || a.name === 'Ленинградская область') {
                    return -1;
                }
                if (b.name === 'Московская область' || b.name === 'Ленинградская область') {
                    return 1;
                }
                return 0;
            });

            let cities = payload.cities.filter((city) => city.level === 3);
            cities = cities.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });

            cities = cities.sort((a, b) => {
                if (a.extra === 1) {
                    return -1;
                }
                if (b.extra === 1) {
                    return 1;
                }
                return 0;
            });

            // добавление 1-ого уровня - страна
            const nodeValue = new NodeValue(
                { id: 1, name: 'Россия' },
                1,
                true,
                false,
                false,
                true,
            );
            tree.addNodeByParentId(nodeValue, 0);

            territories.forEach((territory) => {
                const nodeValue = new NodeValue(
                    territory,
                    2,
                    // Разворачиваем только Центральный федеральный округ
                    territory.id === 1,
                    false,
                    false,
                    false,
                );
                tree.addNodeByParentId(nodeValue, 1);
            });

            regions.forEach((region) => {
                let nodeValue = new NodeValue(region, 3);

                if (region.id === 35) {
                    nodeValue = new NodeValue(region, 3, true);
                }

                tree.addNodeByParentId(nodeValue, region.id_territory);
            });

            cities.forEach((city) => {
                // для регионов без федерального округа города не добавляются
                const cityRegion = regions.find((region) => region.id === city.region);
                if (cityRegion && cityRegion.id_territory === 9) {
                    return;
                }
                if (city.name === 'Москва' || city.name === 'Санкт-Петербург' || city.name === 'Севастополь') {
                    city.additional = 1;
                    city.extra = 0;
                }
                const nodeValue = new NodeValue(city, 4);
                tree.addNodeByParentId(nodeValue, city.region);
            });

            state.tree = tree;
        },

        expandGeoNode: (state, nodeDesc) => {
            state.tree.expandNode(nodeDesc.level, nodeDesc.id);
        },
        chooseGeoNode: (state, nodeDesc) => {
            state.tree.chooseNode(nodeDesc.level, nodeDesc.id);
        },

        setSearchString(state, searchString) {
            state.searchString = searchString;
        },
        runSearch(state) {
            state.searchTree = state.tree.filterTreeByNodeName(state.searchString);
        },
        resetSearch(state) {
            state.searchString = '';
        },
        expandGeoSearchNode: (state, nodeDesc) => {
            state.searchTree.expandNode(nodeDesc.level, nodeDesc.id);
        },
        syncSearchTree: (state) => {
            state.searchTree.copyStateOfChoice(state.searchTree.root, state.tree);
        },

        chooseOrCancelTree: (state, choice) => {
            state.tree.selectOrDropAllNodes(choice);
        },

        chooseOrCancelSearchTree(state, choice) {
            state.searchTree.selectOrDropAllNodes(choice);
        },

        setTooltipVisible(state, value) {
            state.tooltipVisible = value;
        },

        setGeoUnits(state, value) {
            state.geoUnits = value;
        },
    },

    actions: {
        async setGeoTree({ commit }) {
            const geoUnits = await api.getCities();
            commit('setGeoUnits', geoUnits);
            commit('setGeoTree', geoUnits);
        },
        chooseGeoSearchNode: ({ commit }, nodeDesc) => {
            commit('chooseGeoNode', nodeDesc);
            commit('syncSearchTree');
        },
        updateSearch: ({ commit, getters }, searchString) => {
            commit('setSearchString', searchString);
            if (getters.isSearchMode) {
                commit('runSearch');
            }
        },

        chooseGeoNode({ commit }, nodeDesc) {
            commit('chooseGeoNode', nodeDesc);
            commit('runSearch');
        },

        async setMillionaireCities({ getters, commit }) {
            if (
                !confirm('Вы уверены, что хотите включить города-миллионники?'
                + ' Все остальные города будут исключены из выборки.')) {
                return;
            }

            await commit('chooseOrCancelTree', false);

            getters.getMillionaireCities.forEach((city) => {
                commit('chooseGeoNode', { level: 4, id: city.id });
            });
        },

        async setRegionalCenters({ getters, commit }) {
            if (!confirm('Вы уверены, что хотите включить региональные центры?'
              + ' Все остальные города будут исключены из выборки.')) {
                return;
            }

            await commit('chooseOrCancelTree', false);

            getters.getRegionalCenters.forEach((city) => {
                commit('chooseGeoNode', { level: 4, id: city.id });
            });
        },

        setGeoUnits({ commit }, { region, city, country }) {
            commit('chooseOrCancelTree', false);

            if (country.length !== 0) {
                return commit('chooseOrCancelTree', true);
            }

            region.forEach((id) => {
                commit('chooseGeoNode', { level: 3, id });
            });

            city.forEach((id) => {
                commit('chooseGeoNode', { level: 4, id });
            });
        },
    },
};
