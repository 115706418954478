import { createApp } from 'vue';
import initSentry from '@/vue_sentry';
import EgrulConfigurator from './components/EgrulConfigurator/EgrulConfigurator.vue';
import store from './store/store';

const el = document.getElementById('vue_egrul_configurator');
if (el) {
    const app = createApp(EgrulConfigurator);
    initSentry(app);
    app.use(store);
    window.vue_egrul_configurator = app.mount('#vue_egrul_configurator');
}
