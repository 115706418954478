<template>
  <div class="additionalFiltersWrapper">
    <div
      class="additionalFiltersHeader"
      title="Настройте доп. фильтры у вашей базы"
      @click="toggleExpandAdditionalFilters"
    >
      <Checkbox
        :chosen="additionalFiltersChosen"
        :partially-fill="false"
        :mode="['dark']"
        class="checkbox"
      />
      <span class="additionalFilersTitle">Дополнительные фильтры</span>
    </div>

    <div
      v-show="expandAdditionalFilters"
      class="additionalFiltersBody"
    >
      <div class="optionsConfigurator">
        <Checkbox
          :chosen="has_site_phone"
          :disabled="!phoneFilter.selected"
          :mode="getCheckBoxMode"
          html-title
          title="Телефоны только с сайтов компаний"
          @change="toggleHasSitePhone"
        />
      </div>

      <div class="optionsConfigurator">
        <Checkbox
          :chosen="has_messenger"
          :mode="['dark']"
          html-title
          title="Есть мессенджер"
          @change="toggleHasMessenger"
        />
      </div>

      <hr>

      <div class="registrationConfigurator">
        <div class="registrationConfiguratorText">
          Фильтр по годам и месяцам регистрации позволит
          максимально точно выбрать компании
        </div>
        <RegistrationDate
          class="regYearAndMonthSelector"
        />
      </div>

      <hr>

      <div class="optionsConfigurator">
        <div class="additionalFilters">
          <EmployeeFilter
            :disabled="additionalFiltersDisabled"
            @click="onClick"
          />
          <IncomeFilter
            :disabled="additionalFiltersDisabled"
            @click="onClick"
          />
          <ProfitFilter
            :disabled="additionalFiltersDisabled"
            @click="onClick"
          />
        </div>

        <AdditionalComment />
      </div>

      <hr>

      <div class="optionsConfigurator">
        <Checkbox
          :chosen="show_inc_and_profit_for_last_years"
          :mode="['dark']"
          html-title
          title="Показать <span style='font-weight: 700'>выручку/прибыль</span> за последние 4 года"
          @change="toggleIncAndProfitForLastYears"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from 'checkbox';
import {
  mapGetters, mapMutations, mapState, mapActions,
} from 'vuex';
import EmployeeFilter from '@/egrul/components/AdditionalFilters/EmployeeFilter/EmployeeFilter.vue';
import IncomeFilter from '@/egrul/components/AdditionalFilters/IncomeFilter/IncomeFilter.vue';
import ProfitFilter from '@/egrul/components/AdditionalFilters/ProfitFilter/ProfitFilter.vue';
import RegistrationDate from '@/egrul/components/RegistrationDate/RegistrationDate.vue';
import AdditionalComment from '@/egrul/components/AdditionalFilters/Comment/AdditionalComment.vue';

export default {
  name: 'AdditionalFilters',
  components: {
    AdditionalComment,
    RegistrationDate,
    ProfitFilter,
    IncomeFilter,
    EmployeeFilter,
    Checkbox,
  },

  computed: {
    ...mapState([
      'phoneFilter',
      'has_site_phone',
      'has_messenger',
      'additionalFiltersDisabled',
      'expandAdditionalFilters',
      'show_inc_and_profit_for_last_years',
    ]),

    ...mapGetters([
      'additionalFiltersChosen',
    ]),

    getCheckBoxMode() {
      return this.phoneFilter.selected ? ['dark'] : ['dark', 'disabled'];
    },
  },

  methods: {
    ...mapMutations([
      'toggleExpandAdditionalFilters',
    ]),

    ...mapActions([
      'toggleIncAndProfitForLastYears',
      'toggleHasSitePhone',
      'toggleHasMessenger',
    ]),

    onClick() {
      if (this.employeesAndIncomeDisabled) {
        window.echo('Фильтры численности и выручки недоступны,'
            + ' если выбран размер компании по МСП (микро, малые, средние)');
      }
    },
  },
};
</script>

<style scoped>
.additionalFiltersHeader {
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  width: 35%;
}

.additionalFiltersBody {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 25px;
  margin-top: 20px;
}

.optionsConfigurator {
  display: grid;
  grid-template-columns: 355px auto;
  gap: 20px;
}

.additionalFilters {
  display: grid;
  gap: 10px;
}

.checkbox {
  margin-right: 10px;
}

.additionalFilersTitle {
  position: relative;
  font-weight: bold;
  border-bottom: 1px dashed;
  font-size: 15px;
}

.additional_filters:hover .additionalFilersTitle {
  border-bottom: transparent;
}

.additionalFilersTitle::after {
  content: '';
  position: absolute;
  right: -28px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  width: 21px;
  height: 21px;
  background: url('/dist/css/images/arrow_for_index.png') center center no-repeat;
  background-size: contain;
  pointer-events: none;
}

.registrationConfigurator {
  display: flex;
  justify-content: space-between;
  grid-column: 1 / 2;
}

.registrationConfiguratorText {
  font-style: italic;
  width: 50%;
  display: flex;
}

.registrationConfiguratorText::after {
  content: '';
  margin-right: 10px;
  width: 40px;
  background: url('./img/arrow.png') no-repeat top right;
  background-size: auto 40px;
}
</style>
