function sanitizeEmployees(val) {
    val = val.replace(/[^0-9.]/g, '');
    val = parseInt(val);

    if (isNaN(val)) {
        val = 0;
    }

    return val;
}

function sanitizeEarnings(val) {
    return val.replace(/[^0-9.,]/g, '');
}

function parseIncome(income) {
    income = income.replace(/,/, '.');

    if (isNaN(income)) {
        return 0;
    }

    const limiterIndex = income.indexOf('.');

    if (income.slice(limiterIndex).length > 3) {
        return income.slice(0, limiterIndex + 3);
    }

    return Number(income);
}

function sanitizeMinProfit(val) {
    const firstChar = val.charAt(0);
    return firstChar + val.slice(1).replace(/[^\d.,]/g, '');
}

function parseProfit(profit) {
    profit = profit.replace(/,/, '.');

    if (Number.isNaN(profit) || profit === '') {
        return 0;
    }

    profit = parseFloat(profit);

    if (Math.abs(profit) > 1000000) {
        return parseFloat(profit.toString().slice(0, -1));
    }

    return profit;
}

export { sanitizeEmployees, sanitizeEarnings, sanitizeMinProfit, parseIncome, parseProfit };
