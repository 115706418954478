<template>
  <div>
    <div class="selectorBorderVertical " />
    <div class="selectorBorderVertical" />
    <div class="selectorHead">
      <div class="selectorHeadOptionTitle">
        &nbsp;
      </div>
      <div class="inclusionOptions">
        <div class="inclusionOptionsItem selectorCell">
          <div class="inclusionOptionTitle">
            Включено
          </div>
          <div class="inclusionOptionSubtitle">
            в заказ
          </div>
        </div>
        <div class="inclusionOptionsItem selectorCell">
          <div class="inclusionOptionTitle">
            Исключено
          </div>
          <div class="inclusionOptionSubtitle">
            из заказа
          </div>
        </div>
      </div>
    </div>

    <section class="selectorOptionsSection">
      <div class="selectorOptionsSectionTitle">
        Размер компании по МСП
      </div>

      <IncludeExcludeSelector
        selector-label="Все компании"
        is-all-types-selector
        :all-types-selected="allMspTypesSelected"
        @allTypes="toggleAllMspTypeSelected"
      />

      <IncludeExcludeSelector
        v-for="mspType in mspTypes"
        :selector-label="mspType.title"
        :option-obj="mspType"
        @inclusion="toggleIncludeMspType(mspType.id)"
        @exclusion="toggleExcludeMspType(mspType.id)"
      />
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import IncludeExcludeSelector from '@/common_components/IncludeExcludeSelector/IncludeExcludeSelector.vue';

export default {
  name: 'CompanySizeSelector',
  components: {
    IncludeExcludeSelector,
  },

  computed: {
    allMspTypesSelected() {
      return !Object.keys(this.mspTypes)
        .some((id) => this.mspTypes[id].included
              || this.mspTypes[id].excluded);
    },

    ...mapState([
      'mspTypes',
    ]),
  },

  methods: {
    ...mapActions([
      'toggleAllMspTypeSelected',
      'toggleIncludeMspType',
      'toggleExcludeMspType',
    ]),
  },
};
</script>

<style scoped src="../EgrulConfigurator/EgrulConfigurator.css" />

<style scoped src="../../../common_components/IncludeExcludeSelector/IncludeExcludeSelector.css" />
