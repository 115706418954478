<template>
  <div class="wrapper-groups">
    <template
      v-for="companyTypeGroup in companyTypeGroups"
    >
      <div
        v-if="companyTypeGroup.show"
        :key="companyTypeGroup.id"
        class="company-type-group"
      >
        <div
          :class="{'company-type-row_active': companyTypeGroup.active}"
          class="company-type-row"
          @click="toggleVisibleCompanyTypesByGroupId(companyTypeGroup.id)"
        >
          {{ companyTypeGroup.title }}
          <span
            v-if="getCompanyTypeGroupIncludedCount(companyTypeGroup.id)"
            class="company-group_included_count"
          >
            Вкл: {{ getCompanyTypeGroupIncludedCount(companyTypeGroup.id) }}
          </span>
          <span
            v-if="getCompanyTypeGroupIncludedCount(companyTypeGroup.id)
              && getCompanyTypeGroupExcludedCount(companyTypeGroup.id)"
          >
            |
          </span>
          <span
            v-if="getCompanyTypeGroupExcludedCount(companyTypeGroup.id)"
            class="company-group_excluded_count"
          >
            Искл: {{ getCompanyTypeGroupExcludedCount(companyTypeGroup.id) }}
          </span>
          <img
            alt="arrow"
            src="/images/arrow_right_black.svg"
            class="company-type-row__arrow"
            width="16"
            height="16"
          >
        </div>
        <IncludeExcludeSelector
          v-for="(companyType) in searchCompanyTypesInGroup(companyTypeGroup.id)"
          mode="egrulCompanyTypeMode"
          :is-licenses-option="Number(companyType.id) === 1"
          :selector-label="companyType.title"
          :selector-label-accusative="companyType.title_accusative"
          :option-obj="companyType"
          @inclusion="toggleIncludeCompanyType(companyType.id)"
          @exclusion="toggleExcludeCompanyType(companyType.id)"
        />
      </div>
    </template>
  </div>
</template>

<script>
import {
  mapActions, mapState,
} from 'vuex';
import debounce from 'lodash.debounce';
import Fuse from 'fuse.js';
import IncludeExcludeSelector from '@/common_components/IncludeExcludeSelector/IncludeExcludeSelector.vue';

export default {
  name: 'CompanyTypeGroups',
  components: {
    IncludeExcludeSelector,
  },

  computed: {
    ...mapState([
      'companyTypeGroups',
      'companyTypes',
      'searchCompanyType',
      'fuse',
      'fuseOptions',
    ]),

    sortedCompanyTypes() {
      const companyTypes = Object.values(this.companyTypes);
      return companyTypes.sort((a, b) => {
        if (a.sortIndex < b.sortIndex) {
          return -1;
        }
        if (a.sortIndex > b.sortIndex) {
          return 1;
        }
        return 0;
      });
    },
  },

  watch: {
    searchCompanyType: {
      handler: debounce(function () {
        if (this.searchCompanyType === '') {
          this.resetSearch();
        } else {
          this.search();
        }
      }, 300),
    },
  },

  methods: {
    ...mapActions([
      'toggleIncludeCompanyType',
      'toggleExcludeCompanyType',
      'toggleVisibleCompanyTypesByGroupId',
      'search',
      'resetSearch',
    ]),

    getVisibleCompanyTypesByGroupId(groupId) {
      return this.sortedCompanyTypes
        .filter((companyType) => companyType.groupId === groupId && companyType.visible);
    },

    getCompanyTypesByGroupId(groupId) {
      return this.sortedCompanyTypes
        .filter((companyType) => companyType.groupId === groupId);
    },

    searchCompanyTypes(companyTypes) {
      if (this.searchCompanyType === '') {
        return companyTypes;
      }

      return companyTypes.filter((companyType) => {
        const fuse = new Fuse([companyType], this.fuseOptions);
        return fuse.search(this.searchCompanyType).length > 0;
      });
    },

    searchCompanyTypesInGroup(groupId) {
      const companyTypes = this.getVisibleCompanyTypesByGroupId(groupId);
      return this.searchCompanyTypes(companyTypes);
    },

    getCompanyTypeGroupIncludedCount(groupId) {
      const companyTypes = this.getCompanyTypesByGroupId(groupId);
      return companyTypes.filter((companyType) => companyType.included === true).length;
    },

    getCompanyTypeGroupExcludedCount(groupId) {
      const companyTypes = this.getCompanyTypesByGroupId(groupId);
      return companyTypes.filter((companyType) => companyType.excluded === true).length;
    },
  },
};
</script>

<style scoped>
  .company-type-row {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    width: 516px;
    font-weight: bold;
  }

  .company-type-row__arrow {
    max-width: 15px;
    transform: rotate(90deg);
    transition: .3s;
  }

  .company-type-row_active .company-type-row__arrow {
    transform: rotate(270deg);
  }

  .company-group_included_count {
    color: var(--color-green-prime);
  }

  .company-group_excluded_count {
    color: var(--color-orange);
  }
</style>
