import api from '../../api/index';
import EgrulConfiguratorUtil from '../utils/EgrulConfiguratorUtil';

export default {
    namespaced: true,

    state: {
        cost: 0,
        count: 0,
        excludedCount: 0,
        visibleDiscount: 0,
        relativeDiscountValue: 0,

        selectedOkved: [],

        egrulPrice: {
            base: 0,

            ogrn: 0,
            kpp: 0,
            okpo: 0,

            phone: {
                1: 0,
                2: 0,
                3: 0,
            },

            address: 0,

            post_index: 0,

            multiplier_email_verified: 0,

            site: 0,

            okved_contact_person: 0,

            show_company_size: 0,

            registration_date: 0,

            multiplier_msp: {
                1: 0,
                2: 0,
                3: 0,
            },

            company_type: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
            },

            years: 0,
            months: 0,

            employees: 0,
            income: 0,
        },

        // стоимость одной компании согласно включенным фильтрам
        costOfOneFirm: 0,

        // стоимость одной компании после применения скидочной политики
        costOfOneFirmFinal: 0,

        selectedConfiguratorFields: {
            basic: ['phone'],
            phone_type: [],
            company_type: [],
            years: false,
            months: false,
            employees: false,
            income: false,
            msp_type: [],
            email_verified: false,
        },
    },

    getters: {
        totalCost(state) {
            return state.cost;
        },

        totalCount(state) {
            return state.count;
        },

        excludedTotalCount(state) {
            return state.excludedCount;
        },

        getOneCompanyCost(state) {
            if (state.costOfOneFirmFinal > 0) {
                return state.costOfOneFirmFinal;
            }
            return state.costOfOneFirm;
        },
    },

    mutations: {
        setEgrulPrice(state, price) {
            state.egrulPrice = price;
        },

        setCost(state, value) {
            state.cost = value;
        },

        setExcludedCount(state, value) {
            state.excludedCount = value;
        },

        setOneCompanyCostFinal(state, value) {
            state.costOfOneFirmFinal = value;
        },

        setCount(state, value) {
            state.count = value;
        },

        setRelativeDiscountValue(state, value) {
            state.relativeDiscountValue = value;
        },

        setVisibleDiscount(state, value) {
            state.visibleDiscount = Math.round(value);
        },

        setSelectedConfiguratorFieldsBasicFilter(state, value) {
            state.selectedConfiguratorFields.basic = value;
        },

        setSelectedPhoneType(state, value) {
            state.selectedConfiguratorFields.phone_type = value;
        },

        setSelectedConfiguratorFieldsCompanyType(state, value) {
            state.selectedConfiguratorFields.company_type = value;
        },

        setYearsSelected(state, value) {
            state.selectedConfiguratorFields.years = value;
        },

        setMonthsSelected(state, value) {
            state.selectedConfiguratorFields.months = value;
        },

        setEmployeesSelected(state, value) {
            state.selectedConfiguratorFields.employees = value;
        },

        setIncomeSelected(state, value) {
            state.selectedConfiguratorFields.income = value;
        },

        setSelectedConfiguratorFieldsMspType(state, value) {
            state.selectedConfiguratorFields.msp_type = value;
        },

        setIsEmailVerifiedSelected(state, value) {
            state.selectedConfiguratorFields.email_verified = value;
        },

        updateOneCompanyCost(state) {
            state.costOfOneFirm = state.egrulPrice.base;

            const basicFiltersCount = state.selectedConfiguratorFields.basic.length;
            for (let i = 0; i < basicFiltersCount; i++) {
                if (state.selectedConfiguratorFields.basic[i] !== 'phone') {
                    state.costOfOneFirm += state.egrulPrice[state.selectedConfiguratorFields.basic[i]];
                } else {
                    const phoneTypeFiltersCount = state.selectedConfiguratorFields.phone_type.length;
                    for (let j = 0; j < phoneTypeFiltersCount; j++) {
                        state.costOfOneFirm += state.egrulPrice.phone[state.selectedConfiguratorFields.phone_type[j]];
                    }
                }
            }

            const companyTypeFiltersCount = state.selectedConfiguratorFields.company_type.length;
            for (let i = 0; i < companyTypeFiltersCount; i++) {
                state.costOfOneFirm += state.egrulPrice.company_type[state.selectedConfiguratorFields.company_type[i]];
            }

            if (state.selectedConfiguratorFields.years) {
                state.costOfOneFirm += state.egrulPrice.years;
            }

            if (state.selectedConfiguratorFields.months) {
                state.costOfOneFirm += state.egrulPrice.months;
            }

            if (state.selectedConfiguratorFields.employees) {
                state.costOfOneFirm += state.egrulPrice.employees;
            }

            if (state.selectedConfiguratorFields.income) {
                state.costOfOneFirm += state.egrulPrice.income;
            }

            state.costOfOneFirm *= EgrulConfiguratorUtil.getCostMultiplier(
                state.selectedConfiguratorFields,
                state.egrulPrice,
            );
        },
    },

    actions: {
        setPrice(context) {
            api.getPrice()
                .then((price) => {
                    context.commit('setEgrulPrice', price);
                })
                .then(() => {
                    context.dispatch('setSelectedConfiguratorFields');
                });
        },

        setSelectedConfiguratorFields(context) {
            context.commit('setSelectedConfiguratorFieldsBasicFilter', context.rootGetters.selectedBasicFilters);
            context.commit('setSelectedPhoneType', context.rootGetters.selectedPhoneTypes);
            context.commit('setSelectedConfiguratorFieldsCompanyType', context.rootGetters.selectedCompanyType);
            context.commit('setYearsSelected', context.rootGetters.selectedYear.length > 0);
            context.commit('setMonthsSelected', context.rootGetters.selectedMonth.length > 0);
            context.commit('setEmployeesSelected', context.rootGetters.employees.chosen);
            context.commit('setIncomeSelected', context.rootGetters.incomeForCalculationRequest.chosen);
            context.commit('setSelectedConfiguratorFieldsMspType', context.rootGetters.selectedMspType);
            context.commit('setIsEmailVerifiedSelected', context.rootGetters.isVerifiedEmail);

            context.commit('updateOneCompanyCost');
        },

        updatePageInfo({ commit }, data) {
            commit('setCost', data.total_cost);
            commit('setCount', data.total_count);
            commit('setExcludedCount', data.excluded_count);
            commit(
                'setVisibleDiscount',
                100 * data.visible_discount_coefficient,
            );
            commit('setRelativeDiscountValue', data.relative_discount_value);
            commit(
                'setOneCompanyCostFinal',
                Math.ceil(data.total_cost * 100 / data.total_count),
            );
        },

        resetPageInfo({ commit }) {
            commit('setCost', 0);
            commit('setCount', 0);
            commit('setExcludedCount', 0);
            commit('setVisibleDiscount', 0);
            commit('setRelativeDiscountValue', 0);
            commit('setOneCompanyCostFinal', 0);
        },
    },
};
