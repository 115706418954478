<template>
  <div class="more_fields_tooltip">
    <div class="more_fields_tooltip_title">
      <span>
        {{ moreFieldsText }}
      </span>
    </div>
    <ul class="more_fields_tooltip_ul">
      <li
        v-for="field in fields"
        :key="field"
      >
        {{ field }}
      </li>
    </ul>
  </div>
</template>

<script>
import plural from 'plural-ru';

export default {
  name: 'MoreFieldsEnumTooltip',
  data() {
    return {
      fields: [
        'Город',
        'ОКОПФ',
        'Регион',
        'Статус компании',
        'Тип компании',
        'Часовой пояс',
      ],
    };
  },

  computed: {
    moreFieldsText() {
      return `и еще ${this.fields.length} ${plural(this.fields.length, 'поле', 'поля', 'полей')}`;
    },
  },
};
</script>

<style scoped>
.more_fields_tooltip {
  cursor: pointer;
}

.more_fields_tooltip_title {
  padding-left: 12px;
  padding-top: 4px;
}

.more_fields_tooltip_title span {
  color: rgba(0, 0, 0, 0.7);
  border-bottom: 1px dashed;
}

.more_fields_tooltip_ul {
  display: none;
  position: absolute;
}

.more_fields_tooltip_title:hover + .more_fields_tooltip_ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  z-index: 100;
  border: 1px solid #999;
  border-radius: 4px;
  color: #353535;
  background: var(--notification-color);
  font-size: 13px;
  line-height: 1.2;
  width: 180px;
  padding: 10px;
  transform: translateY(8%);
}

.more_fields_tooltip_ul li {
  padding-left: 16px;
  background: url("/dist/css/images/tick_small.png") no-repeat left center;
}
</style>
