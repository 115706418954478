<template>
  <TwoBlocksPopup @close-popup="closePopup">
    <template #popup-left-block>
      <p class="okved-tree__title">
        Выберите ОКВЭД:
      </p>
      <div class="wrapper-filter">
        <ToggleSwitch
          :id="includeAdditionalOkved.name"
          :title="includeAdditionalOkved.title"
          :chosen="!includeAdditionalOkved.chosen"
          :mode="['exportbase']"
          class="additional_okved_toggle_switch"
          @set-chosen-item="toggleIncludeAdditionalOkved"
        />
        <div class="filter_description">
          {{ additionalOkvedOptionDescriptionText }}
        </div>
        <div class="option-example">
          <span
            class="option-example__text"
            @click="showMoreCompaniesInfo = true"
          >
            Показать пример
          </span>
          <MoreCompaniesInfoPopup
            v-if="showMoreCompaniesInfo"
            main-activity-type-okved
            @hide-more-companies-info-popup="showMoreCompaniesInfo = false"
          />
        </div>
      </div>
      <div class="okved-tree-search">
        <SearchBar
          v-model="searchStringModel"
          class="searchBar"
          width="100%"
          placeholder="Поиск по кодам ОКВЭД"
          @reset="resetSearch"
        />
      </div>
      <TreeComponent
        v-if="!isSearchMode"
        :node="getTree.getRoot()"
        with-important-nodes
        :ul-node-mode="{'tree-ul-pa-left-20': 1}"
        :chosen-node-mode="['mark']"
        class="okved-tree-component scrollable-Y"
        @expand-node="expandOkvedNode"
        @choose-node="chooseNode"
      />
      <div
        v-if="isSearchMode"
        class="search-tree scrollable-Y"
      >
        <span
          v-if="emptySearchResult"
          class="emptySearch"
        >Ничего не найдено <br>
          Менеджер поможет с подбором базы!<br>
          Ответим на любой вопрос в чате на сайте, по Email <a
            class="okved-tree__link"
            href="mailto:info@export-base.ru"
          >info@export-base.</a> или по телефону <a
            class="okved-tree__link"
            href="tel:+78007752912"
            style="white-space: nowrap;"
          >8 800 775-29-12</a>
        </span>
        <TreeComponent
          v-else
          :node="getSearchTree.getRoot()"
          :search-text="searchStringModel"
          :checkbox-node-mode="{'node-important': 2}"
          :ul-node-mode="{'tree-ul-pa-left-20': 1}"
          class="okved-tree-component"
          @expand-node="expandOkvedSearchNode"
          @choose-node="chooseSearchNode"
        />
      </div>
    </template>
    <template #popup-right-block>
      <div>
        <p class="okved-tree-description__title">
          Как провести конфигурацию ОКВЭД?
        </p>
        <p class="okved-tree-description__text">
          1. Выберите один или несколько ОКВЭД для начала конфигурации.
        </p>
        <p class="okved-tree-description__text">
          2. Вы можете воспользоваться поиском, чтобы найти нужный ОКВЭД.
        </p>
        <div class="okved-tree-configuration">
          <p
            :class="{'calculating': isCalculating}"
            class="okved-tree-configuration__count"
          >
            Кол-во компаний:
            <b class="okved-tree-configuration__count-value">
              <span class="okved-tree-configuration__count-value-value">
                {{ count.toLocaleString('ru') }}
              </span>
              шт.
            </b>
          </p>
          <p
            :class="{'calculating': isCalculating}"
            class="okved-tree-configuration__cost"
          >
            Цена:
            <b class="okved-tree-configuration__cost-value">
              <span class="okved-tree-configuration__cost-value-value">
                {{ cost.toLocaleString('ru') }}
              </span>
              руб.
            </b>
          </p>
        </div>
        <div class="search-block__wrapper-btns">
          <div
            class="eb-button-prime small-button"
            :class="{'disabled' : getSelectedOkveds.length === 0}"
            @click="chooseOkveds"
          >
            Выбрать
          </div>
          <div
            class="eb-button-secondary small-button"
            @click="resetTree"
          >
            Сброс
          </div>
        </div>
      </div>
    </template>
  </TwoBlocksPopup>
</template>

<script>
import SearchBar from 'searchbar';
import { TreeComponent } from 'tree';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import ToggleSwitch from 'toggle-switch';
import MoreCompaniesInfoPopup from '@/common_components/MoreCompaniesInfoPopup/MoreCompaniesInfoPopup.vue';
import TwoBlocksPopup from '../../../common_components/TwoBlocksPopup/TwoBlocksPopup.vue';

export default {
  name: 'EgrulOkvedTree',
  components: {
    MoreCompaniesInfoPopup,
    TwoBlocksPopup,
    TreeComponent,
    SearchBar,
    ToggleSwitch,
  },

  data() {
    return {
      showMoreCompaniesInfo: false,
    };
  },

  computed: {
    ...mapState('summary', [
      'count',
      'cost',
    ]),

    ...mapState([
      'isCalculating',
    ]),

    ...mapState('okvedTree', [
      'searchString',
      'includeAdditionalOkved',
    ]),

    ...mapGetters('okvedTree', [
      'getTree',
      'isSearchMode',
      'emptySearchResult',
      'getSearchTree',
      'getSelectedOkveds',
      'nodeByOkvedCodeIsFound',
    ]),

    searchStringModel: {
      get() {
        return this.searchString;
      },

      set(value) {
        this.updateSearch(value);
      },
    },

    additionalOkvedOptionDescriptionText() {
      if (!this.includeAdditionalOkved.chosen) {
        return 'Поиск происходит только по основному (главному) ОКВЭД';
      }
      return 'Поиск происходит по любому (основному и дополнительным) ОКВЭД';
    },
  },

  watch: {
    getSelectedOkveds: {
      handler() {
        this.calculateConfiguration();
      },

      deep: true,
    },

    includeAdditionalOkved: {
      handler() {
        this.calculateConfiguration();
      },

      deep: true,
    },
  },

  mounted() {
    if (this.nodeByOkvedCodeIsFound) {
      const el = document.querySelectorAll(
        '.okved-tree-component .checkbox-button-label.checkbox-button-label_checked',
      )[1];

      el.scrollIntoView({
        block: 'center', behavior: 'smooth',
      });

      this.setNodeByParsedOkvedCodeIsFound(false);
    }
  },

  methods: {
    ...mapMutations('okvedTree', [
      'resetSearch',
      'expandOkvedNode',
      'expandOkvedSearchNode',
      'chooseOrCancelTree',
      'chooseOrCancelSearchTree',
      'setVisibleTree',
      'setNodeByParsedOkvedCodeIsFound',
    ]),

    ...mapActions('okvedTree', [
      'updateSearch',
      'chooseOkvedNode',
      'chooseOkvedsSearchNode',
      'toggleIncludeAdditionalOkved',
    ]),

    ...mapActions([
      'calculateConfiguration',
    ]),

    chooseNode(node) {
      this.chooseOkvedNode(node);
    },

    chooseSearchNode(node) {
      this.chooseOkvedsSearchNode(node);
    },

    resetTree() {
      this.chooseOrCancelTree(false);
      this.chooseOrCancelSearchTree(false);
    },

    chooseOkveds() {
      if (this.getSelectedOkveds.length !== 0) {
        this.closePopup();
      }
    },

    closePopup() {
      this.resetSearch();
      this.setVisibleTree(false);
    },
  },
};
</script>

<style scoped>
.okved-tree__title {
  margin-bottom: 5px;
  font-size: 24px;
  font-weight: 700;
}

.okved-tree__link {
  color: #60b6ea;
  text-decoration: none;
}

.okved-tree__link:hover {
  text-decoration: underline;
}

.okved-tree-search {
  display: flex;
  flex-direction: column;
  width: 97%;
  gap: 10px;
}

.okved-tree-component, .search-tree {
  min-height: 50px;
  height: 100%;
  padding-bottom: 20px;
}

.okved-tree-description__title {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 12px;
  margin-top: 80px;
}

.okved-tree-description__text {
  margin-bottom: 12px;
}

.okved-tree-configuration {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.okved-tree-configuration__count,
.okved-tree-configuration__cost {
  line-height: 16px;
}

.okved-tree-configuration__count::after,
.okved-tree-configuration__cost::after {
  display: none;
  content: '';
  position: absolute;
  bottom: 2px;
  right: -20px;
  width: 16px;
  height: 16px;
  background: url("../../../assets/images/small_preloader.gif");
}

.okved-tree-configuration__count.calculating .okved-tree-configuration__count-value,
.okved-tree-configuration__cost.calculating .okved-tree-configuration__cost-value {
  display: none;
}

.okved-tree-configuration__count-value-value,
.okved-tree-configuration__cost-value-value {
  color: var(--color-green-prime);
}

.okved-tree-configuration__count.calculating::after,
.okved-tree-configuration__cost.calculating::after {
  display: inline-block;
}

.search-block__wrapper-btns {
  margin: 30px 0;
  display: flex;
  gap: 10px;
}

.emptySearch {
  line-height: 1.5;
}

.wrapper-filter{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
}
.option-example__text {
  cursor: pointer;
  color: var(--color-green-prime, #839F22);
}
.tooltip {
  position: absolute;
  background: white;
  color: black;
  bottom: -50px;
  left: -5px;
  border: 1px solid black;
  padding: 10px;
  box-shadow: rgb(0 0 0 / 35%) 0 5px 15px;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  z-index: 1;
  width: 440px;
}

.additional_okved_toggle_switch {
  border: 1px solid var(--color-green-prime, #839F22);
  align-self: baseline;
  border-radius: 5px;
  padding: 5px;
}

.tooltip_text p {
  margin-bottom: 5px;
}
</style>
