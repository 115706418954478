<template>
  <div class="orderSettingsInfo">
    <span
      v-if="!hasSelectedCities"
      class="emptyCities"
      @click="openCityTree"
    >
      Города не выбраны,
      <span class="_dotted">
        откройте список
      </span>
      и выберите
    </span>

    <span
      v-if="okvedNotSelected && hasSelectedCities"
      class="emptyOkveds"
    >
      ОКВЭД не выбраны. Выберите
      <span
        class="_dotted"
        @click="openOkvedTree"
      >
        нужные ОКВЭД
      </span>
    </span>

    <div
      v-if="hasSelectedCities && !okvedNotSelected"
      class="orderInfo"
    >
      [
      <span @click="openOkvedTree">{{ getCountOkveds }} ОКВЭД</span>
      <span @click="openCityTree">{{ ` ${getDescriptionForCitiesList}` }}</span>
      ]
    </div>

    <span
      v-if="allOkvedsSelected && hasSelectedCities"
      class="orderAllOkveds"
    >
      Выбраны все сферы деятельности
    </span>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'OrderSettingsInfo',

  computed: {
    ...mapGetters('tree', [
      'hasSelectedCities',
      'getDescriptionForCitiesList',
    ]),

    ...mapGetters('okvedTree', [
      'allOkvedsSelected',
      'getSelectedOkveds',
    ]),

    okvedNotSelected() {
      return this.getSelectedOkveds.length === 0;
    },

    getCountOkveds() {
      return this.getSelectedOkveds.length;
    },
  },

  methods: {
    ...mapMutations([
      'setVisibleTree',
    ]),

    ...mapMutations('okvedTree', {
      setVisibleOkvedTree: 'setVisibleTree',
    }),

    openCityTree() {
      this.setVisibleTree(true);
    },

    openOkvedTree() {
      this.setVisibleOkvedTree(true);
    },
  },
};
</script>

<style scoped>
.orderSettingsInfo {
  font-size: 16px;
  font-weight: bold;
}

.orderInfo {
  font-weight: normal;
  font-size: 14px;
}

._dotted {
  border-bottom: 1px dashed;
  cursor: pointer;
}
</style>
