const initConfigProps = {
    initConfig: {
        type: Object,
        required: false,
        default: () => ({
            initMainConfig: {
                initTypesConfig: {
                    companyStatuses: {
                        statuses: [
                            { id: 1, included: true }, // active
                            { id: 2, included: false }, // closed
                            { id: 3, included: false }, // reorganization
                            { id: 4, included: false }, // bankrupt
                        ],
                    },
                    companySizes: {
                        // пустой массив => выбрано всё
                        sizes: [
                            // { id: 1, included: true, excluded: false }, // микро
                            // { id: 2, included: true, excluded: false }, // малые
                            // { id: 3, included: true, excluded: false }, // средние
                        ],
                    },
                    companyTypes: {
                        types: [
                            /* {
                                id: 30,
                                id_group: 5,
                                included: true,
                                excluded: false,
                            },
                            {
                                id: 5,
                                id_group: 5,
                                included: false,
                                excluded: false,
                            },
                            {
                                id: 6,
                                id_group: 5,
                                included: true,
                                excluded: false,
                            },
                            {
                                id: 29,
                                id_group: 5,
                                included: true,
                                excluded: false,
                            },
                            {
                                id: 22,
                                id_group: 8,
                                included: false,
                                excluded: true,
                            }, */
                        ],
                    },
                    okopfs: {
                        // коды окопф
                        // массив объектов с полями code и level
                        // пр. { code: '20200', level: 4 }
                        codes: [],
                    },
                    okveds: {
                        // коды оквед через запятую
                        // если пусто => по умолчанию ничего не выбрано
                        codes: [],
                    },
                    geoUnits: {
                        // id страны. Если country не пусто => пустые regions и cities
                        country: [],
                        // id регионов и городов, через запятую
                        // если выбран регион, не могут быть выбраны города в нём
                        regions: [],
                        cities: [],
                    },
                },

                initFiltersConfig: {
                    ogrn: { selected: false, isPartiallySelected: false },
                    phone: {
                        selected: true,
                        isPartiallySelected: false,
                        subFilters: [
                            { key: '1', selected: true }, // confirmed
                            { key: '2', selected: true }, // mobile
                            { key: '3', selected: true }, // affiliated
                        ],
                    },
                    address: { selected: false },
                    email: {
                        selected: false,
                        isPartiallySelected: false,
                        subFilters: [
                            { key: 'emailVerified', selected: true },
                        ],
                    },
                    site: { selected: false, isPartiallySelected: true },
                    decisionMaker: { selected: false, isPartiallySelected: false },
                    showCompanySize: { selected: false, isPartiallySelected: false },
                    registrationDate: { selected: false, isPartiallySelected: false },
                    hasMessenger: { selected: false, isPartiallySelected: false },
                },
            },

            initAddConfig: {
                // sitePhone: { selected: true },
                // employees: { selected: true, employeesMin: '0', employeesMax: '150' },
                // income: { selected: true, incomeMin: '0', incomeMax: '350' },
                // profit: { selected: true, profitMin: '0', profitMax: '450' },
                // lastYearsIncAndProfit: { selected: true },
                // regMonths: { selected: true, months: [] },
                // regYears: { selected: true, years: [2021, 2024, 2022, 2023] },
            },
        }),
    },
};

export default initConfigProps;
