<template>
  <section class="optionBlock">
    <div @click="toggleName">
      <Field
        :filter="nameFilter"
        :field-width="fieldWidth"
      />
    </div>
    <Field
      :filter="ogrnSelectedFilter"
      :field-width="fieldWidth"
      @toggleFilter="toggleOgrn(false)"
      @changeFilter="toggleOgrn(true)"
    />
    <Field
      :filter="phoneFilter"
      :field-width="fieldWidth"
      :sub-filters="phoneFilter.subFilters"
      @toggleFilter="togglePhone(false)"
      @changeFilter="togglePhone(true)"
      @changeSubFilter="changePhoneSubFilter"
    />
    <Field
      :filter="addressFilter"
      :field-width="fieldWidth"
      @toggleFilter="toggleAddress(false)"
      @changeFilter="toggleAddress(true)"
    />
    <Field
      :filter="emailFilter"
      :field-width="fieldWidth"
      :sub-filters="emailFilter.subFilters"
      @toggleFilter="toggleEmail(false)"
      @changeFilter="toggleEmail(true)"
      @changeSubFilter="changeEmailVerifiedSubFilter"
    />
    <Field
      :filter="siteFilter"
      :field-width="fieldWidth"
      @toggleFilter="toggleSite(false)"
      @changeFilter="toggleSite(true)"
    />
    <Field
      :filter="decisionMakerFilter"
      :field-width="fieldWidth"
      @toggleFilter="toggleDecisionMaker(false)"
      @changeFilter="toggleDecisionMaker(true)"
    />
    <Field
      :filter="showCompanySizeFilter"
      :field-width="fieldWidth"
      @toggleFilter="toggleShowCompanySize(false)"
      @changeFilter="toggleShowCompanySize(true)"
    />
    <Field
      :filter="registrationDateFilter"
      :field-width="fieldWidth"
      @toggleFilter="toggleRegistrationDate(false)"
      @changeFilter="toggleRegistrationDate(true)"
    />
    <Field
      :filter="hasMessengerFilter"
      :field-width="fieldWidth"
      without-check-box
      @toggleFilter="toggleHasMessenger"
    />

    <NotificationComponent
      v-if="isVisible && !hideAdditionalNotification"
      class="notificationBalloon"
      display-mode="overlay"
      cursor="pointer"
      flipper-position="flipper-left"
      @clicked="onNotificationClick"
    >
      Настройте нужные поля,<br>
      а затем перейдите к корзине
    </NotificationComponent>
  </section>
</template>

<script>
import {
  mapActions, mapGetters, mapState,
} from 'vuex';
import Field from '@/egrul/components/Field/Field.vue';
import NotificationComponent
  from '@/common_components/NotificationComponent/NotificationComponent.vue';

export default {
  name: 'OptionBlock',

  components: {
    NotificationComponent,
    Field,
  },

  data() {
    return {
      fieldWidth: '205px',
      hideAdditionalNotification: false,
      visibilityCounter: 0,
      isVisible: false,
    };
  },

  computed: {
    ...mapState([
      'nameFilter',
      'ogrnSelectedFilter',
      'phoneFilter',
      'addressFilter',
      'emailFilter',
      'siteFilter',
      'selectedBasicFilters',
      'decisionMakerFilter',
      'showCompanySizeFilter',
      'registrationDateFilter',
      'hasMessengerFilter',
    ]),

    ...mapGetters([
      'expandAdditionalFilters',
    ]),

    ...mapGetters('summary', [
      'getOneCompanyCost',
    ]),
  },

  watch: {
    selectedBasicFilters: {
      handler() {
        this.hideAdditionalNotification = true;
      },

      deep: true,
    },
  },

  async created() {
    window.addEventListener('hashchange', () => this.showHintBalloon());
  },

  mounted() {
    if (window.vue_header_personal_cabinet._isMounted) {
      this.showHintBalloon();
    } else {
      window.addEventListener('load', () => this.showHintBalloon());
    }
  },

  methods: {
    toggleName() {
      echo('Данную опцию нельзя отключить');
    },

    onNotificationClick() {
      window.MFuncs.yandexReachGoalWithTarget('help_box');
      const siteHeaderHeight = document.querySelector('body .header_top').offsetHeight;
      const elem = document.querySelector('#result_for_balloon_egrul_hint_scrolling');
      window.scrollBy({
        top: (elem.getBoundingClientRect().top - siteHeaderHeight),
        behavior: 'smooth',
      });
    },

    ...mapActions([
      'togglePhone',
      'changePhoneSubFilter',
      'toggleOgrn',
      'toggleAddress',
      'toggleEmail',
      'changeEmailVerifiedSubFilter',
      'toggleSite',
      'toggleDecisionMaker',
      'toggleShowCompanySize',
      'toggleRegistrationDate',
      'toggleHasMessenger',
    ]),

    showHintBalloon() {
      this.isVisible = false;
      const isUserAuthorized = window.vue_header_personal_cabinet.authorized;

      if (this.visibilityCounter < 2 && !isUserAuthorized) {
        setTimeout(
          () => {
            this.isVisible = true;
            this.visibilityCounter += 1;
          },
          5000,
        );
      }
    },
  },
};
</script>

<style scoped>
.optionBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: flex-start;
  margin-top: 20px;
}

.notificationBalloon {
  top: 45%;
  right: 75px;
  max-width: 180px;
}
</style>
