<template>
  <div class="result">
    <img
      alt="error"
      src="/images/error.svg"
      class="result__img"
    >
    <p class="result__text">
      Не найдено данных для исключения, проверьте правильность выбранного столбца.
    </p>
  </div>
</template>

<script>
export default {
  name: 'ErrorResult',
};
</script>

<style>
  .result{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px ;
  }

  .result__img {
    max-width: 85px;
  }

  .result__text {
    font-size: 18px;
    cursor: text;
  }
</style>
