<template>
  <div class="egrulConfigurator">
    <div class="egrulConfiguratorBody">
      <MainConfigurator />
      <AdditionalFilters />
    </div>

    <Banner />

    <OrderSummary />
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import Banner from '@/egrul/components/Banner/Banner.vue';
import MainConfigurator from '@/egrul/components/MainConfigurator/MainConfigurator.vue';
import OrderSummary from '@/egrul/components/OrderSummary/OrderSummary.vue';
import AdditionalFilters from '@/egrul/components/AdditionalFilters/AdditionalFilters.vue';
import initConfigProps from '../../store/utils/initEgrulConfiguration';

export default {
  name: 'EgrulConfigurator',

  components: {
    OrderSummary,
    MainConfigurator,
    Banner,
    AdditionalFilters,
  },

  props: {
    initConfig: initConfigProps.initConfig,
  },

  async created() {
    await Promise.all([
      this.setGeoTree(),
      this.setOkvedTree(),
      this.setOkopfTree(),
      this.setPhoneTypes(),
      this.setIncludeExcludeSelectorData(),
      this.setYears(),
    ]);
    await this.setMonths();

    const initTypes = this.initConfig.initMainConfig.initTypesConfig;
    const initFilters = this.initConfig.initMainConfig.initFiltersConfig;
    const addConfig = this.initConfig.initAddConfig;

    const urlParams = new URLSearchParams(window.location.search);
    const oId = urlParams.get('oId');
    if (oId) {
      initTypes.okveds.codes.push(oId);
      urlParams.delete('oId');
      this.setShowLinkOkvedNotification(true);
      const baseUrl = window.location.origin + window.location.pathname;
      window.history.replaceState(null, '', baseUrl);
    }

    await Promise.all([
      // инициализация фильтров
      this.setInitFilters(initFilters),
      // инициализация типов
      this.setInitTypesData(initTypes),
      // инициализация окопф
      this.selectOkopfsByCodesAndLevels(
        { codesWithLevels: initTypes.okopfs.codes },
      ),
      // инициализация доп. фильтров
      this.setInitAdditionalFilters(addConfig),
    ]);

    this.calculateConfiguration();
  },

  methods: {
    ...mapActions([
      'calculateConfiguration',
      'setIncludeExcludeSelectorData',
      'setInitAdditionalFilters',
      'setInitFilters',
      'setInitTypesData',
      'setPhoneTypes',
      'setYears',
      'setMonths',
    ]),

    ...mapActions('tree', [
      'setGeoTree',
    ]),

    ...mapActions('okvedTree', [
      'setOkvedTree',
    ]),

    ...mapActions('okopfTree', [
      'setOkopfTree',
      'selectOkopfsByCodesAndLevels',
    ]),

    ...mapMutations('okvedTree', [
      'setShowLinkOkvedNotification',
    ]),
  },
};
</script>

<style scoped>
.egrulConfiguratorBody {
  padding: 30px 25px 20px;
  display: grid;
  gap: 30px;
  box-shadow: rgb(153 153 153) 0 -1px 5px 0;
  border-radius: 10px 10px 0 0;
}
</style>
