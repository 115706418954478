<template>
  <section class="configuratorBlock">
    <div
      class="configuratorBlockSection geoAndOkved"
      @click="handleClick"
    >
      <RegionConfigurator
        :it-is-time-to-show="itIsTimeToShow"
      />

      <OkvedWidget
        :it-is-time-to-show="itIsTimeToShow"
      />

      <NotificationComponent
        v-if="showNotification && itIsTimeToShow && !showLinkOkvedNotification"
        class="configurator-block-notification"
        display-mode="overlay"
      >
        <p>
          Сначала выберите нужные <b>города и коды ОКВЭД</b>,
          затем настройте другие фильтры справа
        </p>
      </NotificationComponent>

      <NotificationComponent
        v-if="itIsTimeToShow && showLinkOkvedNotification"
        class="configurator-block-notification link-okved-notification "
        is-highlighted
        display-mode="overlay"
      >
        <p>
          Выбран ОКВЭД <b>{{ getSelectedOkveds[0] }}</b>.
          Вы можете выбрать дополнительные коды ОКВЭД, настроить города и фильтры.
        </p>
      </NotificationComponent>
    </div>

    <div class="configuratorBlockSection okopf">
      <OkopfWidget />
    </div>

    <div class="configuratorBlockSection other">
      <CompanyTypeConfigurator />

      <CompanySizeConfigurator />

      <CompanyStatusConfigurator />
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import OkvedWidget from '@/egrul/components/OkvedWidget/OkvedWidget.vue';
import CompanyTypeConfigurator from '@/egrul/components/CompanyTypeConfigurator/CompanyTypeConfigurator.vue';
import CompanySizeConfigurator from '@/egrul/components/CompanySizeConfigurator/CompanySizeConfigurator.vue';
import RegionConfigurator from '@/egrul/components/RegionConfigurator/RegionConfigurator.vue';
import CompanyStatusConfigurator
  from '@/egrul/components/CompanyStatusConfigurator/CompanyStatusConfigurator.vue';
import OkopfWidget from '@/egrul/components/OkopfWidget/OkopfWidget.vue';
import NotificationComponent from '@/common_components/NotificationComponent/NotificationComponent.vue';

export default {
  name: 'ConfiguratorBlock',

  components: {
    NotificationComponent,
    OkopfWidget,
    CompanyStatusConfigurator,
    RegionConfigurator,
    CompanySizeConfigurator,
    CompanyTypeConfigurator,
    OkvedWidget,
  },

  data() {
    return {
      showNotification: true,
      itIsTimeToShow: false,
    };
  },

  computed: {
    ...mapState('okvedTree', [
      'showLinkOkvedNotification',
    ]),

    ...mapGetters('okvedTree', [
      'getSelectedOkveds',
    ]),
  },

  async created() {
    setTimeout(() => {
      this.itIsTimeToShow = true;
    }, 3000);
  },

  methods: {
    ...mapMutations('okvedTree', [
      'setShowLinkOkvedNotification',
    ]),

    handleClick() {
      this.showNotification = false;
      this.setShowLinkOkvedNotification(false);
    },
  },

};
</script>

<style scoped>
.configuratorBlock {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.configuratorBlockSection {
  display: grid;
  gap: 15px;
}

.configuratorBlockSection.geoAndOkved {
  display: flex;
  flex-direction: column;
}

.configuratorBlockSection.okopf {
  margin-top: 70px;
}

.configuratorBlockSection.other {
  margin-top: 30px;
}

.configurator-block-notification {
  width: 59%;
  height: 63%;
  align-content: center;
  z-index: 6;
  left: 0;
  top: 122px;
}
</style>
