<template>
  <div class="field">
    <div
      :style="{ 'width': fieldWidth }"
      class="filter__item_switch"
    >
      <div :class="hasSubFilters ? 'filter__item_detach' : ''">
        <ToggleSwitch
          :id="filter.name"
          :title="filter.title"
          :chosen="filter.selected"
          :disabled="filter.disabled" :mode="['exportbase']"
          @set-chosen-item="toggleFilter"
        />
        <div
          v-if="hasSubFilters && filter.selected"
          class="container_subFilter_title-list"
        >
          <SubFilterCheckBoxes
            :items="subFilters"
            :mode="['dark']"
            :space-between="5"
            :filter-name="filter.name"
            @chooseCheckBox="changeSubFilter"
          />
        </div>
      </div>
    </div>
    <div
      v-if="!withoutCheckBox"
      class="filter__item_checkbox"
    >
      <div :class="hasSubFilters ? 'filter__item_detach' : ''">
        <div @click="showFilterAlert(filter)">
          <CheckBox
            v-if="filter.name !== 'name'"
            :id="filter.name"
            :chosen="filter.selected && !filter.partiallySelected"
            :disabled="!filter.selected || filter.disabled"
            :partially-fill="filter.selected && filter.partiallySelected"
            :title="getFieldText"
            :mode="getCheckBoxMode"
            @change="changeFilter"
          />
        </div>
        <MoreFieldsEnumTooltip
          v-if="filter.name === 'name'"
        />
        <div
          v-if="filter.selected && filter.name === 'phone_egrul'"
          class="actualization__explain_phone_subFilter__wrapper"
        >
          {{ getExplanationText }}
        </div>
        <div
          v-if="filter.selected && filter.name === 'email' && filter.partiallySelected"
          class="actualization__explain_email_subFilter__wrapper"
        >
          <p class="explanationWarning">
            <b>Внимание!</b> Приблизительно 30% компаний в этом разделе имеют email.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExplainHelper from '@/actualization_service/helper/ExplainHelper';
import MoreFieldsEnumTooltip from '@/egrul/components/MoreFieldsEnumTooltip/MoreFieldsEnumTooltip.vue';
import SubFilterCheckBoxes from '@/egrul/components/SubFilters/SubFilterCheckBoxes.vue';
import ToggleSwitch from 'toggle-switch';
import CheckBox from 'checkbox';

export default {
  name: 'Field',
  components: {
    ToggleSwitch,
    CheckBox,
    MoreFieldsEnumTooltip,
    SubFilterCheckBoxes,
  },

  props: {
    filter: {
      type: Object,
      required: false,
      default: () => {},
    },

    subFilters: {
      type: Array,
      required: false,
      default: () => [],
    },

    fieldWidth: {
      type: String,
      required: false,
      default: '',
    },

    withoutCheckBox: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['changeFilter', 'toggleFilter', 'changeSubFilter'],

  computed: {
    getFieldText() {
      if (this.filter.selected) {
        if (this.filter.partiallySelected) {
          return 'Заполнено там, где есть';
        }
        return 'Обязательное поле';
      }
      return 'Поле не выбрано';
    },

    getCheckBoxMode() {
      return this.filter.selected && !this.filter.disabled ? ['dark'] : ['dark', 'disabled'];
    },

    hasSubFilters() {
      return !!this.subFilters.length;
    },

    getExplanationText() {
      return ExplainHelper.explainPhoneSubFilters({
        phoneFilter: this.filter,
        phoneSubFilters: this.subFilters,
      });
    },
  },

  methods: {
    changeFilter() {
      this.$emit('changeFilter', this.filter.name);
    },

    toggleFilter() {
      this.$emit('toggleFilter', this.filter.name);
    },

    changeSubFilter(payload) {
      this.$emit('changeSubFilter', payload.subFilterName);
    },

    showFilterAlert(filter) {
      if (!filter.selected) {
        window.echo('Данный фильтр не выбран');
      }
    },
  },
};
</script>

<style scoped>

.field {
  display: flex;
  gap: 5px;
  width: 450px;
}

.filter__item_switch {
  display: block;
}

.filter__item_checkbox {
  display: block;
  text-align: left;
}

.container_subFilter_title-list {
  margin-top: 15px;
  margin-left: 25px;
}

.actualization__explain_email_subFilter__wrapper {
  padding: 2% 0 3%;
  width: 280px;
  font-style: italic;
}

.actualization__explain_phone_subFilter__wrapper {
  padding-top: 15px;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  line-height: 1.3;
}

.explanationWarning {
  padding-top: 2%;
  color: #F9701F;
}

.field :deep(.checkbox-button_disabled) {
  opacity: 0;
}

@media (max-width: 1100px) and (min-width: 1030px) {
  .actualization__explain_phone_subFilter__wrapper {
    width: 260px;
  }
}

@media (max-width: 1030px) and (min-width: 800px) {
  .actualization__explain_phone_subFilter__wrapper {
    width: 240px;
  }
}

@media (max-width: 1030px) and (min-width: 800px) {
  .actualization__explain_email_subFilter__wrapper {
    width: 210px;
  }
}
</style>
