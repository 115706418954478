<template>
  <Popup
    @close="closePopup"
  >
    <template #content>
      <p class="okved-tree__title">
        Популярные правовые формы можно отметить кнопками быстрого выбора (ИП, ООО):
      </p>
      <ul class="okopf-presets-list">
        <li class="preset_item">
          <Checkbox
            :chosen="ipPresetFullySelected"
            :partially-fill="ipPresetPartiallySelected"
            :mode="['dark', 'exportbase']"
            :title="ipPreset.name"
            @change="toggleIpPreset"
          />
        </li>
        <li class="preset_item">
          <Checkbox
            :chosen="oooPresetFullySelected"
            :partially-fill="oooPresetPartiallySelected"
            :mode="['dark', 'exportbase']"
            :title="oooPreset.name"
            @change="toggleOooPreset"
          />
        </li>
        <li class="preset_item">
          <Checkbox
            :chosen="otherPresetFullySelected"
            :partially-fill="otherPresetPartiallySelected"
            :mode="['dark', 'exportbase']"
            title="АО, ПАО и другие"
            @change="toggleOtherPreset"
          />
        </li>
      </ul>
      <p class="okved-tree__title">
        Для тонкой фильтрации по кодам ОКОПФ используйте поиск по дереву:
      </p>
      <div class="okved-tree-search">
        <SearchBar
          v-model="searchStringModel"
          class="searchBar"
          width="100%"
          placeholder="Поиск по кодам ОКОПФ"
          @reset="resetSearch"
        />
      </div>
      <TreeComponent
        v-if="!isSearchMode"
        :node="getTree.getRoot()"
        with-important-nodes
        :ul-node-mode="{'tree-ul-pa-left-20': 1}"
        :chosen-node-mode="['mark']"
        class="okved-tree-component scrollable-Y"
        @expand-node="expandOkopfNode"
        @choose-node="chooseNode"
      />
      <div
        v-if="isSearchMode"
        class="search-tree scrollable-Y"
      >
        <span
          v-if="emptySearchResult"
          class="emptySearch"
        >Ничего не найдено</span>
        <TreeComponent
          v-else
          :node="getSearchTree.getRoot()"
          :search-text="searchStringModel"
          :checkbox-node-mode="{'node-important': 2}"
          :ul-node-mode="{'tree-ul-pa-left-20': 1}"
          class="okved-tree-component"
          @expand-node="expandOkopfSearchNode"
          @choose-node="chooseSearchNode"
        />
      </div>
    </template>
  </Popup>
</template>

<script>
import SearchBar from 'searchbar';
import { TreeComponent } from 'tree';
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import Checkbox from 'checkbox';
import Popup from '@/common_components/Popup/Popup.vue';

export default {
  name: 'EgrulOkopfTree',
  components: {
    Checkbox,
    TreeComponent,
    SearchBar,
    Popup,
  },

  computed: {

    ...mapState('okopfTree', [
      'searchString',
    ]),

    ...mapGetters('okopfTree', [
      'getTree',
      'isSearchMode',
      'emptySearchResult',
      'getSearchTree',
      'getSelectedOkopfs',

      'ipPreset',
      'ipPresetFullySelected',
      'ipPresetPartiallySelected',

      'oooPreset',
      'oooPresetFullySelected',
      'oooPresetPartiallySelected',

      'otherPresetFullySelected',
      'otherPresetPartiallySelected',
    ]),

    searchStringModel: {
      get() {
        return this.searchString;
      },

      set(value) {
        this.updateSearch(value);
      },
    },
  },

  watch: {
    getSelectedOkopfs: {
      handler() {
        this.calculateConfiguration();
      },

      deep: true,
    },
  },

  methods: {
    ...mapMutations('okopfTree', [
      'resetSearch',
      'expandOkopfNode',
      'expandOkopfSearchNode',
      'setVisibleTree',
    ]),

    ...mapActions('okopfTree', [
      'updateSearch',
      'chooseOkopfNode',
      'chooseOkopfsSearchNode',
      'toggleIpPreset',
      'toggleOooPreset',
      'toggleOtherPreset',
    ]),

    ...mapActions([
      'calculateConfiguration',
    ]),

    chooseNode(node) {
      this.chooseOkopfNode(node);
    },

    chooseSearchNode(node) {
      this.chooseOkopfsSearchNode(node);
    },

    closePopup() {
      this.resetSearch();
      this.setVisibleTree(false);
    },
  },
};
</script>

<style scoped>
.okved-tree__title {
  font-size: 16px;
  font-weight: 700;
  width: 500px;
}

.okved-tree-component {
  min-height: 50px;
  height: 100%;
  padding-bottom: 20px;
}

.okopf-presets-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
  margin-bottom: 40px;
}
</style>
