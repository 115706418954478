import axios from 'axios';

export default {
    getYears() {
        return fetch('/api/egrul_service/year')
            .then((res) => res.json());
    },
    getPrice() {
        return fetch('/api/egrul_service/price', { method: 'GET' })
            .then((price) => price.json());
    },
    getIncludeExcludeSelectorData() {
        return fetch('/api/egrul_service/include_exclude_selector_data')
            .then((includeExcludeSelectorData) => includeExcludeSelectorData.json());
    },
    getPhoneTypes() {
        return fetch('/api/egrul_service/phone_type')
            .then((phoneTypes) => phoneTypes.json());
    },
    getCities() {
        return fetch('/api/egrul_service/cities', { method: 'GET' })
            .then((cities) => cities.json());
    },
    uploadFile(file) {
        return fetch('/api/egrul_service/upload_exclusion_file', {
            method: 'POST',
            body: file,
        })
            .then((response) => response.json());
    },
    parseFile(data) {
        return fetch('/api/egrul_service/parse_exclusion_file', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json());
    },
    checkAuth() {
        return fetch('api/client_is_authenticated', { method: 'GET' })
            .then((res) => res.json());
    },
    getUserData() {
        return fetch('/api/authorized_client_data', { method: 'GET' })
            .then((res) => res.json());
    },
    getUserDiscount(email) {
        return fetch('/handlers/email_discount.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                email,
            }),
        })
            .then((res) => res.json())
            .catch(() => {
                echo(
                    'Произошла ошибка! Пожалуйста, обратитесь в тех. поддержку: 8 (800) 775 - 29 - 12 (доб. 1)',
                    6000,
                    'error',
                );
            });
    },
    createOrder(dataForOrder) {
        return fetch('/api/egrul_service/create_order', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify(dataForOrder),
        });
    },
    getDemoFileHash(dataForDemo) {
        return fetch('/api/egrul_service/create_demo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify({
                ...dataForDemo,
            }),
        });
    },

    async getOkveds() {
        return (await axios.get('/api/egrul_service/okveds')).data;
    },

    async getOkopfs() {
        return (await axios.get('/api/egrul_service/okopfs')).data;
    },

    async calculate(data) {
        const formData = new URLSearchParams();

        formData.append('cities', JSON.stringify(data.cities));
        formData.append('filters', JSON.stringify(data.filters));
        formData.append('okveds', JSON.stringify(data.okveds));
        formData.append('count', JSON.stringify(data.count));

        try {
            return await axios.post('/api/egrul_service/calculate', formData);
        } catch (err) {
            console.log(err.toJSON());
            echo(
                'Произошла ошибка! Просим вас связаться с тех. поддержкой по тел. 8 800 775-29-12',
                6000,
                'error',
            );
        }
    },
};
