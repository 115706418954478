<template>
  <div class="result">
    <img
      alt="success"
      src="/images/success.svg"
      class="result__img"
    >
    <p class="result__text">
      Мы обнаружили
      <b>{{ getDescriptionParsedData }}</b>
      для исключения из вашего файла!
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SuccessResult',

  computed: {
    ...mapGetters('exclusionFile', [
      'getDescriptionParsedData',
    ]),
  },
};
</script>

<style>
  .result {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px ;
  }

  .result__img {
    max-width: 85px;
  }

  .result__text {
    font-size: 18px;
    cursor: text;
  }
</style>
