<template>
  <div
    class="dropdownConfigurator"
  >
    <span class="dropdownTitle">{{ title }}</span>

    <div @click="toggleDropdown">
      <ButtonSelector
        :content="content"
        :width="width"
      />
    </div>

    <transition name="slide">
      <div
        v-if="isOpen"
        class="dropdownBody"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
import ButtonSelector from 'button-selector';

export default {
  name: 'DropdownConfigurator',

  components: {
    ButtonSelector,
  },

  props: {
    title: {
      type: String,
      require: true,
      default: '',
    },

    width: {
      type: String,
      require: true,
      default: '',
    },

    content: {
      type: String,
      require: false,
      default: '',
    },

    isOpen: {
      type: Boolean,
      require: true,
      default: false,
    },
  },

  emits: ['clickButton'],

  methods: {
    toggleDropdown() {
      this.$emit('clickButton');
    },
  },
};
</script>

<style scoped>
.dropdownConfigurator {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: max-content;
}

.dropdownTitle {
  font-weight: bold;
  font-size: 14px;
}

.dropdownBody {
  position: absolute;
  z-index: 101;
  top: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-bottom: 10px;
  box-shadow: 1px 5px 10px #666;
}

.slide-enter-active {
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 1300px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

</style>
