<template>
  <div class="tooltip">
    <img
      src="../../../assets/images/icon_query.png"
      width="17"
      height="17"
      alt="question"
      data-method="GET"
      data-file="/api/popup/exclusion_popup"
      class="_popup tooltip__img"
    >
  </div>
</template>

<script>
export default {
  name: 'ExclusionTooltip',
};
</script>

<style scoped>
  .tooltip {
    position: relative;
  }

  .tooltip__img {
    cursor: pointer;
  }
</style>
