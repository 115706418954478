<template>
  <div class="row-relevant">
    <p class="row-relevant__description">
      База актуальна на:
    </p>
    <p class="row-relevant__value">
      {{ getCurrentDate }}
    </p>
  </div>
</template>

<script>

import dayjs from 'dayjs';
import 'dayjs/locale/ru';

export default {
  name: 'RowRelevant',

  computed: {
    getCurrentDate() {
      dayjs.locale('ru');
      const currentDate = new Date();
      return dayjs(currentDate).format('DD MMMM YYYY');
    },
  },
};
</script>

<style>
.row-relevant {
  font-weight: bold;
  display: grid;
  grid-template-columns: 130px 1fr;
  justify-items: start;
  align-items: center;
}

.row-relevant__value {
  font-weight: normal;
  color: #629a00;
}
</style>
