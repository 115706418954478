<template>
  <div
    id="result_for_balloon_egrul_hint_scrolling"
    class="okvedSelectorAndSummary"
  >
    <OrderDescription />

    <OrderBucket />
  </div>
</template>

<script>
import OrderDescription from '@/egrul/components/OrderDescription/OrderDescription.vue';
import OrderBucket from '@/egrul/components/OrderBucket/OrderBucket.vue';

export default {
  name: 'OrderSummary',

  components: {
    OrderBucket,
    OrderDescription,
  },
};
</script>

<style scoped>
.okvedSelectorAndSummary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--margin-left-cart);
  padding-top: var(--margin-under-configurator);
  background-color: #f7f7f7;
}
</style>
