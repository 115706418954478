<template>
  <div v-if="visible" class="preloaderWrapper">
    <span class="preloaderText">
      Производим расчет...
    </span>
  </div>
</template>

<script>
export default {
  name: 'GrayPreloader',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style scoped>
  .preloaderWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    background: rgba(153, 153, 153, .4);
  }

  .preloaderText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 101;
    padding: 15px 20px;
    border-radius: 5px;
    text-align: center;
    background-color: #fff;
    font-size: 14px;
    font-style: italic;
  }
</style>