<template>
  <div>
    <p class="chosen-column__description">
      После выбора столбца с данными, найденные компании не попадут в купленную базу.
    </p>
    <RepresentUploadedFile
      :rows="xlsxData"
      :columns="getColumns"
      :chosen-column="indexOfChosenColumn"
      @choose-column="chooseColumnByClick"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { columns } from '@/egrul/store/utils/constants';
import RepresentUploadedFile from '../../../actualization_service/components/Modals/RepresentUploadedFile.vue';

export default {
  name: 'ChosenColumnStep',
  components: {
    RepresentUploadedFile,
  },

  computed: {
    ...mapState('exclusionFile', [
      'xlsxData',
      'chosenColumn',
    ]),

    indexOfChosenColumn() {
      return Number.parseInt(this.chosenColumn.value);
    },

    getColumns() {
      return columns;
    },
  },

  methods: {
    ...mapMutations('exclusionFile', [
      'setChosenColumn',
    ]),

    chooseColumnByClick(columnIndex) {
      this.setChosenColumn(columnIndex);
    },
  },

};
</script>

<style scoped>
  .chosen-column__description {
    margin-bottom: 25px;
    font-size: 16px;
    font-weight: bold;
    cursor: text;
  }
</style>
