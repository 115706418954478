<template>
  <form class="form">
    <div
      v-for="(step, index) in steps"
      v-show="activeStepIndex===index"
      :id="'step'+(index+1)"
      :key="index"
      class="form-step"
    >
      <slot :name="'step'+(index+1)" />
    </div>
    <div class="wrapper-nav">
      <button
        v-if="activeStepIndex !== 0"
        class="form__button_prev form__button"
        @click.prevent="prevStep"
      >
        Назад
      </button>
      <button
        class="form__button_next form__button"
        @click.prevent="submitStep"
      >
        {{ getTextForStepNext }}
      </button>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'UploadForm',
  props: ['steps'],

  data() {
    return {
      activeStepIndex: 0,
    };
  },

  computed: {
    ...mapState('exclusionFile', [
      'countParsedData',
    ]),

    isLastStep() {
      return this.activeStepIndex === this.steps.length - 1;
    },

    getTextForStepNext() {
      if (this.isLastStep && this.countParsedData === 0) {
        return 'Выйти';
      }

      if (this.isLastStep) {
        return 'Применить';
      }

      return 'Далее';
    },
  },

  methods: {
    submitStep() {
      if (!this.steps[this.activeStepIndex].step_valid) {
        this.$emit('validateStep', this.activeStepIndex);
        return false;
      }
      const isLastStep = (this.activeStepIndex === (this.steps.length - 1));
      if (isLastStep) {
        this.$emit('onComplete');
        return true;
      }
      this.activeStepIndex++;
    },

    prevStep() {
      this.activeStepIndex--;
      this.$emit('resetValidation', this.activeStepIndex);
    },
  },
};
</script>

<style scoped>
  .form {
    display: flex;
    flex-direction: column;
    gap: 30px;
    flex: 1 1 auto;
    min-height: 120px;
    justify-content: space-between;
    width: 820px;
  }

  .wrapper-nav {
    align-self: flex-end;
    display: flex;
    gap: 15px;
  }

  .form__button {
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 8px;
    border: none;
    font-weight: bold;
    transition: .3s;
    font-size: 15px;
  }

  .form__button_next {
    background: var(--color-button-prime-hover);
    color: #fff;
  }

  .form__button_next:hover {
    opacity: .8;
  }

  .form__button_prev:hover {
    background: var(--color-not-active);
  }

  @media(max-width: 950px) {
    .form {
      width: 500px;
      overflow: hidden;
    }
  }

  @media(max-width: 655px) {
    .form {
      width: 300px;
    }
  }
</style>

