<template>
  <div class="okved-widget">
    <div
      class="okved-selector"
      @click="openOkvedTree"
    >
      <span class="okved-selector__text">
        <b>Коды ОКВЭД:</b>
      </span>
      <ButtonSelector
        :content="getDescription"
        :class="{'selector-nothing-selected': okvedIsEmpty & itIsTimeToShow}"
        class="cityTrees"
      />
    </div>
    <EgrulOkvedTree v-if="visibleTree" />
  </div>
</template>

<script>
import {
  mapGetters, mapMutations, mapState,
} from 'vuex';
import EgrulOkvedTree from '@/egrul/components/EgrulOkvedTree/EgrulOkvedTree.vue';
import ButtonSelector from 'button-selector';

export default {
  name: 'OkvedWidget',
  components: {
    ButtonSelector,
    EgrulOkvedTree,
  },

  props: {
    itIsTimeToShow: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapState('okvedTree', [
      'visibleTree',
      'tree',
    ]),

    ...mapGetters('okvedTree', [
      'getSelectedOkveds',
      'okvedIsEmpty',
      'allOkvedsSelected',
    ]),

    getDescription() {
      const selectedOkvedsNumber = this.getSelectedOkveds.length;
      if (this.allOkvedsSelected) {
        return 'Все ОКВЭД';
      }
      return selectedOkvedsNumber ? `${selectedOkvedsNumber} ОКВЭД` : 'Не выбрано';
    },
  },

  methods: {
    ...mapMutations('okvedTree', [
      'setVisibleTree',
    ]),

    openOkvedTree() {
      this.setVisibleTree(true);
    },
  },
};
</script>

<style scoped src="../../../assets/css/element.css" />

<style scoped>
.okved-widget {
  width: max-content;
}
.okved-selector {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.okved-selector__text {
  font-size: 14px;
}
</style>
