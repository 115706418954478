<template>
  <div class="description-list">
    <RowFeature />
    <RowLegalForm />
    <RowFields
      :main-fields="getMainFields"
      :extra-fields="getExtraFields"
    />
    <RowFormat />
    <RowRelevant />
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import RowFields from '@/common_components/PayPopup/OrderDescription/RowFields.vue';
import RowRelevant from './RowRelevant.vue';
import RowFormat from './RowFormat.vue';
import RowFeature from './RowFeature.vue';
import RowLegalForm from './RowLegalForm.vue';

export default {
  name: 'Description',
  components: {
    RowRelevant,
    RowFormat,
    RowFields,
    RowFeature,
    RowLegalForm,
  },

  computed: {
    ...mapGetters([
      'getFilters',
    ]),

    getMainFields() {
      return this.getFilters
        .filter((filter) => filter.selected)
        .map((filter) => filter.title);
    },

    getExtraFields() {
      return [
        'Город и регион',
        'Статус компании',
        'Тип компании',
        'Часовой пояс',
      ];
    },
  },
};
</script>

<style scoped>
.description-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  color: black;
}
</style>
