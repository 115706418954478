<template>
  <div>
    <div class="selectorBorderVertical" />

    <div class="selectorBorderVertical" />

    <div class="selectorHead">
      <div class="selectorHeadOptionTitle">
        &nbsp;
      </div>
      <div class="inclusionOptions">
        <div class="inclusionOptionsItem selectorCell">
          <div class="inclusionOptionTitle">
            Включено
          </div>
          <div class="inclusionOptionSubtitle">
            в заказ
          </div>
        </div>
      </div>
    </div>

    <section class="selectorOptionsSection">
      <div class="selectorOptionsSectionTitle">
        Статус компании
      </div>

      <IncludeExcludeSelector
        v-for="(status) in companyStatuses"
        :selector-label="status.title"
        :option-obj="status"
        only-included
        @inclusion="toggleIncludeCompanyStatus(status.id)"
      />
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import IncludeExcludeSelector from '@/common_components/IncludeExcludeSelector/IncludeExcludeSelector.vue';

export default {
  name: 'CompanyStatusSelector',

  components: {
    IncludeExcludeSelector,
  },

  computed: {
    ...mapState([
      'companyStatuses',
    ]),
  },

  methods: {
    ...mapActions([
      'toggleIncludeCompanyStatus',
    ]),
  },
};
</script>

<style scoped src="../EgrulConfigurator/EgrulConfigurator.css" />

<style scoped src="../../../common_components/IncludeExcludeSelector/IncludeExcludeSelector.css" />
