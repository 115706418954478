<template>
  <div class="subFilter-list">
    <div
      v-for="(item, index) in items"
      :key="index + '_' + filterName + 'sub-filter-checkbox'"
      :style="spaceBetweenItems(index)"
      class="subFilter-row"
    >
      <CheckBox
        :id="item.name"
        :title="item.title"
        :title-width="titleWidth"
        :chosen="item.selected"
        :mode="mode"
        @change="chooseCheckBox"
      />
      <span
        v-if="item.name === 'affiliated_phone'"
        class="explanation popup"
        data-file="/api/popup/actualization_affiliated_phone_explanation"
      />
      <span
        v-if="item.name === 'email_verified'"
        class="explanation _popup"
        data-method="GET"
        data-file="api/popup/egrul_validated_email_info"
      />
    </div>
  </div>
</template>

<script>
import CheckBox from 'checkbox';

export default {
  name: 'SubFilterCheckBoxes',
  components: {
    CheckBox,
  },

  props: {
    items: Array,
    filterName: String,
    mode: {
      type: Array,
      required: false,
      default: () => [],
    },

    titleWidth: {
      type: Number,
      required: false,
    },

    spaceBetween: {
      type: Number,
      required: false,
    },
  },

  emits: ['chooseCheckBox'],

  methods: {
    chooseCheckBox(id) {
      this.$emit(
        'chooseCheckBox',
        {
          mainFilterName: this.filterName,
          subFilterName: id,
        },
      );
    },

    spaceBetweenItems(index) {
      if (this.spaceBetween === undefined) {
        return '';
      }
      return index === this.items.length - 1 ? '' : `margin-bottom:${this.spaceBetween}px`;
    },
  },
};
</script>

<style scoped src="./SubFilters.css">
</style>
