<template>
  <div>
    <div class="selectorBorderVertical companyType" />

    <div class="selectorBorderVertical companyType" />

    <div class="selectorHead">
      <div class="selectorHeadOptionTitle selectorHeadInputWrapper companyType">
        <InputSearch
          :value="searchCompanyType"
          placeholder="Введите тип компании"
          class="selectorInput"
          focus
          @updateValue="updateSearch"
        />
      </div>
      <div class="inclusionOptions">
        <div class="inclusionOptionsItem selectorCell">
          <div class="inclusionOptionTitle">
            Включено
          </div>
          <div class="inclusionOptionSubtitle">
            в заказ
          </div>
        </div>
        <div class="inclusionOptionsItem selectorCell">
          <div class="inclusionOptionTitle">
            Исключено
          </div>
          <div class="inclusionOptionSubtitle">
            из заказа
          </div>
        </div>
      </div>
    </div>

    <section class="selectorOptionsSection">
      <div class="selectorOptionsSectionTitle">
        Типы компаний
      </div>

      <IncludeExcludeSelector
        is-all-types-selector
        :all-types-selected="allCompanyTypesSelected"
        selector-label="Все типы"
        mode="egrulCompanyTypeMode"
        @allTypes="toggleAllCompanyTypeSelected"
      />

      <CompanyTypeGroups />
    </section>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import CompanyTypeGroups from '@/egrul/components/CompanyTypeGroups/CompanyTypeGroups.vue';
import InputSearch from '@/common_components/InputSearch/InputSearch.vue';
import IncludeExcludeSelector
  from '../../../common_components/IncludeExcludeSelector/IncludeExcludeSelector.vue';

export default {
  name: 'CompanyTypeSelector',
  components: {
    InputSearch,
    CompanyTypeGroups,
    IncludeExcludeSelector,
  },

  computed: {
    ...mapState([
      'companyTypes',
      'searchCompanyType',
    ]),

    allCompanyTypesSelected() {
      return !Object.keys(this.companyTypes)
        .some((id) => this.companyTypes[id].included
            || this.companyTypes[id].excluded);
    },
  },

  methods: {
    ...mapActions([
      'toggleAllCompanyTypeSelected',
    ]),

    ...mapMutations(['setSearchCompanyType']),

    updateSearch(value) {
      this.setSearchCompanyType(value);
    },
  },
};
</script>

<style scoped src="../EgrulConfigurator/EgrulConfigurator.css" />

<style scoped src="../../../common_components/IncludeExcludeSelector/IncludeExcludeSelector.css" />

<style scoped>
.selectorHeadInputWrapper {
  margin-left: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.selectorInput {
  width: 100%;
}
</style>
