<!-- компонент реализуется уникально для каждого радела -->

<template>
  <div>
    <PayPopup
      :visible-pay-popup="visiblePayPopup"
      :email="email"
      :order-id="orderId"
      :client-id="clientId"
      :customer-is-authorized="customerIsAuthorized"
      :client-balance="clientBalance"
      :cost="cost"
      :count-companies="count"
      :user-discount="userDiscount"
      with-save-section
      with-demo-section
      :initial-section="initialSection"
      :demo-creation-is-running="demoCreationIsRunning"
      :demo-created-successfully="demoCreatedSuccessfully"
      :show-order-preloader="showOrderPreloader"
      :initial-tab="initialTab"
      payment-target="database"
      with-promocode
      order-type="egrul"
      @close-popup="closePayPopup"
      @input-email="changeEmail"
      @create-order="createOrder"
      @create-bill="createBill"
      @init-login="initLogin"
      @download-demo="downloadDemoFile"
      @save-order="saveOrder"
    >
      <template v-slot:description>
        <Description />
      </template>
    </PayPopup>

    <div class="egrul_summary_fixed_wrapper">
      <Card
        :total-cost="cost"
        :total-count="count"
        :relative-discount="relativeDiscountValue"
        :visible-discount="visibleDiscount"
        balance-bonus-notice
        @demoClick="demoClick"
        @downloadButtonClick="downloadButtonClick"
      >
        <template v-slot:notifications>
          <EgrulNotificationList
            :company-cost="cost"
            :selected-geo-count="selectedGeoUnitsCount"
            :selected-okved-count="getSelectedOkveds.length"
            @openOkvedTree="openOkvedTree"
            @openCityTree="openCityTree"
            @openCashlessPopup="openCashlessPopup"
          />
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import {
  mapState, mapGetters, mapActions, mapMutations,
} from 'vuex';
import Description from '@/egrul/components/EgrulSummary/components/Description/Description.vue';
import PayPopup from '@/common_components/PayPopup/PayPopup.vue';
import Card from '@/common_components/Card/Card.vue';
import EgrulNotificationList from './components/EgrulNotificationList/EgrulNotificationList.vue';

export default {
  name: 'EgrulSummary',
  components: {
    Description,
    PayPopup,
    Card,
    EgrulNotificationList,
  },

  computed: {
    ...mapState('summary', [
      'cost',
      'count',
      'visibleDiscount',
      'relativeDiscountValue',
    ]),

    ...mapState('payPopup', [
      'visiblePayPopup',
      'email',
      'orderId',
      'clientId',
      'customerIsAuthorized',
      'initialSection',
      'demoCreationIsRunning',
      'demoCreatedSuccessfully',
      'userDiscount',
      'clientBalance',
      'initialTab',
      'showOrderPreloader',
    ]),

    ...mapGetters('tree', [
      'selectedGeoUnits',
    ]),

    ...mapGetters('payPopup', [
      'getDataToCreateOrder',
      'getUrlForBill',
      'getDataForDemoFile',
    ]),

    ...mapGetters('okvedTree', [
      'getSelectedOkveds',
    ]),

    selectedGeoUnitsCount() {
      return (
        this.selectedGeoUnits.city.length
        + this.selectedGeoUnits.region.length
        + this.selectedGeoUnits.country.length
      );
    },
  },

  created() {
    this.setPrice();
  },

  methods: {
    ...mapActions('summary', [
      'setPrice',
    ]),

    ...mapMutations([
      'setVisibleTree',
    ]),

    ...mapMutations('payPopup', [
      'setVisiblePayPopup',
      'setTypePayment',
      'setOrderId',
      'setClientId',
      'setEmail',
      'setInitialSection',
      'setOldBeznalData',
      'setOptionOldBeznalData',
      'setDemoCreationIsRunning',
      'setDemoCreatedSuccessfully',
      'setInitialTab',
      'setShowOrderPreloader',
    ]),

    ...mapMutations('okvedTree', {
      setVisibleOkvedTree: 'setVisibleTree',
    }),

    ...mapActions('payPopup', [
      'getAuthStatus',
      'getUserData',
      'getUserDiscount',
      'getDemoFileHash',
      'setDemoHash',
    ]),

    ...mapActions('payPopup', {
      createOrderStore: 'createOrder',
    }),

    async demoClick() {
      if (this.cost <= 0) {
        await window.vue_demo_popup.$store.dispatch('openPopup');
        return;
      }
      await this.getAuthStatus();
      if (this.customerIsAuthorized) {
        await this.getUserData();
      }
      this.setInitialSection('demo');
      this.setVisiblePayPopup(true);
    },

    async downloadButtonClick() {
      if (this.cost === 0) {
        return echo('Выберите больше фирм', 6000, 'warning');
      }
      window.MFuncs.reachYandexGoal('config');
      await this.getAuthStatus();

      if (this.customerIsAuthorized) {
        await this.getUserData();
      }

      this.setVisiblePayPopup(true);
    },

    closePayPopup() {
      this.setVisiblePayPopup(false);
      this.setInitialSection('');
      this.setInitialTab('');
    },

    async createOrder() {
      this.setShowOrderPreloader(true);

      this.setTypePayment('8');
      await this.getUserDiscount(this.email);

      const res = await this.createOrderStore(this.getDataToCreateOrder);

      this.setOrderId(String(res.id_plan));
      this.setClientId(Number(res.customer_number));

      window.openWebSocketChannel(res.id_plan, res.hash);

      this.setShowOrderPreloader(false);
    },

    async saveOrder(email) {
      this.setTypePayment('8');
      await this.getUserDiscount(email);
      const localData = JSON.parse(JSON.stringify(this.getDataToCreateOrder));
      localData.user.email = email;
      await this.createOrderStore(localData);

      echo(
        'Заказ успешно сохранен.',
        6000,
        'success',
      );
    },

    changeEmail(email) {
      this.setEmail(email);
      this.getUserDiscount(email);
    },

    async createBill(oldBeznalData) {
      this.setTypePayment('9');
      this.setOldBeznalData(oldBeznalData);

      const res = await this.createOrderStore(this.getDataToCreateOrder);

      this.setOptionOldBeznalData({
        option: 'umb_schet',
        value: res.id_plan,
      });
      this.setOptionOldBeznalData({
        option: 'count_for_beznal',
        value: res.count_for_beznal,
      });
      this.setOptionOldBeznalData({
        option: 'cost_for_beznal',
        value: res.cost_for_beznal,
      });

      if (window.MFuncs.goYandexKassaWithParams) {
        window.MFuncs.goYandexKassaWithParams(res.id_plan, res.cost_for_beznal);
      }
      if (window.MFuncs.reachYandexGoal) {
        MFuncs.reachYandexGoal('beznal');
      }

      // костыль для лучшего достижения целей при медленном интернете
      // переходим на страницу счета с небольшой задержкой, чтобы цели дошли до Яндекса
      setTimeout(() => {
        window.location.href = this.getUrlForBill;
      }, 100);
    },

    async downloadDemoFile({ format, option, email }) {
      this.setDemoCreationIsRunning(true);

      const dataForDemo = this.getDataForDemoFile;
      dataForDemo.okveds = this.getSelectedOkveds;
      dataForDemo.format = format;
      dataForDemo.email = email;
      const optionText = option ? '&options=hyperlinks_phone' : '';
      const { message, error } = await this.getDemoFileHash(dataForDemo);

      this.setDemoCreationIsRunning(false);
      if (error) {
        this.setDemoCreatedSuccessfully(false);
        return echo(message, 6000, 'warning');
      }
      this.setDemoCreatedSuccessfully(true);

      this.setDemoHash(message);

      window.location.href = `/api/egrul_service/create_demo_file?code=${message}&format=${format}${optionText}`;

      window.echo(
        'Демо-выгрузка сформирована и скачивается. Ссылка отправлена на ваш E-mail',
        6000,
        'success',
      );
    },

    addEventForBalanceBlock() {
      document.body.addEventListener('open-balance-block', async () => {
        await this.getAuthStatus();
        if (this.customerIsAuthorized) {
          await this.getUserData();
        }
        this.setInitialTab('BalanceForm');
        await this.setVisiblePayPopup(true);
        this.setInitialTab('');
      });
    },

    initLogin() {
      window.open_balance_block = true;
      this.addEventForBalanceBlock();
      this.setVisiblePayPopup(false);
      document.dispatchEvent(new Event('openAuthWrapper'));
    },

    openOkvedTree() {
      this.setVisibleOkvedTree(true);
    },

    openCityTree() {
      this.setVisibleTree(true);
    },

    openCashlessPopup() {
      this.setInitialTab('CashlessPaymentForm');
      this.setVisiblePayPopup(true);
    },
  },
};
</script>

<style scoped>
</style>
