import api from '../../api/index';
import { columns, dataTypeList } from '../utils/constants';

export default {
    namespaced: true,

    state: {
        excludeCompanies: false, // включено исключение компаний
        file: null,
        fileName: '', // имя файла на сервере
        xlsxData: [],
        chosenColumn: {},
        chosenType: null,
        fileIsUploaded: false,
        countParsedData: 0,
        showPreloader: false,
        fileIsParsed: false,
    },

    getters: {
        getDataForParse(state) {
            return {
                column: state.chosenColumn.title,
                fileName: state.fileName,
                type: state.chosenType,
            };
        },

        // данные для старого запрос /api/egrul_service/calculate
        getDataForConfig(state) {
            return {
                fileName: state.fileName,
                excludeCompanies: state.excludeCompanies,
                dataType: state.chosenType,
                fileIsParsed: state.fileIsParsed,
            };
        },

        getDescriptionParsedData(state) {
            return `${state.countParsedData} ${dataTypeList[state.chosenType]}`;
        },

        getFormattedChosenType(state) {
            return dataTypeList[state.chosenType];
        },

        getClientFileName(state) {
            return state.file ? state.file.name : null;
        },
    },

    mutations: {
        setFile(state, file) {
            state.file = file;
        },

        initializeXlsxData(state) {
            const cells = [];

            for (let i = 0; i < 10; i++) {
                cells.push('');
            }

            for (let i = 0; i < 7; i++) {
                state.xlsxData.push(cells);
            }
        },

        setXlsxData(state, demoXlsx) {
            state.xlsxData = [];

            demoXlsx.forEach((item) => { state.xlsxData.push(item); });
        },

        setChosenColumn(state, value) {
            state.chosenColumn = columns[value];
        },

        setFileIsUploaded(state, value) {
            state.fileIsUploaded = value;
        },

        setChosenDataType(state, value) {
            state.chosenType = value;
        },

        setFileName(state, name) {
            state.fileName = name;
        },

        setShowPreloader(state, value) {
            state.showPreloader = value;
        },

        setExcludeCompanies(state, value) {
            state.excludeCompanies = value;
        },

        setCountParsedData(state, value) {
            state.countParsedData = value;
        },

        setFileIsParsed(state, value) {
            state.fileIsParsed = value;
        },
    },

    actions: {
        uploadClientFile({ commit }, fileFormData) {
            commit('setShowPreloader', true);
            commit('setFile', fileFormData.get('file'));

            return api.uploadFile(fileFormData)
                .then((response) => {
                    commit('setXlsxData', response.demoXlsx);
                    commit('setFileIsUploaded', true);
                    commit('setFileName', response.fileName);
                })
                .catch(() => {
                    window.echo(
                        'Произошла ошибка при загрузке файла! '
                        + 'Просьба связаться с тех. поддержкой'
                        + 'по тел.: 8 800 775-29-12 или info@export-base.ru',
                        8000,
                        'error',
                    );
                    commit('setFileIsUploaded', false);
                })
                .finally(() => {
                    commit('setShowPreloader', false);
                });
        },

        parseFile({ commit, dispatch }, data) {
            commit('setShowPreloader', true);
            return api.parseFile(data)
                .then((res) => {
                    commit('setCountParsedData', res.countParsedData);
                    commit('setFileIsParsed', true);
                })
                .catch((error) => {
                    window.echo(
                        'Произошла ошибка при обработке файла! '
                        + 'Просьба связаться с тех. поддержкой'
                        + 'по тел.: 8 800 775-29-12 или info@export-base.ru',
                        8000,
                        'error',
                    );
                    throw error;
                })
                .finally(() => {
                    commit('setShowPreloader', false);
                });
        },

        toggleExcludeFilter({ state, commit, dispatch }, value) {
            commit('setExcludeCompanies', value);
        },

        resetSettings({ commit }) {
            commit('setCountParsedData', 0);
            commit('setFileIsParsed', false);
        },
    },
};
