<template>
  <div class="orderDescription">
    <OrderSettingsInfo class="_offset" />
    <ExclusionFilter />
  </div>
</template>

<script>
import ExclusionFilter from '@/egrul/components/ExclusionCompaniesFilter/ExclusionFilter.vue';
import OrderSettingsInfo from '@/egrul/components/OrderSettingsInfo/OrderSettingsInfo.vue';

export default {
  name: 'OrderDescription',

  components: {
    OrderSettingsInfo,
    ExclusionFilter,
  },
};
</script>

<style scoped>
.orderDescription {
  padding: 15px;
  border-radius: 5px;
  box-shadow: rgb(153 153 153) 0 2px 5px 0;
  background: var(--background-color-configurator);
}

.orderDescriptionBody {
  padding: 15px;
}

._offset {
  margin-bottom: 30px;
}
</style>
