/* eslint-disable no-param-reassign */

import intersection from 'lodash.intersection';
import difference from 'lodash.difference';
import { NodeValue, Tree } from 'tree';
import api from '@/egrul/api';
import PresetsUtil from '../utils/PresetsUtil';

Tree.prototype.selectNodesByCodesAndLevels = function selectNodesByCodesAndLevels(codesWithLevels) {
    const levelMap = new Map();
    codesWithLevels.forEach(({ code, level }) => {
        if (!levelMap.has(level)) {
            levelMap.set(level, new Set());
        }
        levelMap.get(level).add(code);
    });

    const traverse = (node) => {
        if (!node) return;

        const { nodeLevel } = node.value;
        let nodeCode = null;

        if (node.value && node.value.obj) {
            nodeCode = node.value.obj.code || node.value.obj.id;
        }

        if (nodeCode && levelMap.has(nodeLevel) && levelMap.get(nodeLevel).has(nodeCode)) {
            this.chooseNode(nodeLevel, nodeCode, false);
        }

        if (node.children) {
            node.children.forEach(traverse);
        }
    };

    this.selectOrDropAllNodes(false);

    traverse(this.root);
};

export default {
    namespaced: true,

    state: {
        okopfs: [],

        /**
         * @var {Tree}
         */
        tree: {},

        presets: {},

        searchString: '',

        /**
         * @var {Tree}
         */
        searchTree: {},

        visibleTree: false,

        initialized: false,
    },

    getters: {

        isInitialized: (state) => state.initialized,

        getTree(state) {
            return state.tree;
        },

        getSearchTree(state) {
            return state.searchTree;
        },

        isSearchMode(state) {
            return (state.searchString.length >= 3);
        },

        emptySearchResult(state) {
            return state.searchTree.isEmpty();
        },

        okopfLeafs(state) {
            return state.okopfs
                .filter((okopf) => okopf.is_leaf)
                .map((okopf) => okopf.code);
        },

        ipPreset(state) {
            return state.presets.ip;
        },

        ipPresetFullySelected(state, getters) {
            const ipOkopfs = getters.ipPreset.okopfs;
            const selectedOkopfs = getters.getSelectedOkopfs;
            return (intersection(ipOkopfs, selectedOkopfs).length === ipOkopfs.length);
        },

        ipPresetPartiallySelected(state, getters) {
            const ipOkopfs = getters.ipPreset.okopfs;
            const selectedOkopfs = getters.getSelectedOkopfs;
            return (
                intersection(ipOkopfs, selectedOkopfs).length > 0
                && intersection(ipOkopfs, selectedOkopfs).length < ipOkopfs.length
            );
        },

        oooPreset(state) {
            return state.presets.ooo;
        },

        oooPresetFullySelected(state, getters) {
            const oooOkopfs = getters.oooPreset.okopfs;
            const selectedOkopfs = getters.getSelectedOkopfs;
            return (intersection(oooOkopfs, selectedOkopfs).length === oooOkopfs.length);
        },

        oooPresetPartiallySelected(state, getters) {
            const oooOkopfs = getters.oooPreset.okopfs;
            const selectedOkopfs = getters.getSelectedOkopfs;
            return (
                intersection(oooOkopfs, selectedOkopfs).length > 0
                && intersection(oooOkopfs, selectedOkopfs).length < oooOkopfs.length
            );
        },

        otherPresetOkopfs(state, getters) {
            return difference(
                getters.okopfLeafs,
                getters.ipPreset.okopfs,
                getters.oooPreset.okopfs,
            );
        },

        otherPresetFullySelected(state, getters) {
            const otherOkopfs = getters.otherPresetOkopfs;
            const selectedOkopfs = getters.getSelectedOkopfs;
            return (intersection(otherOkopfs, selectedOkopfs).length === otherOkopfs.length);
        },

        otherPresetPartiallySelected(state, getters) {
            const otherOkopfs = getters.otherPresetOkopfs;
            const selectedOkopfs = getters.getSelectedOkopfs;
            return (
                intersection(otherOkopfs, selectedOkopfs).length > 0
                && intersection(otherOkopfs, selectedOkopfs).length < otherOkopfs.length
            );
        },

        getSelectedOkopfs(state) {
            if (!state.tree.root) return [];

            let okopfs = [];
            let okopfsOnLevel = [];
            for (let i = 2; i < 5; i += 1) {
                okopfsOnLevel = state
                    .tree
                    .getChosenNodesFromLevel(i)
                    .filter((okopf) => !okopf.children.length)
                    .map((okopf) => okopf.value.obj.id);

                okopfs = [...okopfs, ...okopfsOnLevel];
            }

            return okopfs;
        },

        getOkopfsForCalculationRequest(state, getters) {
            if (getters.noOkopfsSelected) {
                return getters.okopfLeafs;
            }
            return getters.getSelectedOkopfs;
        },

        noOkopfsSelected(state, getters) {
            return getters.getSelectedOkopfs.length === 0;
        },

        allOkopfsSelected(state) {
            if (!state.tree.root) return true;
            return (
                state.tree.root.children[0].value.isChosen
                && !state.tree.root.children[0].value.isPartiallyChosen
            );
        },

        selectedOkopfsDescription(state, getters) {
            if (getters.allOkopfsSelected) {
                return 'Все компании';
            }
            if (getters.noOkopfsSelected) {
                return 'Все компании';
            }

            const presets = [
                {
                    name: 'ИП',
                    values: getters.ipPreset.okopfs,
                },
                {
                    name: 'ООО',
                    values: getters.oooPreset.okopfs,
                },
                {
                    name: 'АО, ПАО и другие',
                    values: getters.otherPresetOkopfs,
                },
            ];
            if (PresetsUtil.selectionIsUnionOfPresets(getters.getSelectedOkopfs, presets)) {
                const presetsInUnion = PresetsUtil.getSelectedPresets(
                    getters.getSelectedOkopfs,
                    presets,
                );
                return presetsInUnion.map((preset) => preset.name).join('; ');
            }

            const selectedOkopfsNumber = getters.getSelectedOkopfs.length;
            return `${selectedOkopfsNumber} ОКОПФ`;
        },
    },

    mutations: {
        resetSearch(state) {
            state.searchString = '';
        },

        setSearchString(state, searchString) {
            state.searchString = searchString;
        },

        expandOkopfNode: (state, nodeDesc) => {
            state.tree.expandNode(nodeDesc.level, nodeDesc.id);
        },

        runSearch(state) {
            state.searchTree = state.tree.filterTreeByNodeName(state.searchString, 0, false);
        },

        setPresets(state, { presets }) {
            const presetsWithInitialState = presets.map((preset) => ({
                ...preset,
                selected: true,
            }));

            const keyedPresets = {};
            presetsWithInitialState.forEach((preset) => {
                keyedPresets[preset.key] = preset;
            });

            state.presets = keyedPresets;
        },

        setTree(state, { okopfs }) {
            state.okopfs = okopfs;

            // корень
            const tree = new Tree(4);
            const nodeValue = new NodeValue(
                { id: 1, name: 'Выбрать все ОКОПФ' },
                1,
                true,
                false,
                true,
                true,
            );
            tree.addNodeByParentId(nodeValue, 0);

            // Разделы классификатора ОКОПФ
            const okopfSections = okopfs.filter((okopf) => okopf.level === 0);

            okopfSections.forEach((okopfSection) => {
                okopfSection.id = okopfSection.code;
                okopfSection.name = `${okopfSection.name}`;
                const nodeSection = new NodeValue(
                    okopfSection,
                    2,
                    false,
                    false,
                    true,
                );

                tree.addNodeByParentId(nodeSection, 1);
            });

            // Типы организационно-правовых форм
            const okopfTypes = okopfs.filter((okopf) => okopf.level === 1);

            okopfTypes.forEach((okopfType) => {
                okopfType.id = okopfType.code;
                okopfType.name = `${okopfType.name}`;

                const nodeType = new NodeValue(
                    okopfType,
                    3,
                    false,
                    false,
                    true,
                );

                tree.addNodeByParentId(nodeType, okopfType.parent_code);
            });

            // Виды организационно-правовых форм
            const okopfKinds = okopfs.filter((okopf) => okopf.level === 2);

            okopfKinds.forEach((okopfKind) => {
                okopfKind.id = okopfKind.code;
                okopfKind.name = `${okopfKind.name}`;

                const nodeKind = new NodeValue(
                    okopfKind,
                    4,
                    false,
                    false,
                    true,
                );

                tree.addNodeByParentId(nodeKind, okopfKind.parent_code);
            });

            state.tree = tree;
        },

        expandOkopfSearchNode: (state, nodeDesc) => {
            state.searchTree.expandNode(nodeDesc.level, nodeDesc.id);
        },

        syncSearchTree: (state) => {
            state.searchTree.copyStateOfChoice(state.searchTree.root, state.tree);
        },

        chooseOkopfNode: (state, nodeDesc) => {
            state.tree.chooseNode(nodeDesc.level, nodeDesc.id, false);
        },

        chooseOrCancelTree: (state, choice) => {
            state.tree.selectOrDropAllNodes(choice);
        },

        chooseOrCancelSearchTree(state, choice) {
            state.searchTree.selectOrDropAllNodes(choice);
        },

        setVisibleTree(state, value) {
            state.visibleTree = value;
        },

        setInitialized(state, value) {
            state.initialized = value;
        },
    },

    actions: {
        async setOkopfTree({ commit }) {
            const res = await api.getOkopfs();
            const { okopfs, presets } = res;
            commit('setTree', { okopfs });
            commit('setPresets', { presets });
            commit('setInitialized', true);
        },

        updateSearch: ({ commit, getters }, searchString) => {
            commit('setSearchString', searchString);
            if (getters.isSearchMode) {
                commit('runSearch');
            }
        },

        chooseOkopfNode({ commit }, nodeDesc) {
            commit('chooseOkopfNode', nodeDesc);
            commit('runSearch');
        },

        chooseOkopfsSearchNode: ({ commit }, nodeDesc) => {
            commit('chooseOkopfNode', nodeDesc);
            commit('syncSearchTree');
        },

        chooseOkopfs({ commit }, okopfs) {
            commit('chooseOrCancelTree', false);

            okopfs.forEach((okopf) => {
                commit('chooseOkopfNode', { level: 3, id: okopf });
            });
        },

        toggleIpPreset({ getters, dispatch }) {
            const { okopfs } = getters.ipPreset;
            const choose = !getters.ipPresetFullySelected;
            dispatch('updateOkopfsInTree', { okopfs, choose });
        },

        toggleOooPreset({ getters, dispatch }) {
            const { okopfs } = getters.oooPreset;
            const choose = !getters.oooPresetFullySelected;
            dispatch('updateOkopfsInTree', { okopfs, choose });
        },

        toggleOtherPreset({ getters, dispatch }) {
            const okopfs = getters.otherPresetOkopfs;
            const choose = !getters.otherPresetFullySelected;
            dispatch('updateOkopfsInTree', { okopfs, choose });
        },

        updateOkopfsInTree({ state }, { okopfs, choose }) {
            okopfs.forEach((nodeId) => {
                let searchedNode;
                for (let i = 4; i >= 3; i -= 1) {
                    searchedNode = state.tree.searchNodeById(i, nodeId, state.tree.getRoot());
                    if (searchedNode) {
                        break;
                    }
                }
                if (searchedNode.value.isChosen !== choose) {
                    state.tree.chooseNode(searchedNode.value.nodeLevel, nodeId);
                }
            });
        },

        async selectOkopfsByCodesAndLevels({
            state, commit, getters, dispatch,
        }, { codesWithLevels }) {
            if (!getters.isInitialized) {
                await dispatch('setOkopfTree');
            }

            if (!state.tree || codesWithLevels.length === 0) {
                return;
            }

            state.tree.selectNodesByCodesAndLevels(codesWithLevels);

            if (getters.isSearchMode) {
                commit('runSearch');
                commit('syncSearchTree');
            }
        },
    },
};
