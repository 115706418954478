<template>
  <DropdownConfigurator
    :content="selectedStatusCompany"
    title="Статус компании:"
    width="200px"
    :is-open="showCompanyStatusSelector"
    @clickButton="toggleCompanyStatusSelector"
  >
    <CompanyStatusSelector />
  </DropdownConfigurator>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import DropdownConfigurator from '@/egrul/components/DropListConfigurator/DropdownConfigurator.vue';
import CompanyStatusSelector from '@/egrul/components/CompanyStatusSelector/CompanyStatusSelector.vue';

export default {
  name: 'CompanyStatusConfigurator',

  components: {
    CompanyStatusSelector,
    DropdownConfigurator,
  },

  computed: {
    ...mapState([
      'companyStatuses',
      'showCompanyStatusSelector',
    ]),

    ...mapGetters([
      'selectedCompanyStatuses',
    ]),

    selectedStatusCompany() {
      if (this.selectedCompanyStatuses.length === 4 || this.selectedCompanyStatuses.length === 0) {
        return 'Все статусы';
      } if (this.selectedCompanyStatuses.length === 1) {
        return this.selectedCompanyStatuses[0].title;
      }
      return `${this.selectedCompanyStatuses.length} шт.`;
    },
  },

  methods: {
    ...mapMutations(['toggleShowCompanyStatusSelector']),

    companyStatusNameToLowerCase(value) {
      return value.charAt(0).toLowerCase() + value.slice(1);
    },

    toggleCompanyStatusSelector() {
      if (this.showCompanyStatusSelector) {
        this.closeCompanyStatusSelector();
      } else {
        this.toggleShowCompanyStatusSelector();
        document.addEventListener('click', this.clickHandler);
      }
    },

    closeCompanyStatusSelector() {
      document.removeEventListener('click', this.clickHandler);
      this.toggleShowCompanyStatusSelector();
    },

    clickHandler(event) {
      const clickedButton = event.target;
      const insideSelector = clickedButton.closest('.dropdownBody');
      const insideSelectorButton = clickedButton.closest('.selectMenu');
      if (this.showCompanyStatusSelector && !insideSelector && !insideSelectorButton) {
        this.closeCompanyStatusSelector();
      }
    },
  },
};
</script>

<style scoped>
</style>
