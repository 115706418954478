<template>
  <div class="row-feature">
    <p class="row-feature__description">
      Особенности:
    </p>
    <p class="row-feature__value">
      База ЕГРЮЛ/ЕГРИП
    </p>
  </div>
</template>

<script>
export default {
  name: 'RowFeature',
};
</script>

<style>
.row-feature {
  font-weight: bold;
  display: grid;
  grid-template-columns: 130px 1fr;
  justify-items: start;
  align-items: flex-start;
}

.row-feature__value {
  font-weight: normal;
}
</style>
