const state = {
    nameFilter: {
        name: 'name',
        title: 'Название / ИНН / ОКВЭД',
        selected: true,
        partiallySelected: false,
        disabled: true,
    },

    ogrnSelectedFilter: {
        name: 'inn',
        title: 'ОГРН / КПП / ОКПО',
        selected: false,
        partiallySelected: false,
    },

    phoneFilter: {
        name: 'phone_egrul',
        title: 'Телефон компании',
        selected: false,
        partiallySelected: false,
        subFilters: [],
    },

    addressFilter: {
        name: 'address',
        title: 'Адрес и индекс',
        selected: false,
        partiallySelected: false,
    },

    emailFilter: {
        name: 'email',
        title: 'Email компании',
        selected: false,
        partiallySelected: false,
        subFilters: [
            {
                name: 'email_verified',
                title: 'проверенные',
                selected: true,
            },
        ],
    },

    siteFilter: {
        name: 'site',
        title: 'Сайт',
        selected: false,
        partiallySelected: true,
    },

    showCompanySizeFilter: {
        name: 'show_company_size',
        title: 'Числен./выручка',
        selected: false,
        partiallySelected: false,
    },

    decisionMakerFilter: {
        name: 'okved_contact_person',
        title: 'Руководитель',
        selected: false,
        partiallySelected: false,
    },

    registrationDateFilter: {
        name: 'registration_date',
        title: 'Дата регистрации',
        selected: false,
        partiallySelected: false,
    },

    hasMessengerFilter: {
        name: 'has_messenger',
        title: 'Есть мессенджер',
        selected: false,
        partiallySelected: false,
    },

    years: [],

    months: [],

    licenses: {
        included: false,
        excluded: false,
    },

    branches: {
        included: false,
        excluded: false,
    },

    employees: {
        chosen: false,
        minValue: 0,
        maxValue: 250,
    },

    /**
     * диапазон выручки в млн. руб.
     */
    income: {
        chosen: false,
        minValue: 0,
        maxValue: 500,
    },

    profit: {
        chosen: false,
        minValue: 0,
        maxValue: 500,
    },

    has_site_phone: false,

    show_inc_and_profit_for_last_years: false,

    selectedBasicFilters: ['phone'],

    basicFiltersOrder: [
        'ogrn',
        'phone',
        'address',
        'post_index',
        'email',
        'site',
        'okved_contact_person',
        'show_company_size',
        'registration_date',
    ],

    needRecalculation: false,
    isCalculating: false,

    selectedOptionalFilters: ['site'],

    // Состояние отключения фильтров численности и выручки при выборе МСП
    additionalFiltersDisabled: false,

    mspTypes: {},

    fuse: null,

    fuseOptions: {
        keys: ['title'],
        threshold: 0.4,
    },

    searchCompanyType: '',

    companyTypes: {},

    showCompanyTypeSelector: false,

    showCompanySizeSelector: false,

    showCompanyStatusSelector: false,

    companyTypeGroups: {},

    companyStatuses: [],

    cityDescription: 'Москва',

    expandAdditionalFilters: false,

    visibleTree: false,

    highlightGeoButton: false,
    highlightOkvedButton: false,
    highlightOkopfButton: false,
};

export default state;
