const columns = [
  {
    value: '0',
    title: 'A',
  },
  {
    value: '1',
    title: 'B',
  },
  {
    value: '2',
    title: 'C',
  },
  {
    value: '3',
    title: 'D',
  },
  {
    value: '4',
    title: 'E',
  },
  {
    value: '5',
    title: 'F',
  },
  {
    value: '6',
    title: 'G',
  },
  {
    value: '7',
    title: 'H',
  },
  {
    value: '8',
    title: 'I',
  },
  {
    value: '9',
    title: 'J',
  },
];

const dataTypeList = {
  inn: 'ИНН',
  ogrn: 'ОГРН',
};

export { columns, dataTypeList };
