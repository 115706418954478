<template>
  <DropdownConfigurator
    :content="selectedMspType"
    title="Размер компании по МСП:"
    width="200px"
    :is-open="showCompanySizeSelector"
    @clickButton="toggleCompanySizeSelector"
  >
    <CompanySizeSelector />
  </DropdownConfigurator>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import DropdownConfigurator from '@/egrul/components/DropListConfigurator/DropdownConfigurator.vue';
import CompanySizeSelector from '@/egrul/components/CompanySizeSelector/CompanySizeSelector.vue';

export default {
  name: 'CompanySizeConfigurator',

  components: {
    CompanySizeSelector,
    DropdownConfigurator,
  },

  computed: {
    ...mapState([
      'mspTypes',
      'showCompanySizeSelector',
    ]),

    selectedMspType() {
      const selectedMspTypes = Object.keys(this.mspTypes)
        .filter((mspType) => this.mspTypes[mspType].included
              || this.mspTypes[mspType].excluded);

      if (selectedMspTypes.length === 1) {
        if (this.mspTypes[selectedMspTypes[0]].included) {
          return `${this.mspTypes[selectedMspTypes[0]].title
          } (${this.mspTypes[selectedMspTypes[0]].subtitle})`;
        }
        return `Исключить ${this.companyTypeNameToLowerCase(
          `${this.mspTypes[selectedMspTypes[0]].title
          } (${this.mspTypes[selectedMspTypes[0]].subtitle})`,
        )}`;
      } if (selectedMspTypes.length > 0) {
        return `${selectedMspTypes.length} шт.`;
      }
      return 'Все компании';
    },
  },

  methods: {
    ...mapMutations(['toggleShowCompanySizeSelector']),

    companyTypeNameToLowerCase(value) {
      return value.charAt(0).toLowerCase() + value.slice(1);
    },

    toggleCompanySizeSelector() {
      if (this.showCompanySizeSelector) {
        this.closeCompanySizeSelector();
      } else {
        this.toggleShowCompanySizeSelector();
        document.addEventListener('click', this.clickHandler);
      }
    },

    closeCompanySizeSelector() {
      document.removeEventListener('click', this.clickHandler);
      this.toggleShowCompanySizeSelector();
    },

    clickHandler(event) {
      const clickedButton = event.target;
      const insideSelector = clickedButton.closest('.dropdownBody');
      const insideSelectorButton = clickedButton.closest('.selectMenu');
      if (this.showCompanySizeSelector && !insideSelector && !insideSelectorButton) {
        this.closeCompanySizeSelector();
      }
    },
  },
};
</script>

<style scoped>
</style>
