<template>
  <SuccessResult v-if="countParsedData !== 0" />
  <ErrorResult v-else />
</template>

<script>
import { mapState } from 'vuex';
import SuccessResult from './SuccessResult.vue';
import ErrorResult from './ErrorResult.vue';

export default {
  name: 'ResultStep',
  components: {
    ErrorResult,
    SuccessResult,
  },

  computed: {
    ...mapState('exclusionFile', [
      'countParsedData',
    ]),
  },
};
</script>

<style>

</style>
