<template>
  <div class="reg_date">
    <MultiSelector
      title="Год регистрации"
      all-selected-text="Все годы"
      :items="years"
      class="reg-year"
      :force-open="forceOpenYears"
      @itemChange="yearChange"
      @allItemSelect="allYearSelect"
    />

    <MultiSelector
      title="Месяц регистрации"
      all-selected-text="Все месяцы"
      :items="months"
      @itemChange="monthChange"
      @allItemSelect="allMonthSelect"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import MultiSelector from '../../../common_components/MultiSelector/MultiSelector.vue';

export default {
  name: 'RegistrationDate',
  components: {
    MultiSelector,
  },

  props: {
    initMonths: {
      type: Array,
      required: false,
      default: () => [],
    },

    initYears: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      forceOpenYears: false,
    };
  },

  computed: {
    ...mapState([
      'years',
      'months',
    ]),
  },

  async created() {
    this.handleHash();
  },

  methods: {
    ...mapMutations({
      setExpandAdditionalFilters: 'setExpandAdditionalFilters',
      monthInitChange: 'monthChange',
      yearInitChange: 'yearChange',
    }),

    ...mapActions([
      'yearChange',
      'monthChange',
      'allYearSelect',
      'allMonthSelect',
    ]),

    handleHash() {
      if (window.location.hash === '#filter_reg_date') {
        this.handleHashFilterRegDate();
      }
    },

    handleHashFilterRegDate() {
      this.setExpandAdditionalFilters(true);
      window.scrollTo({
        top: 100,
        left: 100,
        behavior: 'smooth',
      });
      this.forceOpenYears = true;
      window.location.hash = '';
    },
  },
};
</script>

<style>
    .reg_date {
        display: flex;
        flex-direction: row;
    }

    .reg-year {
        margin-right: 30px;
    }
</style>
