
export default class MultiSelectorUtil {
    static unselectAll(items) {
        return items.map(item => ({...item, selected: false}))
    }

    static getSelectedKeys(items, key) {
        return items.filter(item => item.selected).map(item => item[key])
    }

    static findByKey(items, key) {
        return items.find(item => item.key === key)
    }
}
