<template>
  <div>
    <div class="wrapper-btn">
      <ToggleSwitch
        :chosen="excludeCompanies"
        @set-chosen-item="toggleFilter"
      />
      <span
        :class="getClassForButton"
        @click="openPopup"
      >
        Исключить компании по ИНН
      </span>
      <ExclusionTooltip class="exclusion__tooltip" />
    </div>
    <transition name="fade">
      <div
        v-if="visiblePopup"
        class="popup-wrapper"
      >
        <Popup
          mode-no-padding
          @close="closePopup"
        >
          <template #content>
            <Preloader v-show="showPreloader" />
            <UploadForm
              ref="uploadForm"
              :steps="steps"
              class="uploadForm"
              @validateStep="validateStep"
              @onComplete="complete"
              @resetValidation="resetValidStep"
            >
              <template #step1>
                <ChosenFileStep />
              </template>
              <template #step2>
                <ChosenDataTypeStep />
              </template>
              <template #step3>
                <ChosenColumnStep />
              </template>
              <template #step4>
                <ResultStep />
              </template>
            </UploadForm>
          </template>
        </Popup>
      </div>
      <div v-else></div>
    </transition>
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import ExclusionTooltip from '@/egrul/components/ExclusionCompaniesFilter/ExclusionTooltip.vue';
import Popup from '@/common_components/Popup/Popup.vue';
import ToggleSwitch from 'toggle-switch';
import UploadForm from './UploadForm.vue';
import ChosenFileStep from './ChosenFileStep.vue';
import ChosenColumnStep from './ChosenColumnStep.vue';
import ChosenDataTypeStep from './ChosenDataTypeStep.vue';
import Preloader from '../../../common_components/Preloader/Preloader.vue';
import ResultStep from './ResultStep/ResultStep.vue';

export default {
  name: 'ExclusionFileControl',
  components: {
    Popup,
    ExclusionTooltip,
    ResultStep,
    Preloader,
    ChosenDataTypeStep,
    ChosenColumnStep,
    ChosenFileStep,
    UploadForm,
    ToggleSwitch,
  },

  data() {
    return {
      visiblePopup: false,
      steps: [
        { step_no: 1, step_valid: false },
        { step_no: 2, step_valid: false },
        { step_no: 3, step_valid: false },
        { step_no: 4, step_valid: false },
      ],
    };
  },

  computed: {
    ...mapState('exclusionFile', [
      'fileIsUploaded',
      'chosenType',
      'chosenColumn',
      'showPreloader',
      'excludeCompanies',
      'showPreloader',
      'fileIsParsed',
      'countParsedData',
    ]),

    ...mapGetters('exclusionFile', [
      'getDataForParse',
    ]),

    getClassForButton() {
      return {
        'exclusion-button': true,
        'exclusion-button_success': this.excludeCompanies,
        'exclusion-button_hide': !this.excludeCompanies,
      };
    },
  },

  watch: {
    fileIsParsed() {
      this.calculateConfiguration();
    },

    excludeCompanies() {
      if (this.fileIsParsed && this.countParsedData !== 0) {
        this.calculateConfiguration();
      }
    },
  },

  methods: {
    ...mapActions(['calculateConfiguration']),
    ...mapActions('exclusionFile', [
      'parseFile',
      'resetSettings',
      'toggleExcludeFilter',
    ]),

    ...mapMutations('exclusionFile', [
      'setFileIsParsed',
    ]),

    toggleFilter() {
      this.toggleExcludeFilter(!this.excludeCompanies);

      if (this.excludeCompanies && !this.fileIsParsed) {
        this.openPopup();
      }
    },

    closePopup() {
      if (this.showPreloader) {
        return;
      }

      if (this.excludeCompanies && !this.fileIsParsed) {
        this.toggleFilter();
      }

      this.resetValidSteps();

      return this.visiblePopup = !this.visiblePopup;
    },

    openPopup() {
      if (!this.excludeCompanies) {
        this.toggleExcludeFilter(!this.excludeCompanies);
      }

      this.resetSettings();
      this.visiblePopup = !this.visiblePopup;
    },

    async validateStep(stepIndex) {
      if (stepIndex === 0 && !this.fileIsUploaded) {
        return window.echo('Загрузите ваш файл');
      }

      if (stepIndex === 1 && this.chosenType === null) {
        return window.echo('Выберите тип данных');
      }

      if (stepIndex === 2 && !this.chosenColumn.value) {
        return window.echo('Выберите столбик с данными');
      }

      if (stepIndex === 2) {
        await this.parseClientFile();
      }

      this.steps[stepIndex].step_valid = true;
      this.$refs.uploadForm.submitStep();
    },

    resetValidStep(stepIndex) {
      if (stepIndex === 2) {
        this.setFileIsParsed(false);
      }
      this.steps[stepIndex].step_valid = false;
    },

    resetValidSteps() {
      this.steps.forEach((step) => step.step_valid = false);
    },

    parseClientFile() {
      return this.parseFile(this.getDataForParse);
    },

    complete() {
      this.closePopup();
    },
  },
};
</script>

<style scoped>
.exclusion__tooltip {
  width: 17px;
  height: 17px;
  left: 8px;
}

.exclusion-button {
  border-bottom: 1px dashed #000;
  cursor: pointer;
  color: #000;
  font-weight: bold;
  position: relative;
  line-height: 19px;
}

.exclusion-button_success:hover {
  border-bottom: none;
}

.exclusion-button_hide {
  opacity: 0.5;
  cursor: default;
}

.uploadForm {
  padding: 50px;
}

.wrapper-btn {
  position: relative;
  display: flex;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transition-delay: unset;
}
</style>
