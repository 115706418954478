export default class EgrulConfiguratorUtil {
    static pushOrSpliceParam(isSelected, param, currentArray)
    {
        let resultArray = currentArray;

        if (isSelected) {
            if (resultArray.indexOf(param) === -1) {
                resultArray.push(param);
            }
        } else {
            const index = resultArray.indexOf(param);
            resultArray.splice(index, 1);
        }

        return resultArray;
    }

    static getCostMultiplier(filters, prices)
    {
        let multiplier = 1;
        const mspTypeFiltersCount = filters.msp_type.length;

        for (let i = 0; i < mspTypeFiltersCount; i++) {
            multiplier *= prices.multiplier_msp[filters.msp_type[i]];
        }

        if (filters.email_verified) {
            multiplier *= prices.multiplier_email_verified;
        }

        return multiplier;
    }
}